<div id="chart">
  <ng-container *ngIf="chartOptions">
    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis"
      [legend]="chartOptions.legend" [colors]="chartOptions.colors" [grid]="chartOptions.grid"
      [tooltip]="chartOptions.toolTip"></apx-chart>
  </ng-container>

  <div class="w-100 px-2">
    <ng-container *ngFor="let item of categories; index as j">
      <div style="margin-block: 1px" class="d-flex justify-content-between">
        <div class="d-flex align-items-center gap-2">
          <div [style.background]="colors[j]" class="circle-dot"></div>
          <span class="f-12">
            {{ item }}
          </span>
        </div>

        <span class="text-muted f-12">
          {{ values[j] | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1}  }}
        </span>
      </div>
    </ng-container>
  </div>
</div>