import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  inject,
} from "@angular/core";
import { NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-range-date-picker",
  templateUrl: "./range-date-picker.component.html",
  styleUrls: ["./range-date-picker.component.css"],
})
export class RangeDatePickerComponent implements OnInit {
  @Output() onDateChange = new EventEmitter<any>();
  @Input() incomingFromDate: Date;
  @Input() incomingToDate: Date;
  private calendar = inject(NgbCalendar);
  public hoveredDate: NgbDate | null = null;
  private fromDate;
  public fromInput;
  public toInput;
  private toDate;
  constructor() {}
  ngOnInit(): void {
    this.fromDate = {
      day: new Date(this.incomingFromDate).getUTCDate(),
      month: new Date(this.incomingFromDate).getUTCMonth() + 1,
      year: new Date(this.incomingFromDate).getUTCFullYear(),
    };
    this.toDate = {
      day: new Date(this.incomingToDate).getUTCDate(),
      month: new Date(this.incomingToDate).getUTCMonth() + 1,
      year: new Date(this.incomingToDate).getUTCFullYear(),
    };
    this.fromInput = this.formatDate(this.incomingFromDate);
    this.toInput = this.formatDate(this.incomingToDate);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.fromInput = this.formatDate(new Date(
        date.year,
        date.month - 1,
        date.day
      ));
    } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
      this.toDate = date;
      this.toInput = this.formatDate(new Date(
        date.year,
        date.month - 1,
        date.day
      ));
    } else{
      this.toDate = null;
      this.toInput = null;
      this.fromDate = date;
      this.fromInput = this.formatDate(new Date(
        date.year,
        date.month - 1,
        date.day
      ));
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  setDate() {
    let date: Object = {};
    this.fromDate
      ? (date["fromDate"] = new Date(
          this.fromDate.year,
          this.fromDate.month - 1,
          this.fromDate.day
        ))
      : null;
    this.toDate
      ? (date["toDate"] = new Date(
          this.toDate.year,
          this.toDate.month - 1,
          this.toDate.day
        ))
      : null;
    this.onDateChange.emit(date);
  }
  changeInp() {
    this.fromDate = {
      day: new Date(this.fromInput).getUTCDate(),
      month: new Date(this.fromInput).getUTCMonth() + 1,
      year: new Date(this.fromInput).getUTCFullYear(),
    };
    this.toDate = {
      day: new Date(this.toInput).getUTCDate(),
      month: new Date(this.toInput).getUTCMonth() + 1,
      year: new Date(this.toInput).getUTCFullYear(),
    };
  }
  formatDate(date) {
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}
