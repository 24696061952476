import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/';

import { Config } from '../../config/config.config';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private http: HttpClient,
    private config: Config
  ) { }
  addProject(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/project`,
      data,
      {}
    );
  }
  editProject(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/project`,
      data,
      {}
    );
  }
  addTask(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/task`,
      data,
      {}
    );
  }
  editTask(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/task`,
      data,
      {}
    );
  }
  addDrawing(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/drawing`,
      data,
      {}
    );
  }
  editDrawing(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/drawing`,
      data,
      {}
    );
  }
  addExpense(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/expense`,
      data,
      {}
    );
  }
  editExpense(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/expense`,
      data,
      {}
    );
  }
  addMaterialItem(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialitem`,
      data,
      {}
    );
  }
  editMaterialItem(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialitem`,
      data,
      {}
    );
  }
  addMaterialStock(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/materialstock`,
      data,
      {}
    );
  }
  editMaterialStock(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/materialstock`,
      data,
      {}
    );
  }
  addMaterial(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/material`,
      data,
      {}
    );
  }
  editMaterial(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/material`,
      data,
      {}
    );
  }
  addHelpVideo(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/helpvideo`,
      data,
      {}
    );
  }
  editHelpVideo(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/helpvideo`,
      data,
      {}
    );
  }
  takeUserAccess(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/admin/user/access`,
      data,
      {}
    );
  }
  getPlatfomStats(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/platform/stats`,
      {params}
    );
  }
  getActionLogs(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/actionlog`,
      {params}
    );
  }
  addCashbookEntry(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/cashbookentry`,
      data,
      {}
    );
  }
  editCashbookEntry(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/cashbookentry`,
      data,
      {}
    );
  }

  // team member admin services

  deleteTeamMember(id : string): Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/team-member/${id}`,
      {}
    );
  }

  getActionLogCSV(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/actionlog/csv`,
      {params}
    );
  }
  getUsersList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/users`,
      {params}
    );
  }
  inviteAgain(user_id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/invite/again/${user_id}`,
      {}
    );
  }
  generatePaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/cashbook-pdfbydate/${project_id}`,
      {params}
    );
  }
  generateCSVPaymentReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/cashbook-csv/${project_id}`,
      {params}
    );
  }
  generateTaskReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/progress-pdfbydate/${project_id}`,
      {params}
    );
  }
  generateAttendanceReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-pdfbydate/${project_id}`,
      {params}
    );
  }
  generateCSVAttendanceReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/attendance-csv/${project_id}`,
      {params}
    );
  }
  generateCSVMaterialReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-csv/${project_id}`,
      {params}
    );
  }
  generateMaterialReport(project_id: string, params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/generate-report/material-purchase-report/${project_id}`,
      {params}
    );
  }
  getCompnayUserList(params?: HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/companyuser`,
      {params}
    );
  }
  saveNewCompanyUser(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/companyuser`,
      data,
      {}
    );
  }
  saveNewUserRole(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/team-member`,
      data,
      {}
    );
  }

  getWorkforceStockList(params?: HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/workforcestock`,
      {params}
    );
  }

  addWorkforcestock(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/workforcestock`,
      data,
      {}
      );
  }

  editWorkforcestock(data): Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/workforcestock`,
      data,
      {}
      );
  }
  addAttendance(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/attendance`,
      data,
      {}
      );
  }

  editAttendance(data): Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/attendance`,
      data,
      {}
      );
  }

  listOvertimeLatefine(params?: HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/overtime`,
      {params}
    );
  }

  addOvertimeLatefine(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/overtime`,
      data,
      {}
      );
  }

  deleteOvertimeLatefine(id : string): Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/overtime/${id}`,
      {}
    );
  }

  listAllowanceDeduction(params?: HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/allowance`,
      {params}
    );
  }
  addAllowanceDeduction(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/allowance`,
      data,
      {}
      );
  }
  deleteAllowanceDeduction(id : string): Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/delete/allowance/${id}`,
      {}
    );
  }

  getWorkforceList(params?: HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/workforce`,
      {params}
    );
  }

  addWorkforce(data): Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/add/workforce`,
      data,
      {}
      );
  }
  listUserStockforceDetails(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/attendance`,
      {params}
    );
  }

  deleteUserStockforce(id : string): Observable<any>{
    return this.http.delete(
      `${this.config.userEndpoint}/remove/workforcestock/${id}`,
      {}
    );
  }

  replyWhatsAppText(data): Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/whatsapp/send/plain-text`,
    data,
    {}
    );
  }

  grantSubscription(data): Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/update/company/subscription`,
    data,
    {}
    );
  }
  getSubscribedCompany(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/subscribed/company`,
      {params}
    );
  }

  listSubscriptionPlans(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/list/subscription-plan`,
      {params}
    );
  }

  subscriptionDetails(id:string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/detail/subscription-plan/${id}`,
      {}
    );
  }

  editSubscriptionDetails(data): Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/subscription-plan`,
      data,
      {}
      );
  }

  addSubscriptionDetails(data): Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/subscription-plan`,
    data,
    {}
    );
  }

  addPlanBreakUp(data):Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/subscription-plan-breakup`, data, {});
  }
  editPlanBreakUp(data):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/subscription-plan-breakup`, data, {});
  }

  // removeWorkforceStock(id?:string):Observable<any>{
  //   return this.http.delete(`${this.config.userEndpoint}/remove/workforcestock/${id}`,{})
  // }
  getPolicyList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/policy`,
      { params }
    );
  }
  getPolicyTree(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/policy/tree`,
      { params }
    );
  }
  addPolicy(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/policy`,
      data,
      {}
    );
  }
  getPolicyDetail(id?: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/detail/policy/${id}`,
    );
  }

  addCompanyRole(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/admin/add/companyrole`,
      data,
      {}
    );
  }

  listCompanyRole(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/list/companyrole`,
      { params }
    );
  }
  getCompanyRoleById(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/detail/companyrole/${id}`,
    );
  }
  getCompanyRoleListCompany(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/companyrole/list/company/${id}`,
    );
  }

  companyRoleAddPolicy(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/companyrole/add/policy`,
      data,
      {}
    );
  }
  companyRoleRemovePolicy(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/companyrole/remove/policy`,
      data,
      {}
    );
  }

  addcompanyRoleInCompanyConfig(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/companyconfiguration/add/companyrole`,
      data,
      {}
    );
  }

  getCompanyDetailId(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/admin/detail/company/${id}`,
    );
  }

  updateCompanyControls(controlName: string, controlStatus: string, id: string): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/${controlStatus}/${controlName}/${id}`,
      {}
    );
  }

  // admincustomfieldsList(id: string): Observable<any> {
  //   return this.http.get(`${this.config.userEndpoint}/admin/customfields);
  // }

  getCustomFieldList(params:HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/admin/list/customfield`, {params});
  }

  addCustomField(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/admin/add/customfield`,
      data,
      {}
    )
  }

  editCustomField(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/bulk/edit/customfield`,data,{}
    )
  }

  deleteCustomField(id: string): Observable<any> {
    return this.http.delete(
      `${this.config.userEndpoint}/admin/delete/customfield/${id}`,
      {}
    )
  }

  //rV3.PATCH("/admin/enable/multiple-feature", handler.AdminEnable
  enableMultipleFeature(data:any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/edit/multiple-feature`,data,{}
    )
  }

  attendanceRestrictionLevel(data:any): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/admin/edit/attendance_restriction_level`,data,{}
    )
  }
  
  // getPayroll(id:string): Observable<any> {
  //   return this.http.get(`${this.config.userEndpoint}/is-payroll-exist/${id}`, {});
  // }

  // addBulkWorkforceStock(data):Observable<any>{
  //   return this.http.post(`${this.config.userEndpoint}/bulk/add/workforcestock`, data, {})
  // }
  
  // addPayroll(data):Observable<any>{
  //   return this.http.post(`${this.config.userEndpoint}/add/payroll`, data, {})
  // }
  // editPayroll(data):Observable<any>{
  //   return this.http.patch(`${this.config.userEndpoint}/edit/payroll`, data, {})
  // }
}
