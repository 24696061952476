export enum FilterParentPage {
    Payment = 'Payment',
    Material = 'Material',
    Attendance = 'Attendance',
    Party = 'Party',
    Warehouse = 'Warehouse',
    CompanyMaterialLibrary = 'company_material_library',
    Notification = 'notification'
  }
  
  export enum FilterKey {
    Transaction = 'transaction',
    NotificationType = 'notificationType',
    Party = 'party',
    Trade = 'trade',
    Category = 'category',
    Creator = 'creator',
    PaymentMode = 'paymentMode',
    Project = 'project',
    Status = 'status',
    CostCode = 'costcode',
    MaterialCategory = 'material_category',
  }
  
  export enum DateFilterOptionKey {
    All = 'all',
    Today = 'today',
    LastWeek = 'lastWeek',
    LastMonth = 'lastMonth',
    ThisWeek = 'thisWeek',
    ThisMonth = 'thisMonth',
  }

  export enum StatusType {
    Paid = 'paid',
    PartiallyPaid = 'partiallypaid',
    Unpaid = 'unpaid'
  }
  
  export enum TransactionType {
    All = 'all',
    CashbookDeposit = 'cashbook_deposit',
    CashbookWithdraw = 'cashbook_withdraw',
    Material = 'material',
    PartyEarning = 'partyearning',
    StaffPayments = 'staff_payments',
    Reimbursement = 'reimbursement',
    CustomerInvoice = 'customerinvoice',
    MaterialReturn = 'materialreturn',
    MaterialTransfer = 'material_transfer',
    SubconExpense = 'subconexpense',
    DebitNote = 'debitnote'
  }

  export enum NotificationType {
    All = 0,
    Payment = 13,
    MaterialPurchase = 23,
    MaterialReturn = 47,
    PartyEarning = 24,
    Reimbursement = 25,
    CustomerInvoice = 36,
    MaterialTransfer = 41,
    SubconExpense = 48,
    DebitNote = 49
  }
  
  export enum PaymentMode {
    All = 'All',
    Cash = 'Cash',
    BankTransfer = 'Bank Transfer',
    Cheque = 'Cheque'
  }
  
  export enum ExpenseStatus {
    Paid = 'paid',
    Unpaid = 'unpaid',
    PartiallyPaid = 'partiallypaid'
  }

  ///Records///

  export const FilterRecord: Record<FilterKey, { name: string; hasSelectedValue: any[] }> = {
    [FilterKey.Transaction]: { name: 'Transaction', hasSelectedValue: [] },
    [FilterKey.Party]: { name: 'Party', hasSelectedValue: [] },
    [FilterKey.Trade]: { name: 'Cost Code', hasSelectedValue: [] },
    [FilterKey.Category]: { name: 'Category', hasSelectedValue: [] },
    [FilterKey.Creator]: { name: 'Entry by', hasSelectedValue: [] },
    [FilterKey.PaymentMode]: { name: 'Mode', hasSelectedValue: [] },
    [FilterKey.Project]: { name: 'Project', hasSelectedValue: [] },
    [FilterKey.Status]: { name: 'Status', hasSelectedValue: [] },
    [FilterKey.CostCode]: { name: 'Cost Code', hasSelectedValue: [] },
    [FilterKey.MaterialCategory]: { name: 'Material Category', hasSelectedValue: [] },
    [FilterKey.NotificationType]: { name: 'Transaction', hasSelectedValue: [] }
  };

  export const paymentPageFilterRecord: Omit<typeof FilterRecord, FilterKey.CostCode | FilterKey.MaterialCategory | FilterKey.NotificationType> = {
    [FilterKey.Transaction]: { name: 'Transaction', hasSelectedValue: [] },
    [FilterKey.Party]: { name: 'Party', hasSelectedValue: [] },
    [FilterKey.Trade]: { name: 'Cost Code', hasSelectedValue: [] },
    [FilterKey.Category]: { name: 'Category', hasSelectedValue: [] },
    [FilterKey.Creator]: { name: 'Entry by', hasSelectedValue: [] },
    [FilterKey.PaymentMode]: { name: 'Mode', hasSelectedValue: [] },
    [FilterKey.Project]: { name: 'Project', hasSelectedValue: [] },
    [FilterKey.Status]: { name: 'Status', hasSelectedValue: [] }
  };

  export const materialLibraryFilterRecord: Pick<typeof FilterRecord, FilterKey.CostCode | FilterKey.MaterialCategory> = {
    [FilterKey.CostCode]: { name: 'Cost Code', hasSelectedValue: [] },
    [FilterKey.MaterialCategory]: { name: 'Material Category', hasSelectedValue: [] }
  };

  export const dateFilterRecord : Record<DateFilterOptionKey,{name:string}>={
      [DateFilterOptionKey.All]: { name: 'All' },
      [DateFilterOptionKey.Today]: { name: 'Today' },
      [DateFilterOptionKey.LastWeek]: { name: 'Last Week (Mon - Sun)' },
      [DateFilterOptionKey.LastMonth]: { name: 'Last Month' },
      [DateFilterOptionKey.ThisWeek]: { name: 'This Week (Mon - Today)' },
      [DateFilterOptionKey.ThisMonth]: { name: 'This Month' }
  }

  export const statusTypeRecord: Record<StatusType, { name: string }> = {
    [StatusType.Paid]: { name: 'Paid' },
    [StatusType.PartiallyPaid]: { name: 'Partially Paid' },
    [StatusType.Unpaid]: { name: 'Unpaid' }
  };
  
  export const transactionTypeRecord: Record<TransactionType, { name: string; isSelected: boolean }> = {
    [TransactionType.All]: { name: 'All', isSelected: false },
    [TransactionType.CashbookDeposit]: { name: 'Payment In', isSelected: false },
    [TransactionType.CashbookWithdraw]: { name: 'Payment Out', isSelected: false },
    [TransactionType.Material]: { name: 'Material Purchase', isSelected: false },
    [TransactionType.PartyEarning]: { name: 'Other Expense', isSelected: false },
    [TransactionType.StaffPayments]: { name: 'Party Payment', isSelected: false },
    [TransactionType.Reimbursement]: { name: 'Site Expense', isSelected: false },
    [TransactionType.CustomerInvoice]: { name: 'Sales Invoice', isSelected: false },
    [TransactionType.MaterialReturn]: { name: 'Material Return', isSelected: false },
    [TransactionType.MaterialTransfer]: { name: 'Material Transfer', isSelected: false },
    [TransactionType.SubconExpense]: { name: 'Sub-Con Expense', isSelected: false },
    [TransactionType.DebitNote]: { name: 'Debit Note', isSelected: false }
  };

  export const notificationTypeRecord: Record<NotificationType, { name: string; isSelected: boolean }> = {
    [NotificationType.All]: { name: 'All', isSelected: false },
    [NotificationType.Payment]: { name: 'Payments', isSelected: false },
    [NotificationType.MaterialPurchase]: { name: 'Material Purchase', isSelected: false },
    [NotificationType.MaterialReturn]: { name: 'Material Return', isSelected: false },
    [NotificationType.PartyEarning]: { name: 'Other Expense', isSelected: false },
    [NotificationType.Reimbursement]: { name: 'Site Expense', isSelected: false },
    [NotificationType.CustomerInvoice]: { name: 'Sales Invoice', isSelected: false },
    [NotificationType.MaterialTransfer]: { name: 'Material Transfer', isSelected: false },
    [NotificationType.SubconExpense]: { name: 'Sub-Con Expense', isSelected: false },
    [NotificationType.DebitNote]: { name: 'Debit Note', isSelected: false }
  };
  
  export const paymentModeRecord: Record<PaymentMode, { name: string; isSelected: boolean }> = {
    [PaymentMode.All]: { name: 'All', isSelected: false },
    [PaymentMode.Cash]: { name: 'Cash', isSelected: false },
    [PaymentMode.BankTransfer]: { name: 'Bank Transfer', isSelected: false },
    [PaymentMode.Cheque]: { name: 'Cheque', isSelected: false }
  };
  
  export const expenseStatusRecord: Record<ExpenseStatus, { name: string; isSelected: boolean }> = {
    [ExpenseStatus.Paid]: { name: 'Paid', isSelected: false },
    [ExpenseStatus.Unpaid]: { name: 'Unpaid', isSelected: false },
    [ExpenseStatus.PartiallyPaid]: { name: 'Partially Paid', isSelected: false }
  };

  export const notificationFilterRecord: Pick<typeof FilterRecord, FilterKey.NotificationType | FilterKey.Project> = {
    [FilterKey.NotificationType]:{ name: 'Transaction', hasSelectedValue: [] },
    [FilterKey.Project]: { name: 'Project', hasSelectedValue: [] },
  }
  

 