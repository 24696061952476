import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { Company, CompanyUser, Project } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { UtilService } from '../../services/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  Observable,
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
} from "rxjs";
interface ParentData {
  pageFrom?: 'partyEarning'| 'party-payment' | 'materialPurchase'| 'materialInventory' | 'withdraw'| 'deposit' | 'attendance' | 'project-setting' | 'sales-invoice' | 'AddQuotationComponent' | 'equipment' | 'warehouse-setting' | 'create-subcon'| 'purchase-order' | 'material-return' | 'task-assignee' | 'project-creation' | 'asset-modal' | 'todo';
  project_id?: string;
  party_company_user_id?: string;
  autoFocus?: boolean;
  autoClick?: boolean;
  inputLabel?: string;
  hideWarehouse?: boolean;
}
@Component({
  selector: 'app-project-input',
  templateUrl: './project-input.component.html',
  styleUrls: ['./project-input.component.css']
})
export class ProjectInputComponent implements  OnInit, OnDestroy  {
  @ViewChild('projectName') projectNameEle: ElementRef;
  @Input() parentComponent = {} as ParentData;
  @Output() onCompanyProjectSelect = new EventEmitter<Project>(true);
  @Input() required: boolean;
  public formInputError = '';
  public project = {} as Project;
  public projects = [] as Project[];
  
  public company = {} as Company;
  public companyProjectSearchBox = false;
  public isWarehouse = true;
  public mobileForm: FormGroup;
  public mobileNumber: string;
  public valueStack = { page: { next_url: '' } };
  public busy = false;
  constructor(
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    public authService:AuthService,
    private utilService: UtilService
  ) {
  }
  
  ngOnInit(): void {
    this.company = this.authService.session.company || {};
    this.getProjects();
    this.isWarehouse = this.parentComponent.hideWarehouse ? false : true;
  
  }

  ngAfterViewInit() {
    if(this.parentComponent?.autoFocus){
      setTimeout(() => {
        this.projectNameEle.nativeElement?.focus();
      }, 100);
    }
  }
  
  ngOnDestroy() {
    //
  }
  
  getProjects() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.getProjectList(qp).subscribe((res) => {
      this.projects = res.projects || ([] as Project[]);
      this.valueStack = res;
      this.localStorageService.set('companyProjects', this.projects);
      if(this.parentComponent.autoClick){
        this.projectNameEle.nativeElement?.click();
      }
      this.utilService.projectIdObservable.subscribe(party_company_project_id => {
        if (party_company_project_id) {
          this.project = this.projects.find(project => project.id == party_company_project_id);
          if(this.project){
            this.setCompanyProject(this.project);
          }else{
            this.project = {}
            this.sendProject(this.project)
          }
        }
      })
  
    });
  }
  
  filterCompanyProjects() {
    // let len = 0;
    // this.companyProject.name  = this.companyProject.name?.trimStart()
    // this.companyProject.name ? len = this.companyProject.name?.length : 0;
  
    // if (len > 0) {
    //   this.companyProjects = this.localStorageService.get('companyProjects') || [];
    //   const pattern = new RegExp(this.companyProject.name, 'i');
    //   this.companyProjects = this.companyProjects.filter((f) =>
    //     f.name.match(pattern)
    //   );
    //   this.companyProjectSearchBox = true;
    // }
    // if (len == 0) {
    //   this.companyProjects = this.localStorageService.get('companyProjects');
    //   this.companyProjectSearchBox = true;
    // }
    this.companyProjectSearchBox = true;
  }
  
  closeSearchBox(event?) {
    event?.stopPropagation();
    this.project = {};
    this.filterCompanyProjects();
    this.companyProjectSearchBox = false;
    this.onCompanyProjectSelect.emit()
  }
  
  setCompanyProject(item: Project) {
    this.project = item;
    this.formInputError = '';
    this.companyProjectSearchBox = false;
    this.sendProject(item);
  }
  
  sendProject(item?: Project) {
    this.formInputError = '';
    this.onCompanyProjectSelect.emit(item)
  }
  setError(value: boolean) {
    value == true ? this.formInputError = 'projectName' : this.formInputError = '';
  }

  onScroll() {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe({
        next: (res) => {
          this.valueStack = res;
          this.projects = this.projects.concat(res.projects);
          this.busy = false;
        },
        error: (err) => {
          // this.initialLoading = false;
          this.busy = false;
        }
      });
    }
  }

  pullData(term: string): Observable<any[]> {
    if (term == "") {
      this.getProjects();
      return of([]);
    }
    let qp = new HttpParams();
    qp = qp.set("search", term);
    qp = qp.set('company_id',  this.company.id);
    return this.adminService.getProjectList(qp).pipe(
      map((res) => {
        this.valueStack = res;
        this.projects  = res.projects;
        return [];
      })
    );
  }

  tradeSearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.pullData(term)?.pipe(
          catchError(() => {
            return of([]);
          })
        )
      )
  );
  
  }

