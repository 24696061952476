import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveOffcanvas, NgbCalendar, NgbDate, NgbDateStruct, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CompanyUser, DebitNote, DebitNoteItem, Project } from 'src/app/interfaces/interfaces';
import { AddDebiteNoteItemComponent } from '../add-debite-note-item/add-debite-note-item.component';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { AttachmentViewComponent } from 'src/app/shared/components/attachment-view/attachment-view.component';
import { HttpParams } from '@angular/common/http';
import { UtilService } from 'src/app/shared/services/util.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
export interface Parent {
  project_id?: string;
  project?: Project;
  debit_note_id?: string;
  vendor_company_client_cu_id?: string;
  debitNoteItems?: DebitNoteItem[];
}
@Component({
  selector: 'app-add-debit-note',
  templateUrl: './add-debit-note.component.html',
  styleUrls: ['./add-debit-note.component.css']
})

export class AddDebitNoteComponent {
  @ViewChild('child') attachmentView: AttachmentViewComponent;
  @Input() parent = {
    debitNoteItems: [] as DebitNoteItem[]
  } as Parent;
  public loading = false;
  public inputShow = [];
  public model: NgbDateStruct;
  public debitNote = {} as DebitNote;
  public debitNoteItems = [] as DebitNoteItem[];
  public addNote = false;
  public addReference = false;
  public debitNoteItemCount = 0;
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    public canvas: NgbActiveOffcanvas, 
    public calendar: NgbCalendar, 
    private ngbOffcanvas: NgbOffcanvas,
    private notificationService: NotificationsService,
    public authService: AuthService,
    public validatorService: ValidatorService,
    private adminService: AdminService,
    private utilService: UtilService
  ) {
    this.model = this.calendar.getToday();
  }
  ngOnInit(): void {
    if (this.parent.debit_note_id) {
      this.loading = true;
      this.getDebitNoteDetails(this.parent.debit_note_id);
    } else {
      this.debitNote.invoice_date = new Date().toISOString();
    }
  }
  ngOnDestroy(): void {
    this.utilService.sendPartyId(null);
  }
  getDebitNoteDetails(id) {
    this.adminService.getDetailDebitNote(id).subscribe({
      next: (res) => {
        this.loading = false;
        this.debitNote = res || {} as DebitNote;
        this.debitNote.amount = this.debitNote.total_amount;
        if(this.debitNote.monkey_patch_debit_note_items?.length > 0){
          this.setDebitNoteItems(this.debitNote.monkey_patch_debit_note_items);
        }
        if (this.debitNote?.photos?.length > 0) {
          this.attachmentView.getFiles(this.debitNote.photos);
        }
        this.utilService.sendPartyId(res.party_company_user_id);
        // set date model of canvas from old purchase
        const oldPaymentDate = new Date(this.debitNote.invoice_date);
        this.model = new NgbDate(oldPaymentDate.getFullYear(), oldPaymentDate.getMonth() + 1, oldPaymentDate.getDate())
      },
      error: () => {
        this.loading = false;
      }
    })
  }
  setDebitNoteItems(debitNoteItems: DebitNoteItem[]) {
      for (const debit_note_item of debitNoteItems) {
      const old_sii_index = this.debitNoteItems.findIndex(sii => sii.debit_note_id === debit_note_item.id);
      if (old_sii_index > -1) {
        const temp_debit_note_item = this.debitNoteItems[old_sii_index];
        temp_debit_note_item.unit_price = debit_note_item.unit_price;
        temp_debit_note_item.unit = debit_note_item.unit,
        temp_debit_note_item.unit_id = debit_note_item.unit_id,
        temp_debit_note_item.gst_percent = debit_note_item.gst_percent;
        temp_debit_note_item.name = debit_note_item.name;
        temp_debit_note_item.quantity = debit_note_item.quantity;
        if (temp_debit_note_item.delete) temp_debit_note_item.delete = 0;
        this.debitNoteItems[old_sii_index] = temp_debit_note_item;
      } else {
        const debit_note_new_item = {
          ...debit_note_item,
          id: debit_note_item.id,
          debit_note_id: this.debitNote.id || "",
          is_old: debit_note_item.id ? 1 : 0,
          estimated_quantity: debit_note_item.quantity,
          gst_amount: debit_note_item.gst_amount,
          gst_percent: debit_note_item.gst_percent,
          name: debit_note_item.name,
          unit_price: debit_note_item.unit_price,
          unit: debit_note_item.unit,
          unit_id: debit_note_item.unit_id,
          quantity: debit_note_item.quantity,

        } as DebitNoteItem;
        this.debitNoteItems.push(debit_note_new_item);
      }
     }
    this.debitNoteItemCount = this.debitNoteItems.filter(obj => !obj.delete || obj.delete === 0).length;
    this.updateDebitTotals()
  }
  removeDebiteNoteItem(index:number) {
    if (this.debitNoteItems[index].id || this.debitNoteItems[index].is_old) {
      this.debitNoteItems[index].delete = 1;
      this.updateDebitTotals();
    } else {
      this.debitNoteItems.splice(index, 1);
      this.updateDebitTotals();
    }
    this.debitNoteItemCount = this.debitNoteItems.filter(obj => !obj.delete || obj.delete === 0).length;
  }
  updateDebitTotals() {
    this.debitNote.work_amount = 0;
    this.debitNote.gst_amount = 0;
    this.debitNote.amount = 0;
    this.debitNoteItems.forEach((item, index) => {
      if(item.delete !== 1)
      this.debitNoteItems[index].work_amount = item.unit_price * item.quantity || 0;
      this.debitNoteItems[index].gst_amount = (item.unit_price * item.quantity) * (item.gst_percent / 100);
      this.debitNoteItems[index].total_amount = this.debitNoteItems[index].work_amount + this.debitNoteItems[index].gst_amount;
      if (!item.delete) {
        this.debitNote.work_amount += this.debitNoteItems[index].work_amount;
        this.debitNote.gst_amount += this.debitNoteItems[index].gst_amount
      }
    })
    this.debitNote.gst_amount =Number(this.debitNote.gst_amount) || 0;
    this.debitNote.work_amount =Number(this.debitNote.work_amount) || 0;
    this.updateFinalTotals();
  }
  updateFinalTotals() {
    let total = (this.debitNote.work_amount || 0) + (this.debitNote.gst_amount || 0);
    this.debitNote.amount = total;
    this.debitNote.amount =Number(this.debitNote.amount) || 0
  }

  isBadData(): boolean {
    if (this.debitNoteItems.length == 0) {
      return true;
    }
    for (const item of this.debitNoteItems) {
      if (item.quantity == undefined) {
        this.notificationService.initiate({ title: "Invoice quantity mandatory.", type: toastTypes.error })
        return true;
      }
      if (item.quantity <= 0) {
        this.notificationService.initiate({ title: "Invoice quantity mandatory.", type: toastTypes.error })
        return true
      }
    }
    return false
  }
  multiply(a, b) {
    return Number(a * b) || 0
  }
  save(){
    this.debitNote.photos = this.attachmentView.filesFromChild();
    if (!this.debitNote.party_company_user_id) {
      return this.notificationService.initiate({ title: "Party should not be blank", type: toastTypes.error });
    } else if (!this.debitNote.amount) {
      return this.notificationService.initiate({ title: "Amount should not be 0!", type: toastTypes.warning });
    }else if (!this.model) {
      return this.notificationService.initiate({ title: "Date error", type: toastTypes.error });
    } else if (!this.model?.day || !this.model?.month || !this.model?.year) {
      return this.notificationService.initiate({ title: "Select Date!", type: toastTypes.warning });
    }
    if(!this.debitNote.project_id) this.debitNote.project_id = this.authService.session.project?.id || this.parent.project_id;
    this.debitNote.invoice_date = this.validatorService.dateToUtc(this.model.day, this.model.month, this.model.year);
    this.loading = true;
    if (this.parent.debit_note_id) {
      return this.editDebitNote(this.debitNote);
    }
    this.adminService.addDebitNote(this.debitNote).subscribe({
      next: (res) => {
        if(this.debitNoteItems.length > 0){
          this.bulkDebiteNoteitem(res.id);
        }
        this.canvas.close();
        this.loading = false;
      },
      error: (err) => {
        // this.notificationService.initiate({ title: err., type: toastTypes.error });
        this.loading = false;
        // console.error(err)
      }
    })
  }
  editDebitNote(debitNote: DebitNote) {
    this.loading = true;
    this.adminService.editDebitNote(debitNote).subscribe({
      next: (res) => {
        if(this.debitNoteItems.length > 0){
          this.bulkSEditDebitNoteItems(res.id);
        }else{
          this.canvas.close();
          this.loading = false;
        }
      },
      error: () => {
        this.loading = false;
      }
    })
  }
  bulkSEditDebitNoteItems(debit_note_id: string) {
    const data = this.calculate_n_update_debit_note_items(debit_note_id, true);
    if (data.length > 0) {
      this.adminService.bulkEditDebitNoteItems(data).subscribe({
        next: (res) => {
          this.bulkDebiteNoteitem(debit_note_id);
          // this.ngbActiveOffcanvas.close();
        },
        error: (err) => {
          this.loading = false;
          console.error(err)
        }
      })
    }else{
      this.bulkDebiteNoteitem(debit_note_id);
    }
  }
  calculate_n_update_debit_note_items(debit_note_id: string, shouldBeOld: boolean): any[] {
    const data = [];
    for (const sii of this.debitNoteItems) {
      const shouldInclude = shouldBeOld ? sii.is_old == 1 : sii.is_old != 1;
      if (shouldInclude) {
        sii.debit_note_id = debit_note_id;
        sii.work_amount = sii.quantity * sii.unit_price;
        sii.gst_amount = Number((sii.gst_percent * sii.work_amount) / 100) || 0;
        sii.total_amount = sii.work_amount + sii.gst_amount;
        data.push(sii);
      }
    }
    return data || [];
  }
  bulkDebiteNoteitem(debit_note_id: string) {
    const data = this.calculate_n_update_debit_note_items(debit_note_id, false);
    if (data.length > 0) {
      this.adminService.bulkAddDebitNoteItems(data).subscribe({
        next: (res) => {
          this.canvas.close();
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.error(err)
        }
      })
    } else {
      this.loading = false;
      this.canvas.close();

    }
  }
  setCompanyUser(companyUser?: CompanyUser) {
    if (companyUser) {
      this.debitNote.party_company_user_id = companyUser.id;
    }
  }
  inputShowStatus(inputTitleString: 'reference') {
      if (this.inputShow.includes(inputTitleString)) {
        const index = this.inputShow.indexOf(inputTitleString);
        if (this.inputShow[index] == 'reference') {
          //
        } else {
          return;
        }
        this.inputShow.splice(index, 1);
      } else {
        this.inputShow.push(inputTitleString);
      }
  }
  public lastEditIndex = -1;
  addDebiteNoteItem(index?){
    this.lastEditIndex = index;
    const ngbOffcanvasRef = this.ngbOffcanvas.open(AddDebiteNoteItemComponent, {
      position: 'end',
      backdrop: 'static',
      panelClass: 'offcanvas-sm'
    });
    ngbOffcanvasRef.componentInstance.parent = {debit_note_item: this.debitNoteItems[index]};
    ngbOffcanvasRef.result.then(onFilled => {
      if(onFilled.delete == 1 && this.lastEditIndex > -1){
        this.removeDebiteNoteItem(this.lastEditIndex);
      }else if(this.lastEditIndex > -1){
        this.debitNoteItems[this.lastEditIndex] = onFilled;
        this.updateDebitTotals();
      }else{
        this.setDebitNoteItems([onFilled]);
      }
      this.lastEditIndex = -1;
    }, onReject => {
      this.lastEditIndex = -1;
    })
  }
}
