import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from 'src/app/core/services/auth.service';
import { PremiumTagComponent } from "../../../shared/components/premium-tag/premium-tag.component";

@Component({
  selector: 'app-header-plans-ctas',
  templateUrl: './header-plans-ctas.component.html',
  styleUrls: ['./header-plans-ctas.component.css'],
  standalone: true,
  imports: [CommonModule, PremiumTagComponent]
})
export class HeaderPlansCtasComponent {
  public readonly appStoreUrl = 'https://apps.apple.com/in/app/onsite-construction-app/id6443621595';
  public readonly playStoreUrl = 'https://play.google.com/store/apps/details?id=com.app.onsite';
  public showElementBeforeExpirePlain = false;

  private readonly pricingUrls: Record<string, string> = {
    '91': 'https://onsiteteams.com/india-pricing-d/',
    '971': 'https://onsiteteams.com/pre-pricing/',
    '966': 'https://onsiteteams.com/pre-pricing/',
    '974': 'https://onsiteteams.com/pre-pricing/',
    '968': 'https://onsiteteams.com/pre-pricing/',
    '62': 'https://onsiteteams.com/indo-new-pricing/',
    '977': 'https://onsiteteams.com/india-pricing-d/',
    'default': 'https://onsiteteams.com/pre-pricing/'
  };

  private authService = inject(AuthService);

  ngOnInit(): void {
    this.showElementBeforeExpirePlain = this.shouldShowElementBeforeExpirePlain();
  }

  private shouldShowElementBeforeExpirePlain(): boolean {
    const company = this.authService.session.company;
    const today = new Date();
    const subscriptionEndDate = new Date(company.subscription_end_date);
    const daysBeforeExpiry = (subscriptionEndDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24);

    if (company.is_paid === 0) {
      return true;
    }
    if (daysBeforeExpiry <= 15 || subscriptionEndDate < today) {
      return true;
    }
    return false;
  }

  openViewPlansUrl(): void {
    const countryCode = this.authService.session.country.country_code;
    const url = this.pricingUrls[countryCode] || this.pricingUrls['default'];
    window.open(url, '_blank');
  }
}
