import { Directive, HostListener, Input, Self, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[decimalPlaces]'
})
export class DecimalPlacesDirective implements OnInit {
  @Input('decimalPlaces') decimals = 4; // Default to 4 decimal places

  constructor(@Self() private ngControl: NgControl) {}

  ngOnInit() {
    const control = this.ngControl.control;
    if (control) {
      const value = control.value;
      if (value !== null && value !== undefined) {
        // Format the prefilled value
        const formatted = this.formatValue(value, true);
        // Convert to a number if possible
        const numericValue = parseFloat(formatted);
        control.setValue(isNaN(numericValue) ? formatted : numericValue);
      }
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    const rawValue = input.value;

    // Format value while typing
    const formattedValue = this.formatValue(rawValue, false);

    // Only update if the value has changed
    if (rawValue !== formattedValue) {
      input.value = formattedValue;
      // Set the control value to a number if the formatted value is valid
      this.ngControl.control.setValue(
        formattedValue === '' || formattedValue === '.'
          ? ''
          : parseFloat(formattedValue)
      );
    }
  }

  /**
   * Format the input value based on the decimal limit.
   * If isPrefill is true, trailing zeros will be removed.
   */
  private formatValue(value: string | number, isPrefill: boolean): string {
    if (typeof value === 'number') {
      value = value.toString();
    }

    // If there is a decimal point, limit the decimal part length
    if (value.includes('.')) {
      let [whole, decimal] = value.split('.');
      decimal = decimal.slice(0, this.decimals);

      // If the user just typed a dot (e.g., "5."), return that as is.
      if (!decimal) {
        return `${whole}.`;
      }

      // Remove trailing zeros only for prefilled values.
      if (isPrefill) {
        decimal = decimal.replace(/0+$/, '');
      }

      // If the decimal part becomes empty after removing trailing zeros, return the whole number.
      return decimal ? `${whole}.${decimal}` : whole;
    }

    return value;
  }
}
