import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category, Company, MaterialCategory, Member, Project, Subcategory } from 'src/app/interfaces/interfaces';
import { HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AdminService } from 'src/app/core/services/admin.service';
import { UtilService } from '../../services/util.service';
import { Subcategory_type } from 'src/app/enum/enum';


interface Parent {
  page: 'Payment' | 'Material' | 'Attendance' | 'Party' | 'Warehouse' | 'company_material_library'
}

interface Filter {
  project?: Project;
  party_company_user?: any;
  category?: any;
  sub_category?: any;
  start_date?: any;
  end_date?: any;
}
@Component({
  selector: 'app-filter-feature',
  templateUrl: './filter-feature.component.html',
  styleUrls: ['./filter-feature.component.css']
})
export class FilterFeatureComponent implements OnInit {
  @Output() onFilterParameter = new EventEmitter<any>(true)
  @Input() parent: Parent;

  public filterPopUp = false;
  public selectedFilterSwitch = 'transaction';
  public totalSelectedFilterCount = 0;
  public fromDate = '';
  public toDate = '';
  public today = this.formatDate(new Date);
  public subCategoryList = [] as Subcategory[]
  public MaterialCategoryList = [] as MaterialCategory[]
  public subcategory_type = Subcategory_type
  //For Payment page filter
  public paymentPageFilter = {
    // 'date': { name: 'Date', hasSelectedValue: [] },
    'transaction': { name: 'Transaction', hasSelectedValue: [] },
    'party': { name: 'Party', hasSelectedValue: [] },
    'trade': { name: 'Cost Code', hasSelectedValue: [] },
    'category': { name: 'Category', hasSelectedValue: [] },
    'creator': { name: 'Entry by', hasSelectedValue: [] },
    'paymentMode': { name: 'Mode', hasSelectedValue: [] },
    'project': { name: 'Project', hasSelectedValue: [] },
    'status': { name: 'Status', hasSelectedValue: [] },
  }

  // Party or members
  public members = [] as Member[];
  public partySearchString?: string;

  //Date
  public myDateType = {
    'all': { name: 'All' },
    'today': { name: 'Today' },
    'lastWeek': { name: 'Last Week (Mon - Sun)' },
    'lastMonth': { name: 'Last Month' },
    'thisWeek': { name: 'This Week (Mon - Today)' },
    'thisMonth': { name: 'This Month' }
  }
  //Cost Code Filter
  public materialLibraryFilter = {
    'costcode':{name:'Cost Code',  hasSelectedValue: []},
    'material_category':{name:'Material Category',  hasSelectedValue: []}
  }
  public myDate;
  public start_date;
  public end_date;

  public statusType = {
    'paid': { name: 'Paid' },
    'partiallypaid': { name: 'Partially Paid' },
    'unpaid': { name: 'Unpaid' },
  };


  //transaction_type=materialreturn
  // Transaction Type
  public myTransactionType = {
    'all': { name: 'All', isSelected: false },
    'cashbook_deposit': { name: 'Payment In', isSelected: false },
    'cashbook_withdraw': { name: 'Payment Out', isSelected: false },
    'material': { name: 'Material Purchase', isSelected: false },
    'partyearning': { name: 'Other Expense', isSelected: false },
    'staff_payments': { name: 'Party Payment', isSelected: false },
    'reimbursement': { name: 'Site Expense', isSelected: false },
    'customerinvoice': { name: 'Sales Invoice', isSelected: false },
    'materialreturn': { name: 'Material Return', isSelected: false },
    'material_transfer': { name: 'Material Transfer', isSelected: false },
    'subconexpense': { name: 'Sub-Con Expense', isSelected: false },
    'debitnote': { name: 'Debit Note', isSelected: false },
    // 'party_to_party_payment': { name: 'Party to Party Payment', isSelected: false },
  };




  public paymentMode = {
    // 'Cash': { name: 'Cash', isSelected: false },
    // 'Online': { name: 'Online', isSelected: false },
    'All': { name: 'All', isSelected: false },
    'Cash': { name: 'Cash', isSelected: false },
    'Bank Transfer':{
      name:'Bank Transfer', isSelected:false
    },
    'Cheque':{
      name:'Cheque', isSelected:false
    }
  }

  public expenseStatus = {
    'paid': { name: 'Paid', isSelected: false },
    'unpaid': { name: 'Unpaid', isSelected: false },
    'partiallypaid': { name: 'Partially Paid', isSelected: false }
  }

  //Cat or sub cat
  public subCategories = [] as Subcategory[];
  public categories = [] as Category[];
  public projectList = [] as Project[];

  public project = {} as Project;
  public company = {} as Company;
  public Object = Object;
  public selectedTypeFilter = {};

  public valueStackSubCategory = {
    page: { next_url: "" },
  };
  public valueStackProject = {
    page: { next_url: "" },
  };
  
  public busy = false;

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private utilService: UtilService
  ) {
    this.project = this.authService.session.project as Project || {};
    this.company = this.authService.session.company as Company || {};
  }
  ngOnInit(): void {
    this.selectedTypeFilter = this.paymentPageFilter
    if (this.parent.page === 'Warehouse') {
      delete this.myTransactionType['cashbook_deposit'];
      delete this.myTransactionType['cashbook_withdraw'];
      delete this.myTransactionType['partyearning'];
      delete this.myTransactionType['staff_payments'];
      delete this.myTransactionType['reimbursement'];
      delete this.myTransactionType['customerinvoice'];
    }
    if (this.project?.id) {
      delete this.paymentPageFilter['project'];
    }
    if(this.parent.page === 'company_material_library') {
       this.selectedTypeFilter = this.materialLibraryFilter
       this.selectedFilterSwitch = 'costcode'
    
    }
  }

 


  toggleFilter() {
    if(this.parent.page == 'company_material_library'){
      this.getCostCodeList(Subcategory_type.costCode);
      this.getCostCodeList(Subcategory_type.materialCategory)
    }else {
      this.getParties();
    this.getTransactionSubcategory();
    this.getCategory();
    this.transactionCreators();
    if (!this.project?.id) {
      this.getTransactionProjectList();
    }
    }
    
    this.filterPopUp = true;

  }

  getCostCodeList(type:Subcategory_type){
    let qp = new HttpParams()
    qp = qp.set('company_id', this.company.id)
    qp = qp.set('type', type)
    this.adminService.subcategoryList(qp).subscribe({
    next:res=>{
      if(type == Subcategory_type.costCode){
        this.subCategoryList = res.subcategories
      }else {
        this.MaterialCategoryList = res.subcategories
      }
    },
    error:err=>console.error(err)
   })
  }


  applyFilter() {
    this.filterPopUp = false;
    let qp = new HttpParams();
    if(this.parent.page === 'company_material_library'){
       if(this.materialLibraryFilter.costcode.hasSelectedValue.length > 0){
        qp = qp.set("sub_category_id", this.materialLibraryFilter.costcode.hasSelectedValue[0])
       }

       if(this.materialLibraryFilter.material_category.hasSelectedValue.length > 0){
        qp = qp.set("material_sub_category_id", this.materialLibraryFilter.material_category.hasSelectedValue[0])
       }

  

    

    } else {
      if (this.paymentPageFilter.category.hasSelectedValue.length > 0) {
        qp = qp.set("category_id", this.paymentPageFilter.category.hasSelectedValue[0]);
      }
      if (this.paymentPageFilter.party.hasSelectedValue.length > 0) {
        qp = qp.set("company_user_id", this.paymentPageFilter.party.hasSelectedValue[0]);
      }
      if (this.paymentPageFilter.creator.hasSelectedValue.length > 0) {
        qp = qp.set("creator_company_user_id", this.paymentPageFilter.creator.hasSelectedValue[0]);
      }
      if (this.paymentPageFilter.trade.hasSelectedValue.length > 0) {
        qp = qp.set("sub_category_id", this.paymentPageFilter.trade.hasSelectedValue[0]);
      }
      if (this.paymentPageFilter.transaction.hasSelectedValue.length > 0) {
        if (this.paymentPageFilter.transaction.hasSelectedValue[0] != 'all') {
          qp = qp.set("transaction_type", this.paymentPageFilter.transaction.hasSelectedValue[0]);
        }
      }
      if (this.paymentPageFilter.paymentMode.hasSelectedValue.length > 0) {
        if (this.paymentPageFilter.paymentMode.hasSelectedValue[0] != 'All') {
          qp = qp.set("payment_mode", this.paymentPageFilter.paymentMode.hasSelectedValue[0]);
        }
      }
      if (this.paymentPageFilter.project?.hasSelectedValue.length > 0) {
        qp = qp.set("project_id", this.paymentPageFilter.project.hasSelectedValue[0]);
      }
      if (this.paymentPageFilter.status.hasSelectedValue.length > 0) {
        qp = qp.set("invoice_status", this.paymentPageFilter.status.hasSelectedValue[0]);
      }
    }
    // if (this.paymentPageFilter.expenseStatus.hasSelectedValue.length > 0) {
    //   qp = qp.set("invoice_status", this.paymentPageFilter.expenseStatus.hasSelectedValue[0]);
    // }
    // if (this.paymentPageFilter.date.hasSelectedValue.length > 0) {
    //   if (this.paymentPageFilter.transaction.hasSelectedValue[0] != 'all') {
    //     if (this.start_date) {
    //       qp = qp.set("start_date", this.start_date);
    //     }
    //     if (this.end_date) {
    //       qp = qp.set("end_date", this.end_date);
    //     }
    //   }
    // }
    this.sendQParamstoParent(qp);
  }

  sendQParamstoParent(qp) {
    this.onFilterParameter.emit({ queryParams: qp, filterCount: this.totalSelectedFilterCount });
  }

  clearFilter() {
    

    if(this.parent.page == 'company_material_library'){
      
      this.materialLibraryFilter = {
        'costcode':{name:'Cost Code',  hasSelectedValue: []},
        'material_category':{name:'Material Category',  hasSelectedValue: []}
      }

      this.selectedTypeFilter = this.materialLibraryFilter 
    } else {
      this.paymentPageFilter = {
        // 'date': { name: 'Date', hasSelectedValue: [] },
        'transaction': { name: 'Transaction', hasSelectedValue: [] },
        'party': { name: 'Party', hasSelectedValue: [] },
        'trade': { name: 'Cost Code', hasSelectedValue: [] },
        'category': { name: 'Category', hasSelectedValue: [] },
        'creator': { name: 'Entry by', hasSelectedValue: [] },
        'paymentMode': { name: 'Mode', hasSelectedValue: [] },
        'project': { name: 'Project', hasSelectedValue: [] },
        'status': { name: 'Status', hasSelectedValue: [] },
      }
  
      if (this.project?.id) {
        delete this.paymentPageFilter['project'];
      }

      this.selectedTypeFilter = this.paymentPageFilter
    }

   

   
    this.clearDate();
    this.totalSelectedFilterCount = 0;
    this.onFilterParameter.emit(null);
    this.filterPopUp = false;
  }
  clearDate() {
    this.fromDate = null;
    this.toDate = null;
  }
  getTotalSelectedFilterCount() {
    this.totalSelectedFilterCount = 0;
    if(this.parent.page == 'company_material_library'){
      for (const [key, item] of this.Object.entries(this.materialLibraryFilter)){
        this.totalSelectedFilterCount += item.hasSelectedValue.length;
      }
    }else{
      for (const [key, item] of this.Object.entries(this.paymentPageFilter)) {
        this.totalSelectedFilterCount += item.hasSelectedValue.length;
      }
    }
   
  }

  getParties() {
    let qp = new HttpParams();
    if (this.project?.id) {
      qp = qp.set('project_id', this.project.id);
    } else {
      qp = qp.set('company_id', this.company.id);
    }
    qp = qp.set('count', 150);
    //list/transaction/companyuser?
    this.authService.listTransactionCompanyUser(qp).subscribe({
      next: (res) => {
        this.members = res.data;
      }
    });
  }



  pullData(term: string): Observable<any[]> {
    let qp = new HttpParams();
    qp = qp.set('search', term);
    if (this.project?.id) {
      qp = qp.set('project_id', this.project.id);
    } else {
      qp = qp.set('company_id', this.company.id);
    }
    return this.authService.listTransactionCompanyUser(qp).pipe(map(res => {
      this.members = res.data;
      return [];
    }));
  }

  partySearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.pullData(term).pipe(
          catchError(() => {
            return of([]);
          }))
      )
    )
  addRemoveParty(item: Member) {
    if (this.paymentPageFilter['party'].hasSelectedValue.includes(item.id)) {
      this.paymentPageFilter['party'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['party'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveCreator(item: Member) {
    if (this.paymentPageFilter['creator'].hasSelectedValue.includes(item.id)) {
      this.paymentPageFilter['creator'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['creator'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  // Date
  addRemoveDate(key) {
    if (this.paymentPageFilter['date'].hasSelectedValue.includes(key) && key != 'custom') {
      this.paymentPageFilter['date'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['date'].hasSelectedValue = [key]
    }
    this.dateRangeSet(key);
    this.getTotalSelectedFilterCount();
  }

  formatDate(date) {
    if (!date) return;
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  dateRangeSet(input) {
    const nowDate = new Date();
    if (input == "all") {
      this.start_date = null;
      this.end_date = null;
      this.fromDate = null;
      this.toDate = null;
      return;
    } else if (input == "today") {
      this.start_date = this.currentZoneToUtc(nowDate, 'sd');
      this.end_date = this.currentZoneToUtc(nowDate, 'ed');
    } else if (input == "lastWeek") {
      nowDate.setDate(nowDate.getDate() - ((nowDate.getDay() + 6) % 7));
      const d = new Date().getDate() - nowDate.getDate();
      d < 7 ? nowDate.setDate(nowDate.getDate() - 7) : nowDate;
      this.start_date = this.currentZoneToUtc(nowDate, 'sd');
      this.end_date = new Date();
      this.end_date.setDate(nowDate.getDate() + 6);
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "lastMonth") {
      this.start_date = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 0);
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "thisWeek") {
      const d = nowDate.getDay();
      const mon = new Date(nowDate);
      // this.start_date = new Date(mon.setDate(mon.getDate() - d + 1));
      this.start_date = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date();
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "thisMonth") {
      this.start_date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date();
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "custom") {
      this.start_date = this.currentZoneToUtc(new Date(), 'sd');
      this.end_date = this.currentZoneToUtc(new Date(), 'ed');
      if (this.fromDate) {
        this.start_date = new Date(this.fromDate);
        this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      }
      if (this.toDate) {
        this.end_date = new Date(this.toDate);
        this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
      }
      if (this.start_date >= this.end_date) {
        if (!this.fromDate) {
          this.start_date = this.end_date;
        }
        if (this.fromDate && this.toDate) {
          this.end_date = this.start_date;
        }
      }
    }
    this.fromDate = this.formatDate(this.start_date);
    this.toDate = this.formatDate(this.end_date);
  }

  currentZoneToUtc(date: Date, dateFor: 'sd' | 'ed') {
    if (dateFor == 'sd') {
      date.setHours(0, 0, 0, 1);
    } else if (dateFor == 'ed') {
      date.setHours(23, 59, 59, 999);
    }
    return date.toISOString();
  }

  //Transaction
  addRemoveTransaction(key) {
    if (this.paymentPageFilter['transaction'].hasSelectedValue.includes(key)) {
      this.paymentPageFilter['transaction'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['transaction'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  //Transaction
  addRemovePaymentMode(key) {
    if (this.paymentPageFilter['paymentMode'].hasSelectedValue.includes(key)) {
      this.paymentPageFilter['paymentMode'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['paymentMode'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveProject(key) {
    if (this.paymentPageFilter['project'].hasSelectedValue.includes(key)) {
      this.paymentPageFilter['project'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['project'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveStatus(key) {
    if (this.paymentPageFilter['status'].hasSelectedValue.includes(key)) {
      this.paymentPageFilter['status'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['status'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  //Trade
  getTransactionSubcategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    if(this.project?.id){
      qp = qp.set('project_id', this.project.id);
    }
    this.adminService.transactionSubcategoryList(qp).subscribe((res) => {
      this.valueStackSubCategory = res;
      this.subCategories = res.subcategories || [] as Subcategory[];
    });
  }

  addRemoveTrade(item: Subcategory) {
    if (this.paymentPageFilter['trade'].hasSelectedValue.includes(item.id)) {
      this.paymentPageFilter['trade'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['trade'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  //Category
  getCategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    qp = qp.set('type', 'cashbook');
    this.adminService.categoryList(qp).subscribe({
      next: res => this.categories = res.categories || [] as Category,
      error: err => console.error(err)
    });
  }

  creator_companyUsers = [] as Member[];
  transactionCreators() {
    let qp = new HttpParams();
    if (this.project?.id) {
      qp = qp.set('project_id', this.project.id)
    } else {
      qp = qp.set('company_id', this.company.id)
    }
    this.adminService.getTransactionCreators(qp).subscribe({
      next: res => this.creator_companyUsers = res.data || [] as Member[],
      error: (err) => console.error(err)
    });
  }

  getTransactionProjectList() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id)
    this.adminService.getProjectList(qp).subscribe({
      next: res => {
        this.projectList = res.projects || [] as Project,
        this.valueStackProject = res;
      },
      error: err => console.error(err)
    });
  }

  addRemoveCategory(item: Category) {
    if (this.paymentPageFilter['category'].hasSelectedValue.includes(item.id)) {
      this.paymentPageFilter['category'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['category'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveExpense(key) {
    if (this.paymentPageFilter['expenseStatus'].hasSelectedValue.includes(key)) {
      this.paymentPageFilter['expenseStatus'].hasSelectedValue = []
    } else {
      this.paymentPageFilter['expenseStatus'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveCostCode(key){
    if(this.materialLibraryFilter['costcode'].hasSelectedValue.includes(key)){
      this.materialLibraryFilter['costcode'].hasSelectedValue = []
    } else {
      this.materialLibraryFilter['costcode'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount()
  }

  addRemoveMaterialCategory(key){
  
    if(this.materialLibraryFilter['material_category'].hasSelectedValue.includes(key)){
      this.materialLibraryFilter['material_category'].hasSelectedValue = []
    } else {
      this.materialLibraryFilter['material_category'].hasSelectedValue = [key]
    }
   
    this.getTotalSelectedFilterCount()
  }

  onScrollProject() {
    const url = this.valueStackProject.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
          this.valueStackProject = res;
          this.projectList = this.subCategories.concat(res.projects || [] as Project[]);
          this.busy = false;
        },
        (err) => {
          this.busy = false;
          this.valueStackProject = {
            page: { next_url: "" },
          };
        }
      );
    }
  }

  onScrollSubCategory() {
    const url = this.valueStackSubCategory.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
          this.valueStackSubCategory = res;
          this.subCategories = this.subCategories.concat(res.subcategories || [] as Subcategory[]);
          this.busy = false;
        },
        (err) => {
          this.busy = false;
          this.valueStackSubCategory = {
            page: { next_url: "" },
          };
        }
      );
    }
  }

  onScrollCostCodeSubcategory(type:Subcategory_type){
    const url = this.valueStackSubCategory.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
          this.valueStackSubCategory = res;
           if(type == Subcategory_type.costCode){
            this.subCategoryList = this.subCategoryList.concat(res.subcategories || [] as Subcategory[]);
           }else{
            this.MaterialCategoryList = this.MaterialCategoryList.concat(res.subcategories || [] as Subcategory[]);
           }
          this.busy = false;
        },
        (err) => {
          this.busy = false;
          this.valueStackSubCategory = {
            page: { next_url: "" },
          };
        }
      );
    }
  }
}
