import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-premium-tag',
  templateUrl: './premium-tag.component.html',
  styleUrls: ['./premium-tag.component.css']
})
export class PremiumTagComponent {
  @Input() small:boolean;
  public expiry_date = new Date();
 constructor(
  private authService:AuthService
 ){
  this.expiry_date = this.authService.session.company.subscription_end_date
 }


}
