import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from 'src/app/company/services/company.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import {  Company } from 'src/app/interfaces/interfaces';

interface UserBank {
  id?:string;
  company_user_id?:string;
  account_name?:string;
  account_number?:string;
  bank_code?:string;
	iban_number?:string;
	bank_name?:string;
	bank_address?:string; 
  upi_ids?:string[];
}

interface Parent{
  from:string;
  hasRequestType:'edit'|'add';
  hasCompanyBank:UserBank; 
}

@Component({
  selector: 'app-party-bank',
  templateUrl: './party-bank.component.html',
  styleUrls: ['./party-bank.component.css']
})
export class PartyBankComponent implements OnInit {
  @Input() parent = {} as Parent;
  
  public company = {} as Company;
  public bankLogoUrl: string;
  public defaultBankLogo: string;

  public bankName:string;
  public bankAddress:string;
  public upi_id:string;
  public userBank = {
    upi_ids:[] as string[]
  } as UserBank;

  constructor(
    private companyService: CompanyService,
    public authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private notificationsService: NotificationsService
  ) {
  }
  ngOnInit(): void {
  
    if(this.parent.hasRequestType === 'edit'){
      this.onValidateBankCode(this.parent.hasCompanyBank.bank_code);
      this.userBank.id = this.parent.hasCompanyBank.id;
      this.userBank.account_name = this.parent.hasCompanyBank.account_name;
      this.userBank.account_number = this.parent.hasCompanyBank.account_number;
      this.userBank.bank_code = this.parent.hasCompanyBank.bank_code;
      this.userBank.bank_name = this.parent.hasCompanyBank.bank_name;
      this.userBank.bank_address = this.parent.hasCompanyBank.bank_address;
      this.userBank.iban_number = this.parent.hasCompanyBank.iban_number;
      if(this.parent.hasCompanyBank.upi_ids?.length){
        this.upi_id = this.parent.hasCompanyBank.upi_ids[0];
      }
    }else if(this.parent.hasRequestType === 'add' && this.parent.from === 'company-party'){
      this.userBank.company_user_id = this.parent.hasCompanyBank.company_user_id;
    }else if(this.parent.hasRequestType === 'add' && this.parent.from === 'add-party'){
      this.onValidateBankCode(this.parent.hasCompanyBank.bank_code);
      this.userBank.account_name = this.parent.hasCompanyBank?.account_name;
      this.userBank.account_number = this.parent.hasCompanyBank?.account_number;
      this.userBank.bank_code = this.parent.hasCompanyBank?.bank_code;
      this.userBank.bank_name = this.parent.hasCompanyBank.bank_name;
      this.userBank.bank_address = this.parent.hasCompanyBank.bank_address;
      this.userBank.iban_number = this.parent.hasCompanyBank.iban_number;
      if(this.parent.hasCompanyBank.upi_ids?.length){
        this.upi_id = this.parent.hasCompanyBank.upi_ids[0];
      }
    }
  }


  onValidateBankCode(e) {
    const regexIfsc = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
    const ifscCode = regexIfsc.test(e);
    if (ifscCode) this.getDetailsFromIfsc(e);
    if(e?.length < 4) return;
    let bankCode = '';
    bankCode = e?.slice(0,4);
    this.bankLogoUrl = `https://onsite22.sgp1.cdn.digitaloceanspaces.com/bank_logo/${bankCode?.toUpperCase()}_LOGO.jpg`;
  }

  getDetailsFromIfsc(ifsc: string) {
    this.companyService.getBankDetails(ifsc).subscribe({
      next: (res) => {
        this.userBank.bank_name = res.BANK;
        this.userBank.bank_address = res.ADDRESS;
      },
      error: (err) => {
        // console.log('error', err);
        this.userBank.bank_name = "";
        this.userBank.bank_address = "";
      }
    });
  }

  save(){
    if(!this.userBank.account_name 
    || this.userBank.account_name.trim()==""
    || !this.userBank.account_number){
      return this.notificationsService.initiate({
        title: 'Account name & number can\'t be empty',
        type: toastTypes.error
      })
    }

    if(this.parent.hasRequestType === 'add'){
      if(this.parent.from === 'company-party'){
        this.addCompanyUserbankAccount();
      }else if(this.parent.from === 'add-party'){
        this.userBank.upi_ids.push(this.upi_id);
        this.ngbActiveOffcanvas.close(this.userBank);
      }
    }else if(this.parent.hasRequestType === 'edit'){
      this.editCompanyUserbankAccount();
    }
  }

  editCompanyUserbankAccount() {
    if(this.upi_id){
      this.userBank.upi_ids.push(this.upi_id);
    }
    if(!this.userBank.account_number){
      return this.notificationsService.initiate({
        title: 'Account number required',
        type: toastTypes.error
      });
    }
    this.companyService.editCompanyUserbankAccount(this.userBank).subscribe({
      next: (res) => {
        this.ngbActiveOffcanvas.close(res);
      },
      error: (err) => {
        //
      },
    });
  }

  addCompanyUserbankAccount() {
    if(this.upi_id){
      this.userBank.upi_ids.push(this.upi_id);
    }
    this.companyService.addCompanyUserbankAccount(this.userBank).subscribe({
      next: (res) => {
        this.ngbActiveOffcanvas.close(res);
      },
      error: (err) => {
        //
      },
    });
  }

 dismissOffCanva(){
  return this.ngbActiveOffcanvas.dismiss();
 }

  
}




