  <div class="payment-status-modal">
    <div class="payment-status-card">
      <div class="status-logo" style="height:96px; width:96px">
        <img class="img-fluid" src="../../../assets/svg/payment-done.svg" alt="pms">
      </div>
      <div class="payment-status-body vstack ">
        <span class="status-title">Thank You</span>
        <span class="status-subtitle">Your Transaction with us was successful for</span>
        <div class="hstack justify-content-center align-items-center py-4">
          <span class="payment-amount">₹ {{parent.monkey_patch_subscription_plan_breakup.amount}}</span>
          <span class="payment-duration">/Year</span>
        </div>

        <div class="hstack justify-content-center align-items-center payment-company">
          <span>{{parent.monkey_patch_comapny.name}}</span>
        </div>

        <div class="payment-mode py-3">
          <div class="hstack align-items-center justify-content-between">
            <span class="payment-mode-key">Payment Mode</span>
            <span class="payment-mode-value">{{parent.payment_mode | uppercase}}</span>
          </div>
          <div class="hstack align-items-center justify-content-between">
            <span class="payment-mode-key">Plan Type</span>
            <span class="payment-mode-value">{{parent.monkey_patch_subscription_plan_breakup.type | uppercase}}</span>
          </div>
          <div class="hstack align-items-center justify-content-between">
            <span class="payment-mode-key">Plan Renews on</span>
            <span class="payment-mode-value">{{parent.subscription_end_date | date :'d MMM y'}}</span>
          </div>
        </div>

        <div class="extra-charges py-3">
          <div class="hstack align-items-center justify-content-between">
            <span class="payment-mode-key">Plan Charge</span>
            <span class="payment-mode-value">₹ {{parent.monkey_patch_subscription_plan_breakup.amount}}</span>
          </div>
          <div class="hstack align-items-center justify-content-between">
            <span class="payment-mode-key">{{authService.session.country.tax_value_display_name?authService.session.country.tax_value_display_name:'Tax'}}</span>
            <span class="payment-mode-value">₹ {{parent.monkey_patch_subscription_plan_breakup.gst_amount}}</span>
          </div>
        </div>
        <hr>
        <div class="hstack align-items-center justify-content-between">
          <span class="Total-payment-key">Total Charge</span>
          <span class="Total-payment-value">₹ {{parent.price_paid | dynamicNumber: {currency:1} }}</span>
        </div>

        <span class="invoice-msg">You will receive shortly</span>
        <span class="continue-btn pointer" (click)="ngbActiveModal.close()">Continue</span>
      </div>

    </div>
  </div>
