import { HttpParams } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveOffcanvas, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { DebitNote, DebitNoteItem } from 'src/app/interfaces/interfaces';
import { AttachmentViewComponent } from 'src/app/shared/components/attachment-view/attachment-view.component';
import { ConfirmModalComponent } from 'src/app/shared/components/modal/confirm-modal/confirm-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { AddDebitNoteComponent } from '../add-debit-note/add-debit-note.component';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
export interface Parent {
  project_id?: string;
  debit_note_id?: string;
}
@Component({
  selector: 'app-view-debit-note',
  templateUrl: './view-debit-note.component.html',
  styleUrls: ['./view-debit-note.component.css']
})
export class ViewDebitNoteComponent {
  @ViewChild('child') attachmentView: AttachmentViewComponent;
  @Input() parent = {} as Parent;
  public debitNote = {} as DebitNote;
  public debitNoteItems = [] as DebitNoteItem[];
  public loading: boolean = false;
  public isSameStateGst = true;
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    public adminService: AdminService,
    public authService: AuthService,
    public ngbModal: NgbModal,
    public ngbActiveOffcanvas: NgbActiveOffcanvas,
    public ngbOffcanvas: NgbOffcanvas,
    public notificationsService: NotificationsService,
    private utilService:UtilService
  ){}
  ngOnInit(): void {
    this.loading = true;
    this.getDebitNoteDetails(this.parent.debit_note_id);
  }
  getDebitNoteDetails(id) {
    this.adminService.getDetailDebitNote(id).subscribe({
      next: (res) => {
        this.loading = false;
        this.debitNote = res || {} as DebitNote;
        this.debitNote.amount = this.debitNote.total_amount;
        this.debitNoteItems = this.debitNote.monkey_patch_debit_note_items || [];
        if (this.debitNote?.photos?.length > 0) {
          this.attachmentView.getFiles(this.debitNote.photos);
        }
        this.isSameStateGst = this.utilService.isSameStateGst(this.authService.session.company.gstin, this.debitNote.monkey_patch_party_company_user.gstin);
      },
      error: () => {
        this.loading = false;
      }
    })
  }
  confirmation(modalContent){
    const deleteConfirmationModal = this.ngbModal.open(ConfirmModalComponent);
    deleteConfirmationModal.componentInstance.data = { modalTitle: "Warning", modalContent };
    return deleteConfirmationModal.result
  }
  delete() {
    if (!this.authService.hasAccess(this.authService.companyUserPolicy.EDIT_DEBIT_NOTE)) {
      this.notificationsService.initiate({ title: 'You don\'t have access', type: toastTypes.error });
      return
    }
    this.confirmation("Are you sure to delete this item?").then(fulfilled => {
        this.adminService.deleteDebitNote(this.parent.debit_note_id).subscribe({
          next: res => {
            this.ngbActiveOffcanvas.close()
          },
          error: err => this.notificationsService.initiate({ title: err.error.message, type: toastTypes.warning })
        })
    }, reject => {
      console.log(reject)
    })
  }
  edit(){
    if (!this.authService.hasAccess(this.authService.companyUserPolicy.EDIT_DEBIT_NOTE)) {
      this.notificationsService.initiate({ title: 'You don\'t have access', type: toastTypes.error });
      return
    }
    this.addEditDebitNote(this.parent.debit_note_id);
  }
  addEditDebitNote(id?: string) {
    const ngbOffcanvasRef = this.ngbOffcanvas.open(AddDebitNoteComponent, {
      position: 'end',
      backdrop: 'static',
      panelClass: 'offcanvas-sm'
    });
    ngbOffcanvasRef.componentInstance.parent = {
      debit_note_id: id
    }
    
    ngbOffcanvasRef.result.then(onFilled => {
      this.ngbActiveOffcanvas.close();
    }, onReject => {
    })
  }
}
