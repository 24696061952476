import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbModal,
  NgbOffcanvas,
} from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyRole, CompanyUser, FileUploadOptions, Member, Project } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ImgEditModalComponent } from '../img-edit-modal/img-edit-modal.component';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { Title } from '@angular/platform-browser';
import { ValidatorService } from 'src/app/shared/services/validator.service';
@Component({
  selector: 'app-setting-warehouse',
  templateUrl: './setting-warehouse.component.html',
  styleUrls: ['./setting-warehouse.component.css']
})
export class SettingWarehouseComponent {
  @Input() data;
  public tempImg;
  public warehouseTab = 'warehouseDetails';
  public loading = false;
  public companySearchBox = false;
  public manageAccessOptionsShow = -1;
  newManageAccessOptionsShow = false;
  public accessBtnActive = '';
  public accessBox = false;
  public members = [] as Member[];
  public member = {} as Member;
  public warehouse = {} as Project;
  public companyUsers = [] as CompanyUser[];
  public companyUser = {} as CompanyUser;
  public companyRoles = [] as CompanyRole[];
  public objectKeys = Object.keys;
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public authService: AuthService,
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    public utilService: UtilService,
    private ngbOffcanvas: NgbOffcanvas,
    private notificationsService: NotificationsService,
    public title: Title,
    private validatorService: ValidatorService,
  ) {
    title.setTitle("Warehouse Setting");
    if(this.authService.session.project?.type == 'w'){
      this.warehouse = this.authService.session.project;
    }
  }

  ngOnInit(): void {
    this.data.fromPage ? this.warehouseTab = this.data.fromPage : this.warehouseTab = 'warehouseDetails';
    this.warehouse.company_id = this.authService.session.company.id;
    this.warehouse.name = 'Warehouse';
  }
  ngOnDestroy(): void {
    this.localStorageService.remove('companyUserList');
  }

  close() {
    this.activeModal.close(this.members);
  }

  setFeatures(value: any, e: Event) {
    e.stopPropagation();
  }
  getMemberList(warehouse_id: string) {
    let qp = new HttpParams();
    qp = qp.set('project_id', warehouse_id);
    this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      this.members = res.data || ([] as Member);
    });
  }

  setMemberAccess(role: CompanyRole) {
    this.patchCompanyUser(this.companyUser.id, role.id)
  }

  deleteTeamMember() {
    const deleteModal = this.modalService.open(ConfirmModalComponent);
    deleteModal.componentInstance.data = {
      modalTitle: 'Remove member',
      modalContent: 'Are you sure?',
    };
    deleteModal.result.then(
      (onfulfilled) => {
        this.adminService.deleteTeamMember(this.member.id).subscribe((res) => {
          const member_index = this.members.findIndex((m) => m.id === res.id);
          this.members.splice(member_index, 1);
          this.accessBox = false;
          this.companyUser = {};
          this.member = {};
          this.utilService.memberCount.next(this.members.length);
        }, err => console.log(err), () => {
          this.member = {};
        });
      },
      (onrejected) => this.member = {}
    );
  }

  getCompanyUserList() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.authService.session.company.id);
    this.adminService.getCompnayUserList(qp).subscribe((res) => {
      this.companyUsers = res.data || ([] as CompanyUser[]);
      this.localStorageService.set('companyUserList', this.companyUsers);
      this.companySearchBox = true;
    });
  }

  openAccessBox(item: Member) {
    if (this.warehouse.contractor_company_user_id == item.company_user_id) {
      this.notificationsService.initiate({ title: 'Not allowed', type: toastTypes.error });
      return
    }
    const index = this.companyUsers.findIndex(i => i.id == item.company_user_id);
    this.companyUser = this.companyUsers[index] || {} as CompanyUser;
    if (this.authService.session.profile.id == this.companyUser.user_id) {
      this.notificationsService.initiate({ title: 'You can not change your role', type: toastTypes.error });
      return
    }
    this.accessBox = true;
    return this.member = item;
  }

  addEditWarehouseDetails() {  
    if (!this.warehouse.address) return this.notificationsService.initiate({ title: 'Adress can not be empty!', type: toastTypes.error });
    else if (!this.warehouse.city) return this.notificationsService.initiate({ title: 'City can not be empty!', type: toastTypes.error });
    let warehouseObserver;
    if (this.warehouse.id) {
      warehouseObserver = this.adminService.editWarehouse(this.warehouse);
    } else {
      warehouseObserver = this.adminService.addWarehouse(this.warehouse)
    }
    warehouseObserver.subscribe({
      next: (res) => {
        this.notificationsService.initiate({ title: `Warehouse ${this.warehouse.id ? 'updated' : 'added'} !`, type: toastTypes.success });
        this.activeModal.close(res.id);
      },
      error: (err) => {
        this.notificationsService.initiate({ title: err.error.message, type: toastTypes.error });
      }
    })
  }

  replaceImg(event, model, key) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      const fileName = files[0].name;
      const fileType = files[0].type;
      const extn = fileName.substring(fileName.lastIndexOf('.'));
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        const target = fileLoadedEvent.target as { result?: string };
        const modalRef = this.modalService.open(ImgEditModalComponent, { backdrop: 'static' });
        modalRef.componentInstance.data = target.result;
        modalRef.result.then(data => {
          const newfile = new File([data.blob], fileName, { type: fileType, lastModified: Date.now() });
          const fileUploadOptions = {
            files: [newfile],
            contentType: fileType,
            extension: extn,
            folder: 'profile'
          } as FileUploadOptions;
          this.utilService.s3attachAnyFIle(fileUploadOptions).subscribe(res => {
            this.loading = false;
            this.warehouse.customer_profile_image = res.url;
          }, err => {
            this.loading = false;
          });
        }).catch(err => {
          location.reload();
        });
      };
      fileReader.readAsDataURL(files[0]);
    }
  }
  selectFileInput() {
    document.getElementById('picInput').click();
  }

  setCompanyUser(companyUser?: CompanyUser) {
    if (companyUser) {
      this.companyUser = companyUser;
      for (const mem of this.members) {
        if (mem.company_user_id == companyUser.id) {
          this.companySearchBox = false;
          this.member = mem || {} as Member;
          this.openAccessBox(mem)
          return
        }
      }

      if (companyUser.mobile == 0) {
        this.notificationsService.initiate({ title: 'Mobile not updated of selected user', type: toastTypes.error });
        return;
      }

      if (companyUser.company_role_id != '') {
        this.member = {};

        this.member.company_user_id = companyUser.id;
        this.member.project_id = this.warehouse.id;
        this.addTeamMember(this.warehouse.id, companyUser.id);
        this.accessBox = true;
        return
      } else {
        this.accessBox = true;
      }
    } else {
      this.companyUser = {}
    }
  }



  getCompanyRoles() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.authService.session.company.id);
    this.adminService.listCompanyRole(qp).subscribe(
      {
        next: (res) => {
          this.companyRoles = res.data || [] as CompanyRole[];
        },
        error: () => {
          //
        }
      }
    )
  }

  addTeamMember(Warehouse_id: string, company_user_id: string) {
    this.member = {};
    this.member.company_user_id = company_user_id;
    this.member.project_id = Warehouse_id;
    this.adminService.saveNewUserRole(this.member).subscribe(
      (res) => {
        const index = this.members.findIndex(i => i.id == res.id);
        if (index == -1) {
          this.members.push(res);
        } else {
          this.members[index] = res || {} as Member;
        }
        this.utilService.memberCount.next(this.members.length);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  patchCompanyUser(company_user_id: string, role_id: string) {
    this.companyUser.id = company_user_id;
    this.companyUser.company_role_id = role_id;
    // this.companyUser.type = CompanyPartyType.Employee;
    this.adminService.editCompanyUser(this.companyUser).subscribe({
      next: () => {
        this.addTeamMember(this.warehouse.id, company_user_id)
      },
      error: () => {
        //
      }
    })
  }
}
