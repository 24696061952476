import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-premium-tag',
  templateUrl: './premium-tag.component.html',
  styleUrls: ['./premium-tag.component.css'],
  standalone: true,
  imports: [CommonModule, NgbModule],
})
export class PremiumTagComponent {
  @Input() small:boolean;
  public expiry_date = new Date();
  private authService = inject(AuthService);
  ngOnInit(): void {
    this.expiry_date = this.authService.session.company.subscription_end_date;
  }
}
