import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminService } from "src/app/core/services/admin.service";

interface ComponentData {
  prefix: string;
  sequence: string;
  id: string;
}

@Component({
  selector: "app-subcon-prefix-modal",
  templateUrl: "./subcon-prefix-modal.component.html",
  styleUrls: ["./subcon-prefix-modal.component.css"],
})
export class SubconPrefixModalComponent {
  @Input() componentData = {} as ComponentData;

  constructor(
    private adminService: AdminService,
    private ngbActiveModal: NgbActiveModal
  ) {}

  save() {
    if (this.componentData.id) {
      this.editSubConWorkOrderQutaionNumber();
    } else {
      this.ngbActiveModal.close({
        prefix: this.componentData.prefix,
        sequence: this.componentData.sequence,
      });
    }
  }

  editSubConWorkOrderQutaionNumber() {
    this.adminService
      .editSubConWorkOrderQutaionNumber(this.componentData)
      .subscribe({
        next: (res) => {
          this.ngbActiveModal.close({
            prefix: this.componentData.prefix,
            sequence: this.componentData.sequence,
          });
        },
        error: (err) => {
          this.ngbActiveModal.dismiss();
          console.error(err);
        },
      });
  }

  dismissModal() {
    this.ngbActiveModal.dismiss();
  }
}
