import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';

// <span> {{ any_number | dynamicNumber: {fieldType: IPOP_FIELD_TYPE.Price, currency: 1, absOff:1, decimalOff:1} }} </span>

// currency: if you need currency sysmbol default -> 0 (off) / {currency: 1} (on)
// absOff: if you need negative value default -> 0 (abs on) / {absOff: 1} (abs off)
// decimalOff: if you need decimal point default -> 0 (off) / {decimalOff: 1} (on)
// fieldType: to use decimal according to field type. (IPOP_FIELD_TYPE.Quantity, IPOP_FIELD_TYPE.UnitRate, IPOP_FIELD_TYPE.Price, IPOP_FIELD_TYPE.OtherStatic)
// you dont need to send default value. Send parameter when you want some thing rather than default

interface Option {
  currency?: 0 | 1;
  absOff?: 0 | 1;
  digit?: number;
  decimalOff?: 0 | 1;
  fieldType?: IPOP_FIELD_TYPE;
}

@Pipe({
  name: 'dynamicNumber'
})
export class DynamicNumberPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: string | number, options?: Option): string {
    if (typeof value !== 'number' && typeof value !== 'string') {
      return '';
    }

    let amount = Number(value);
    if (isNaN(amount)) {
      return '';
    }

    if (!options?.absOff) {
      amount = Math.abs(amount);
    }
    
    let digits;
    if (options?.fieldType) {
      switch(options?.fieldType){
        case IPOP_FIELD_TYPE.Quantity:
          digits = this.authService.session.company.quantity_float_limit;
          break;
        case IPOP_FIELD_TYPE.UnitRate:
          digits = this.authService.session?.country?.decimal_digit;
          break;
        case IPOP_FIELD_TYPE.Price:
          digits = this.authService.session.company.currency_float_limit;
          break;
        case IPOP_FIELD_TYPE.OtherStatic:
          digits = 2;
          break;
      }
    }else if (options?.digit) {
      digits = options.digit;
    } else {
      digits = this.authService.session?.country?.decimal_digit;
    }

    const fractionDigits = options?.decimalOff ? 0 : digits;
    const currencySymbol = this.authService.session?.company?.currency || '₹';
    const locale = currencySymbol === '₹' ? 'en-IN' : undefined;

    if (options?.currency === 1) {
      return `${currencySymbol} ${new Intl.NumberFormat(locale, {
        maximumFractionDigits: fractionDigits > 0 ? fractionDigits : 0
      }).format(amount)}`;
    }
    return fractionDigits > 0
    ? amount.toLocaleString(locale, { maximumFractionDigits: fractionDigits })
    : amount.toString();

  }
}
