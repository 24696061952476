import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { Company, CompanyUser } from 'src/app/interfaces/interfaces';
import { HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
interface Report {
  report_title?: string,
  report_sub_title?: string,
  csv?: boolean,
  pdf?: boolean,
  apiFunction?: string,
  defaultDateRange?: 'all' | 'today' | 'lastWeek' | 'lastMonth' | 'thisWeek' | 'thisMonth',
  not_to_show?: any[],
  has_date?:boolean,
  party_company_user_id?: string,
  purchase_order_id?:string
}
interface Parent {
  project?: string,
  reportType?: Report,
}
@Component({
  selector: 'app-report-company-modal',
  templateUrl: './report-company-modal.component.html',
  styleUrls: ['./report-company-modal.component.css']
})
export class ReportCompanyModalComponent implements OnInit {
  @Input() parent = {} as Parent;
  public queryParams = new HttpParams();
  public dateSelector = [
    { key: "all", value: "All" },
    { key: "today", value: "Today" },
    { key: "lastWeek", value: "Last Week (Mon - Sun)" },
    { key: "lastMonth", value: "Last Month" },
    { key: "thisWeek", value: "This Week (Mon - Today)" },
    { key: "thisMonth", value: "This Month" },
    { key: "month", value: "Month" },
    { key: "custom", value: "Custom" },
  ];
  dateRange = "";
  public startDate?: Date;
  public endDate?: Date;
  public report_duration_title?: string = "All Time Date";
  public datePicker: Object = {
    fromDate: new Date(),
    toDate: new Date(),
    show: false
  }

  public company = {} as Company;
  public companyUsers = [] as CompanyUser[];
  public companyUser = {} as CompanyUser;



  model: NgbDateStruct;
  date: {year: number, month: number};
  constructor(
    public ngbActiveModal: NgbActiveModal,
    public localStorageService: LocalStorageService,
    public authService: AuthService,
    private adminService: AdminService,
    private notificationService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.company = this.authService.session.company;
    this.dateRangeSet(this.parent.reportType.defaultDateRange);

    const monthControl = document.querySelector('input[type="month"]') as HTMLInputElement;
    if (monthControl) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits

      monthControl.value = `${currentYear}-${currentMonth}`;
    }

    let qp = new HttpParams();
      qp = qp.set('company_id', this.company.id);
      this.adminService.getCompnayUserList(qp).subscribe((res) => {
        this.companyUsers = res.data || ([] as CompanyUser[]);
        this.localStorageService.set('companyUsers', this.companyUsers);
      });
  }

  setCompanyUser(companyUser?: CompanyUser) {
    if (companyUser) {
      this.companyUser = companyUser;
    }
  }

  showMonth = false;
  Date_not_selected = 'Date not selected'
  dateRangeSet(e) {
    if(e=="month"){
      this.showMonth = true
    }
    this.dateRange = e;

    const cDate = new Date();
    if (e == "custom") {
      this.datePicker['show'] = true;
    }
    if (e == "all") {
      this.report_duration_title = "All Time";
      this.startDate = null;
      this.endDate = null;
    }
    this.endDate = null;
    if (e == "today") {
      this.startDate = cDate;
      this.endDate = cDate;
      this.report_duration_title = "Today";
    }
    if (e == "lastWeek") {
      this.report_duration_title = "Last Week (Mon-Sun)";
      const prevMonday = new Date();
      prevMonday.setDate(
        prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
      );
      const d = new Date().getDate() - prevMonday.getDate();
      d < 7 ? prevMonday.setDate(prevMonday.getDate() - 7) : prevMonday;
      this.startDate = prevMonday;
      this.endDate = new Date(prevMonday);
      this.endDate.setDate(prevMonday.getDate() + 6);
    }

    if (e == "lastMonth") {
      this.report_duration_title = "Last Month";
      const date = new Date();
      this.startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      this.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
    }

    if (e == "thisWeek") {
      this.report_duration_title = "This Week";
      const main = new Date();
      const d = main.getDay();
      const mon = new Date(main);
      // this.startDate = new Date(mon.setDate(mon.getDate() - d + 1));
      this.startDate = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
      this.endDate = new Date();
    }

    if (e == "thisMonth") {
      this.report_duration_title = "This Month";
      const date = new Date();
      this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.endDate = new Date();
    }

    if (this.startDate) {
      const date = new Date(this.startDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (this.endDate) {
      const date = new Date(this.endDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }

  setMonth(e){
    console.log('date', e.target.value)
    const _startDate = new Date(`2024, ${e}, 1`);
    const _endDate = new Date(_startDate);
    _endDate.setMonth(_endDate.getMonth()+1, 0)


    this.report_duration_title = e;
    _startDate.setHours(0);
    _startDate.setMinutes(0);
    _startDate.setSeconds(0, 0);
    _endDate.setHours(23);
    _endDate.setMinutes(59);
    _endDate.setSeconds(59, 999);  
    this.queryParams = this.queryParams.set("start_date", _startDate.toISOString())
    this.queryParams = this.queryParams.set("end_date", _endDate.toISOString())
    this.Date_not_selected = 'Month selected'
  }

  handleMonthInput(e: Event): void {
    const target = e.target as HTMLInputElement;
    const selectedMonth = target.value; // e.g., "2024-06");
  
    if (!selectedMonth) {
      console.error('Invalid month selected');
      return;
    }
  
    // Extract year and month
    const [year, month] = selectedMonth.split('-').map(Number);
    const _startDate = new Date(year, month - 1, 1); // Month is zero-indexed
    const _endDate = new Date(year, month, 0); // Day 0 gets last day of the previous month
  
    // Set start and end time for startDate
    _startDate.setHours(0, 0, 0, 0);
    _endDate.setHours(23, 59, 59, 999);
  
    this.report_duration_title = selectedMonth;
    this.queryParams = this.queryParams.set("start_date", _startDate.toISOString());
    this.queryParams = this.queryParams.set("end_date", _endDate.toISOString());
    this.Date_not_selected = 'Month selected';
  }
  

  downloadReport(type?:'pdf'|'csv') {
    if (this.startDate) {
      this.queryParams = this.queryParams.set("start_date", this.startDate.toISOString())
    }
    if (this.endDate) {
      this.queryParams = this.queryParams.set("end_date", this.endDate.toISOString())
    }
    if(this.companyUser.id){
      this.queryParams = this.queryParams.set("party_company_user_id", this.companyUser.id)
    }
    this.queryParams = this.queryParams.set("company_id", this.company.id);
    console.log("this.parent.reportType.apiFunction", this.parent.reportType.apiFunction)
    switch (this.parent.reportType.apiFunction) {
      case "companyPatryTransactionReportPdf":
        this.queryParams = this.queryParams.set('party_company_user_id', this.parent.reportType.party_company_user_id);
        this.adminService.companyPatryTransactionReportPdf(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
      case "companyPaymentsReportCsv":
        this.adminService.companyPaymentsReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
      case "itemwiseMaterialPurchaseReportCsv":
        this.adminService.itemwiseMaterialPurchaseReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;

      case "purchaseExpenseReportCsv":
        this.adminService.purchaseExpenseReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;

      case "companyProjectSummaryReportCsv":
        this.adminService.companyProjectSummaryReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;

      case "companyWorkforceSummaryReportCsv":
        this.adminService.companyWorkforceSummaryReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
      case "companySalesOrderInvoiceReportCsv":
        this.adminService.companySalesOrderInvoiceReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
      case "gstr1ReportCsv":
        this.adminService.gstr1ReportCsv(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
      case "purchaseOrderReport":
        if(type == "csv"){
          this.queryParams = this.queryParams.set('purchase_order_id', this.parent.reportType.purchase_order_id);
          this.adminService.purchaseOrderReportCsv(this.queryParams).subscribe(res => {
            window.open(res.url, "_blank");
          })
        }else{
          this.queryParams = this.queryParams.set('purchase_order_id', this.parent.reportType.purchase_order_id);
          this.adminService.generatePODetailPdf(this.queryParams).subscribe(res => {
            window.open(res.url, "_blank");
          })
        }
        
        break;  
      case "monthlySalary":
        if (!this.queryParams.has('start_date') || !this.queryParams.has('end_date')) {
          this.notificationService.initiate({title:'Please select month!', type:toastTypes.error});
          break;
        }
        this.adminService.monthlySalaryPdf(this.queryParams).subscribe(res => {
          window.open(res.url, "_blank");
        })
        break;
    }
  }
  setDate(e) {
    this.datePicker['show'] = false;
    this.report_duration_title = "Custom";
    if (e.fromDate) {
      const date = new Date(e.fromDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (e.toDate) {
      const date = new Date(e.toDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }
}
