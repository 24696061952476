import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';

interface Source {
  id: string;
  pre_tax:boolean
}

@Component({
  selector: 'app-new-item-deduction',
  templateUrl: './new-item-deduction.component.html',
  styleUrls: ['./new-item-deduction.component.css']
})
export class NewItemDeductionComponent implements OnInit {
  @Input() source = {} as Source;
  public deductionItemName: string;
  private companyId: string;
  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private notificationService: NotificationsService
  ) { 
   this.companyId = this.authService.session.company.id;
  }
  ngOnInit(): void {
    if(this.source.id){
      this.getDetailDeductionItem();
    }
  }

  saveDeductionItem(){
    if(!this.deductionItemName){
      this.notificationService.initiate({title:'Please enter item name',type: toastTypes.error});
      return;
    }
    if(this.source.id){
      this.editDedtionItem();
    }else{
      this.addDeductionItem();
    }
  }

  addDeductionItem(){
    this.adminService.createDeduction({name: this.deductionItemName, company_id: this.companyId, pre_tax:this.source.pre_tax?1:0}).subscribe({
      next: (res) => {
        this.ngbActiveOffcanvas.close(res);
      },
      error: (err) => {
        this.notificationService.initiate({title:'Something went wrong',type: toastTypes.error});
        this.ngbActiveOffcanvas.dismiss();
      }
    })
  }

  editDedtionItem(){
    this.adminService.editDeductionItem({name: this.deductionItemName, id: this.source.id, pre_tax:this.source.pre_tax?1:0}).subscribe({
      next: (res) => {
        this.ngbActiveOffcanvas.close(res);
      },
      error: (err) => {
        this.notificationService.initiate({title:'Something went wrong',type: toastTypes.error});
        this.ngbActiveOffcanvas.dismiss();
      }
    })
  }



  getDetailDeductionItem(){
    this.adminService.getDetailDeductionItem(this.source.id).subscribe({
      next: (res) => {
        this.deductionItemName = res.name;
      },
      error: (err) => {
        this.notificationService.initiate({title:'Something went wrong',type: toastTypes.error});
      }
    })
  }

}
