<div class="d-flex flex-column gap-1">
    <div class="modal-container" style=" max-height: 60vh !important;">
      <div class="d-flex flex-column pt-2">
        <app-party-input [parentComponent]="{pageFrom:'project-setting'}"
          (onCompanyUserSelect)="setCompanyUser($event)"></app-party-input>
      </div>

      <div class="vstack" style="overflow-x:hidden; overflow-y: auto; height: 30vh;" *ngIf="project?.id">
        <div>
          <span class="member-count">
            <!-- 2 Members, 1 Pending -->
          </span>
        </div>
        <div *ngFor="let item of members; let i = index;">
          <ng-container>
            <div class="d-flex flex-column" style="position: relative !important;">
              <div class="d-flex my-1 pointer member-card p-2" [ngClass]="member.id===item.id?'member-card-active':''"
                (click)="openAccessBox(item); companySearchBox=false" id="dropdownManual">
                <div class="align-self-center">
                  <div [ngClass]="item.role=='manager'?'avatar-manager':'avatar-admin'" class="avatar"
                    style="font-size:16px !important;">
                    {{item.monkey_patch_company_user.name | shortName}}
                  </div>
                </div>
                <div class="ms-4">
                  <span class="member-name">
                    {{item.monkey_patch_company_user.name}}
                    <span *ngIf="project.contractor_company_user_id==item.company_user_id" class="fs-8">
                      (Super Admin)
                    </span>
                  </span>
                  <br>
                  <span class="member-mobile">
                    {{item.monkey_patch_company_user.mobile}}
                  </span>
                </div>
                <div class="ms-auto">
                  <span class="member-mobile">
                    {{item.monkey_patch_company_user.monkey_patch_company_role.name}}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>


    <div *ngIf="accessBox" class="access-box-close" (click)="accessBox=false"></div>
    <div class="access-box p-1" *ngIf="accessBox" [ngClass]="companyUser.role=='admin'? 'top-38-percent':''">

      <div *ngFor="let role of companyRoles">
        <div class="d-flex access-box-items pointer py-2" (click)="setMemberAccess(role); accessBox=false">
          <div class="d-flex justify-content-center align-items-center" style="width: 50px">
            <div *ngIf="companyUser.company_role_id==role.id" class="active-tick"></div>
          </div>
          <div class="d-flex flex-column ">
            <span style="font-weight: 500;" class="access-title mb-1">{{role.name}}</span>
            <span class="btn-desc" *ngIf="role.id=='id1'">Admin has complete
              access.</span>
            <span class="btn-desc" *ngIf="role.id=='id2'"> Manager has
              limited access.</span>
          </div>
        </div>
      </div>
      <div>
        <div *ngIf="project?.creator_company_user_id!=companyUser.id || project?.id" class="d-flex access-box-items pointer py-2"
          (click)="deleteTeamMember()">
          <div style="width: 50px;" class="p-2">
          </div>
          <div class="d-flex flex-column">
            <span style="font-weight: 500;" class="remove mb-1">
              Remove
            </span>
  
            <span class="btn-desc">
              Remove from {{project?.id ? 'project' : 'member'}}.
            </span>
          </div>
        </div>
      </div>
    </div>
</div>
