/**
 * Created by tarun on 6/9/17.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';

import { Company, Project, Token } from '../interfaces/interfaces';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observer } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationsService, toastTypes } from '../core/services/notifications.service';
import { AuthService } from '../core/services/auth.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  public JSONMAP = new Map();
  public company = {} as Company;
  public project = {} as Project;
  private cacheableUrls = [
    'https://testapi.onsiteteams.in/apis/v3/list/platforminfo',
  ];
  private superAdminUrls = [
    'admin/add/customfield', 
    'admin/upload/material/bycsv',
    'admin/upload/boq/bycsv'
  ];
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    public notificationService : NotificationsService,
    public authService : AuthService,
  ) {
    // const This = this;
    setInterval(() => {
      this.JSONMAP.clear();
    }, 10 * 60000);

    this.project = localStorageService.get('project');
    this.company = localStorageService.get('company');
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSuperAdminRequest = false;
    for (let i = 0; i < this.superAdminUrls.length; i++) {
      const urlAtIndex = this.superAdminUrls[i];
      if (req.url === urlAtIndex || req.url.includes(urlAtIndex)) {
        isSuperAdminRequest = true;
        break;
      }
    }
    //const token: Token = this.localStorageService.get('token') as Token;
    const token: Token = !isSuperAdminRequest ? (this.localStorageService.get('token') as Token) : this.authService.getPrimaryToken();
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token.token) });
    }

    if (this.project?.id) {
      req = req.clone({ headers: req.headers.set('project_id', this.project.id) });
    }

    if (this.company) {
      req = req.clone({ headers: req.headers.set('project_company_id', this.company.id) });
    }

    /*if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }*/
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({ headers: req.headers.set('version-code', '171') });

    //req.headers.delete()
    // cache handling
    if (req.method === 'GET' && this.cacheableUrls.findIndex(elem => {
      return req.url === elem || req.url.startsWith(elem);
    }) !== -1) {
      // cacheable url
      if (this.JSONMAP.get(req.urlWithParams)) {
        // found in cache
        return new Observable<any>((observer: Observer<any>) => {
          observer.next(this.JSONMAP.get(req.urlWithParams));
          observer.complete();
        });
      } else {
        // not found in cache
        return next.handle(req)
          .pipe(
            catchError(err => {
              if(err.status > 399 ){
                this.showErrorToast(err)
              }
              // if (err.status === 401) {
              //   // this.router.navigate(['/c/c_id/d/home']);
              //   this.authService.logout();
              // }
              // if(err.status === 404){
              //    this.authService.setServerDownStatus(true);
              // }
              return throwError(err);
            })
          )
          .pipe(tap((data) => {
            this.JSONMAP.set(req.urlWithParams, data);
          })
          );
      }
    } else {
      // not a cacheable url
      return next.handle(req)
        .pipe(
          catchError(err => {
            if(err.status > 399 ){
              this.showErrorToast(err)
            }
            // if (err.status === 401) {
            //   this.router.navigate(['/company/dashboard/home']);
            // }

            return throwError(err);
          })
        );
    }
  }

  showErrorToast(err) {
    err instanceof HttpHandler
    this.notificationService.initiate({
      title:err.error.message,
      type:toastTypes.error
    })
  }
}
