<div class="d-flex flex-column gap-3 h-100" 
[ngClass]="{'align-items-end':title.toLocaleLowerCase().includes('to'), 
'align-items-start':title.toLocaleLowerCase().includes('from')}">
    <span class="f-14 disabled-font" >{{ title }}</span>
    <div class="d-flex flex-column gap-1 address-section"
    [ngClass]="{'text-end':title.toLocaleLowerCase().includes('to'), 
    'text-start':title.toLocaleLowerCase().includes('from')}">
        <span class="f-14">{{ selectedAddress?.address_title }}</span>
        <span *ngIf="selectedAddress?.address_line_1" class="f-12">{{selectedAddress?.address_line_1}}</span>
        <span class="f-12">
            {{selectedAddress?.city ? selectedAddress?.city+', ' : ''}} 
            {{selectedAddress?.state ? selectedAddress?.state+', ' : ''}} 
            {{selectedAddress?.postal_code}} 
        </span>
        <span *ngIf="selectedAddress?.monkey_patch_country_config?.country_name"
        class="f-12">{{selectedAddress?.monkey_patch_country_config?.country_name}}</span>
        <span *ngIf="selectedAddress?.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ selectedAddress?.address_gst }}</span>
    </div>
    <div class="custom-div w-100">
      <div ngbDropdown class="form-control" style="border: none; background: transparent;">
        <div ngbDropdownToggle class="d-flex justify-content-start align-items-center gap-2">
          <div class="fs-8 align-self-center">{{ selectedAddress?.address_title || 'Select Address' }}</div>
          <div><ng-icon name="featherChevronDown"></ng-icon></div>
        </div>

        <div ngbDropdownMenu class="custom-menu-address">
            <div
            class="d-flex align-items-center justify-content-between gap-3 p-3 border-bottom"
            *ngIf="listTypes?.length > 0"
            >
                <ng-container *ngFor="let type of listTypes">
                    <div *ngIf="type !== 'person'" class="d-flex justify-content-start align-items-center gap-2 format-card f-12" (click)="handleTypeSelect(type)">
                        <div class="circular_check" [ngClass]="selectedListType === type ? 'circular_check_active' : ''"></div>
                        {{ type | uppercase }}
                    </div>
                </ng-container>
            </div>
            <div ngbDropdownItem class="d-flex p-3 pointer dropdown-list"
                *ngFor="let adress of addressListObj[selectedListType]?.addressList">
                <div (click)="onAddressSelect.emit(adress)" class="d-flex flex-column justify-content-center align-items-start" style="width: 80%;">
                    <div class="d-flex flex-column align-items-start address-section">
                        <span class="f-14">{{ adress?.address_title }}</span>
                        <span *ngIf="adress?.address_line_1" class="f-12">{{adress?.address_line_1}}</span>
                        <span *ngIf="adress?.address_line_2" class="f-12">{{adress?.address_line_2}}</span>
                        <span class="f-12">
                            {{adress?.city ? adress?.city+', ' : ''}} 
                            {{adress?.state ? adress?.state+', ' : ''}} 
                            {{adress?.postal_code}} 
                        </span>
                        <span *ngIf="adress?.monkey_patch_country_config?.country_name"
                        class="f-12">{{adress?.monkey_patch_country_config?.country_name}}</span>
                        <span *ngIf="adress?.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ adress?.address_gst }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="addressListObj[selectedListType]?.addressList.length <= 0"
            class="d-flex justify-content-center align-items-center p-3">
                <span class="f-12 disabled-font">No Addresses found</span>
            </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedAddress?.id" style="color: #D30D52; cursor: pointer;"
    class="d-flex align-items-center gap-2" (click)="removeAddress()">
        <div style="padding-bottom: 3px;"><ng-icon name="featherTrash"></ng-icon></div>
        <span class="f-12">Remove Address</span>
    </div>
    <div *ngIf="showCheckbox" class="d-flex align-items-center justify-content-start gap-2">
        <input
        #checkbox
        type="checkbox"
        [checked]="toAndFromSame"
        (change)="handleCheckboxChange($event)"
        />
        <span class="f-14 disabled-font">{{ checkboxLabel }}</span>
    </div>
</div>
