import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { ShipToBillToAddress } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-view-ship-bill',
  templateUrl: './view-ship-bill.component.html',
  styleUrls: ['./view-ship-bill.component.css']
})
export class ViewShipBillComponent {
  @Input() shipToBillToAddress: any;
  constructor(
    public authService: AuthService
  ) { }
}
