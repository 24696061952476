import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MaterialPurchase, PurchaseOrder, ShipToBillToAddress } from 'src/app/interfaces/interfaces';
import { ShipToBillToComponent } from '../ship-to-bill-to.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
interface Parent {
  company_id?: string;
  project_id?: string;
  party_company_user_id?: string;
  fromPage?: string;
  shipToBillToAddress?: ShipToBillToAddress;
}
@Component({
  selector: 'app-input-ship-bill',
  templateUrl: './input-ship-bill.component.html',
  styleUrls: ['./input-ship-bill.component.css']
})
export class InputShipBillComponent {
  @Input() parent!: Parent;
  @Output() onShipToBillToSelection = new EventEmitter<ShipToBillToAddress>();
  constructor(
    private ngbModal: NgbModal,
    public notificationService: NotificationsService,
  ) { }
  
  openShipToBillTo() {
    if (!this.parent.party_company_user_id) {
      return this.notificationService.initiate({ title: 'Please select party', type: toastTypes.error });
    }
    const modalRef = this.ngbModal.open(ShipToBillToComponent,{
      size: 'xl',
      centered: true,
      windowClass: 'center-modal',
      backdrop: 'static'
    });

    modalRef.componentInstance.parent = JSON.parse(JSON.stringify(this.parent));

    modalRef.result.then((result) => {
      this.onShipToBillToSelection.emit(result);
    },(reject) => {
      //
    });
  }
}
