
import { Component, OnInit, Input } from '@angular/core';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyUser, Project, PurchaseOrder } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
@Component({
  selector: 'app-create-purchase-order-modal',
  templateUrl: './create-purchase-order-modal.component.html',
  styleUrls: ['./create-purchase-order-modal.component.css']
})
export class CreatePurchaseOrderModalComponent implements OnInit {
  @Input() data: any;
  public purchaseOrder = {} as PurchaseOrder;
  public model: NgbDateStruct;
  public saveBtnDisabled = false;
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public authService: AuthService,
    private adminService: AdminService,
    public utilService: UtilService,
    public notificationService: NotificationsService,
    private calendar: NgbCalendar,
    private validatorService: ValidatorService
  ) {}

  ngOnInit(): void {
    this.model = this.calendar.getToday();
    this.purchaseOrder = this.data.purchaseOrder || {};
    this.utilService.sendPartyId(this.purchaseOrder.party_company_user_id);
    this.utilService.sendProjectId(this.purchaseOrder.project_id);
  }

  setCompanyUser(companyUser?: CompanyUser) {
    if(companyUser){
      this.purchaseOrder.party_company_user_id = companyUser.id;
      this.purchaseOrder.monkey_patch_party_company_user = companyUser;
    }
  }
  setCompanyProject(project?: Project) {
    if(project){
      this.purchaseOrder.project_id = project.id;
      this.purchaseOrder.monkey_patch_project = project;
    }
  }

  save(){
   if(!this.purchaseOrder.party_company_user_id) return this.notificationService.initiate({ title: "Please select party!", type: toastTypes.error });
   if(!this.purchaseOrder.project_id) return this.notificationService.initiate({ title: "Please select project!", type: toastTypes.error });
   this.purchaseOrder.creator_company_id = this.authService.session.company.id;
   this.purchaseOrder.purchase_ordered_date = this.validatorService.dateToUtc(
        this.model.day,
        this.model.month,
        this.model.year
      );
   this.saveBtnDisabled = true;   
   if(this.purchaseOrder?.id){
    this.edit();
   }else{
    this.add();
   }
  }

  edit(){
   this.adminService.editPurchaseOrder(this.purchaseOrder).subscribe({
    next: res =>{
      this.activeModal.close(this.purchaseOrder);
    },
    error: err=>{
      //
    },
    complete: () => {
      this.saveBtnDisabled = false;
    }
   })
  }

  add(){
   this.adminService.addPurchaseOrder(this.purchaseOrder).subscribe({
    next: res =>{
      this.activeModal.close(res);
    },
    error: err=>{
      //
    },
    complete: () => {
      this.saveBtnDisabled = false;
    }
   })
  }

}
