<div style="padding: 30px 40px 0px 40px;">
    <div class="d-flex flex-column">
        <div class="d-flex" style="margin-bottom:20px;">
            <div class="f-18" style="font-weight: 500;">Warehouse Setting</div>
            <div class="ms-auto">
                <svg class="pointer" (click)="activeModal.dismiss()" width="24" height="24" viewBox="0 0 24 24"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
        </div>

        <div class="tabs_container">
            <div class="tabs_container_content p-0" style="margin-left: 0 !important;">
                <span class="cursor single_tab text-muted custom-text"
                    [ngClass]="warehouseTab=='warehouseDetails'?'active':''" (click)="warehouseTab='warehouseDetails'">
                    Warehouse Details
                    <div class="active_tab_line"></div>
                </span>

                <span class="cursor single_tab text-muted custom-text" *ngIf="warehouse.id" [ngClass]="warehouseTab=='member'?'active':''"
                    (click)="warehouseTab='member'">
                    Members
                    <div class="active_tab_line"></div>
                </span>

            </div>
        </div>

    </div>
</div>
<div style="border-top: 1px solid #E5E7F3; margin-bottom: 36px;"></div>
<div style="padding: 0px 40px 30px 40px;">
    <div class="d-flex flex-column gap-1">
        <ng-container *ngIf="warehouseTab === 'warehouseDetails'">
            <div class="row gx-1">
                <div class="col-3 col-lg-2 d-flex justify-content-start align-items-start p-0">
                    <div class="project-photo">
                        <div class="project-name">{{'W H' | shortName}}</div>
                    </div>
                </div>
                <div class="col-9 col-lg-10 p-0">
                    <div class="d-flex gap-3 align-items-center">
                        <div class="custom-input mb-3">
                            <label class="custom-text">Address</label>
                            <input class="custom-text" type="text" autocomplete="off"
                            [(ngModel)]="warehouse.address" />
                        </div>
                        <div class="custom-input mb-3">
                            <label class="custom-text">City</label>
                            <input class="custom-text" type="text" autocomplete="off" [(ngModel)]="warehouse.city" />
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="warehouseTab ==='member'">
            <app-add-member [project]="warehouse"></app-add-member>
        </ng-container>


        <div class="mt-auto d-flex justify-content-between" style="margin-top: 40px !important;"
            *ngIf="warehouseTab=='warehouseDetails'">

            <div class="ms-auto align-self-center">
                <span class="project-btn save" (click)="addEditWarehouseDetails()">{{warehouse?.id ? 'Save' : 'Activate'}}</span>
            </div>

        </div>
    </div>
</div>