import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { Profile, Project, Task, Company } from 'src/app/interfaces/interfaces';
import { AdminService } from 'src/app/core/services/admin.service';
import { CompanyService } from 'src/app/company/services/company.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectComponent } from 'src/app/admin/components/modals/project/project.component';
import { UtilService } from '../../services/util.service';
import { SettingCompanyComponent } from '../modal/setting-company/setting-company.component';
import { environment } from 'src/environments/environment';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { SettingWarehouseComponent } from '../modal/setting-warehouse/setting-warehouse.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  @Input() config = { active: 'dashboard' } as any;
  public valueStack = { page: { next_url: '' } };
  public taskValueStack = { page: { next_url: '' } };
  public projects = [] as Project[];
  public pinnedProjects = [] as Project[];
  public companies = [] as Company[];
  public selectedCompany = {} as Company;
  public tasks = [] as Task[];
  public busy = false;
  public loading = false;
  public primaryProfile = {} as Profile;
  private paginate = 1;
  private limit = 10;
  // public showMenu = false;
  public app_version = '';
  public subscriptionLeft: number;
  public i_am_admin = true;
  private company = {} as Company;
  public show_warehouse = false;
  wordAfterD:string;
  public referralUrl = 'https://zfrmz.in/CgGKl1EzOwYbBuBalbMS';
  constructor(
    public authService: AuthService,
    public adminService: AdminService,
    public companyService: CompanyService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    public utilService: UtilService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.app_version = environment.appVersion || '';
    this.selectedCompany = this.authService.session.company || {} as Company;
    // this.companyService.observeCompanies$.subscribe(res => this.companies = res || [] as Company[]);
    this.getWorkableCompanyList();
    this.company = this.authService.session.company;
    this.authService.companyListObserver.subscribe(res=>{
      if(res){
        this.companies = this.authService.session.companies || [] as Company[];
      }
    })

    const url = this.router.url;
    const regex = /\/d\/([^/]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      this.wordAfterD = match[1];
    }else{
      if (url.includes('/w/')) this.wordAfterD = 'warehouse';
      else this.wordAfterD = null;
    }

    authService.project$.subscribe(res=>{
      this.getPinnedProjects();
    })
  }

  getPinnedProjects(){
    this.pinnedProjects = this.localStorageService.get('pinnedProjects') || [] as Project[]
  }

  getWorkableCompanyList(){
    this.authService.getWorkableCompanyList().subscribe({
      next:(res)=>{
            this.companies = res?.Companies || [] as Company[];
            this.authService.session.companies = this.companies || [] as Company[];
      },
      error:(error)=>{
        console.log('error',error);
      }
    })
  }

  getCompanyRole(company: Company) {
    var name = "";
    var colorClass = "";
    var isOwner = company.creator == this.authService.session.profile.id;
    if (isOwner) {
      name = "Super Admin";
      colorClass = "super-admin-role";
    } else {
      name = company.monkey_patch_my_company_user?.monkey_patch_company_role?.name ?? "";
      colorClass = company.monkey_patch_my_company_user?.monkey_patch_company_role?.id == "id1" ? "admin-role" : "other-role";
    }
    return {name: name, colorClass: colorClass};
  }

  ngOnInit() {
    this.config.active = this.config.active || 'dashboard';

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.url;
        const regex = /\/d\/([^/]+)/;
        const match = url.match(regex);
        if (match && match[1]) {
          this.wordAfterD = match[1];
        } else {
          if (url.includes('/w/')) this.wordAfterD = 'warehouse';
          else this.wordAfterD = null;
        }
      }
    });
    this.authService.sessionObserver.subscribe(res=>{
      this.cdr.detectChanges();
    })
  }

  selectCompany(item: Company) {
    // this.showMenu = false;
    this.router.navigate([`/c/${item.id}/d/home`]);
  }

  getRouterLink(
    whereToGo: 'referralUrl' | 'finance' | 'home' | 'report-list' | 'library' | 'dashboard' | 'quotation' | 
    'company-role' | 'warehouse' | 'purchase-order' | 'asset-management' | 'services' | 'company-settings' | 
    'help' | 'channels' | 'payroll-attendance' | 'todo'
  ): string[] {
    const companyId = this.authService.session.company.id;
  
    // Handle the specific case for referralUrl
    if (whereToGo === 'referralUrl') {
      return [this.referralUrl];
    }
  
    // Base route
    const baseRoute = `/c/${companyId}/d/${whereToGo}`;
  
    // Mapping for special cases
    const policyRoutes = {
      'library': [
        { policy: this.authService.companyUserPolicy.View_material_library, route: 'material' },
        { policy: this.authService.companyUserPolicy.VIEW_TRADE_LIBRARY, route: 'trade' },
        { policy: this.authService.companyUserPolicy.View_party_library, route: 'party' },
        { policy: this.authService.companyUserPolicy.VIEW_WORKFORCE_LIBRARY, route: 'workforce' },
      ],
      'company-settings': [
        { policy: this.authService.companyUserPolicy.View_company_dashboard, route: 'company-details' },
        { policy: this.authService.companyUserPolicy.View_company_party_ledger, route: 'business-overview' },
        { policy: this.authService.companyUserPolicy.View_project_dashboard, route: 'lock-bak-date' },
        { policy: this.authService.companyUserPolicy.View_Purchase_Order_List, route: 'bank-details' },
      ],
      'payroll-attendance': [
        { policy: this.authService.companyUserPolicy.VIEW_PAYROLL_PEOPLE, route: '' },
        { 
          policy: this.authService.companyUserPolicy.VIEW_STAFF_ATTENDANCE || 
                  this.authService.companyUserPolicy.VIEW_LABOUR_ATTENDANCE, 
          route: 'attendance' 
        },
      ],
      // TODO: step 1 uncomment the below code (4 lines) and and access policy for parties and company level transactions
      // 'finance': [
      //   { policy: this.authService.companyUserPolicy.View_company_dashboard, route: 'parties' },
      //   { policy: this.authService.companyUserPolicy.View_company_party_ledger, route: 'transactions' },
      // ],
    };
  
    // Handle special cases based on policies
    if (policyRoutes[whereToGo]) {
      for (const { policy, route } of policyRoutes[whereToGo]) {
        if (this.authService.hasAccess(policy)) {
          return route ? [`${baseRoute}/${route}`] : [baseRoute];
        }
      }
    }
  
    // Default route
    return [baseRoute];
  }  

  addWarehouse() {
   if(this.authService.session.warehouse?.id){
    this.router.navigate([`/c/${this.authService.session.company.id}/w/${this.authService.session.warehouse?.id}`], { queryParamsHandling: 'preserve' });
   }else{
    const openWarehouse = this.modalService.open(SettingWarehouseComponent, { size: 'lg', backdrop: 'static', backdropClass: 'sales-invoice-modal-backdropClass', windowClass: 'sales-invoice-modal-windowClass' });
    openWarehouse.componentInstance.data = { fromPage: 'warehouseDetails'}
    openWarehouse.result.then(onfulfilled => {
      sessionStorage.setItem("warehouse-created", "1");
      this.router.navigate([`/c/${this.authService.session.company.id}/w/${onfulfilled}`], { queryParamsHandling: 'preserve' });
    }, onReject => {
      //
    })
   }
  }

  deleteProject(project: Project) {
    const index = this.projects.indexOf(project);
    project.delete = 1;
    this.loading = true;
    this.adminService.editProject(project).subscribe(res => {
      this.projects.splice(index, 1);
      this.loading = false;
    });
  }

  edit(project: Project) {
    document.body.classList.add('modal-addition');
    const modalRef = this.modalService.open(ProjectComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.data = project;
    modalRef.result.then(data => {
      project = data;
    }, err => {
      console.log(err);
    });
  }

  // showSubmenu() {
  //   this.showMenu = !this.showMenu;
  // }

  loadMore(n) {
    this.limit = this.limit + n;
    this.projects = this.localStorageService.get('projectsList')
    this.projects = this.projects.slice(0, this.limit);
  }

  openCompanySettingModal() {
    this.modalService.open(SettingCompanyComponent, { size: this.authService.hasAccess(this.authService.companyUserPolicy.Comapny_details) ? 'lg' : 'md', backdrop: 'static' })
  }

  closeSmallSidebar() {
    this.responsiveService.setSmallSidebarStatus(false);
  }

  setProjectAndNavigate(project: Project) {
    if (this.authService.session.company.monkey_patch_my_company_user.company_role_id == 'id1') {
      this.authService.saveProject(project);
      const urlStr = `/../c/${this.authService.session.company.id}/p/${project.id}/${this.authService.getInitialTab()}`;
      this.router.navigate([urlStr]);
    }else{
      this.notificationsService.initiate({
        title: 'Your role is not admin!',
        type: toastTypes.error
      })
    }
  }
  openDemoPage() {
    // add token
    this.authService.addSaleleadToken().subscribe({next: (res) => {
      this.router.navigate(['/book-demo'], {queryParams:{token: res.token, company_id: this.authService.session.company?.id, project_id: this.authService.session.project?.id}})
    }, error:(err)=> {
      console.log(err);
    }})
    // route to demo page
  }

    // ngOnDestroy() {
  //   this.router.events.unsubscribe();
  // }

  //Book Demo + sales lead//

  bookDemo(){
    this.adminService.getSalesLeadToken(this.company.monkey_patch_my_company_user).subscribe({
      next:res=>{
        this.navigateToBookDemoRelative(res.token);
      },
      error:err=>{
        console.log(err)
      }
    })
  }

  navigateToBookDemoRelative(token:string) {
    const queryParams = {
      token: token,
      company_id: this.company.id
    };

    this.router.navigate(['./book-demo'], {
      queryParams: queryParams
    });
  }

  checkWarehouseStatus():boolean{
    if(!this.authService.session.company.is_paid) return false
    if(!this.authService.session.company.is_warehouse_enabled) return false;
    if (new Date(this.authService.session.company.subscription_end_date) <= new Date()) return false;
    if(this.authService.session.company.creator == this.authService.session.profile.id) return true;
    if(this.authService.session?.warehouse?.id) return true;
    return false;
  }

  // openCustomFieldModal() {
  //   const customFieldModal = this.modalService.open(CustomFieldModalComponent, { size: 'lg',  backdropClass: 'custom-field-modal-backdropClass', windowClass: 'custom-field-modal-windowClass' });

  //   customFieldModal.componentInstance.source = { company_id: this.authService.session.company.id };
  // }
}
