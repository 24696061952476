import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';


interface ButtonStatus {
  title:'Active' | 'Inactive';
  status: number;
}

interface StatusOutput {
  is_hidden: number;
}

@Component({
  selector: 'app-active-hidden-btn',
  templateUrl: './active-hidden-btn.component.html',
  styleUrls: ['./active-hidden-btn.component.css']
})
export class ActiveHiddenBtnComponent implements OnInit {
  @Input() paddingY = 11;
  @Input() isHidden = 0;
  @Output() partyStatus: EventEmitter<StatusOutput> = new EventEmitter<StatusOutput>();

  public buttonStatus = signal<ButtonStatus>({title: 'Active', status: 0});

  ngOnInit(): void {
   if(this.isHidden) {
    this.buttonStatus.set({title: 'Inactive', status: 1});
   }
  }

  
   

  changeStatus(isActive:number) {
     !isActive? this.buttonStatus.set({title: 'Active', status: 0}): this.buttonStatus.set({title: 'Inactive', status: 1});
     this.partyStatus.emit({is_hidden: this.buttonStatus().status});
  }

}
