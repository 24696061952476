
export enum FEATURE_TYPE {
  CASHBOOK = "cashbook",
  INVOICE = 'invoice',
  PO = 'po'
}

export enum CASHBOOK_TYPE {
  IRECEIVED = "iReceived",
  IPAID = "iPaid",
  PAYIN = "payIn",
  PAYOUT = "payOut",
  P2P = "p2p",
}
export enum CASHBOOK_TRANSACTION_TYPE {
  CASHBOOK_DEPOSIT = "cashbook_deposit",
  CASHBOOK_WITHDRAW = "cashbook_withdraw",
}

export enum CASHBOOK_TRANSACTION_FEATURE_TYPE{
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
}
export enum CASHBOOK_STATUS_TYPE {
  PARTIALLY_PAID = "partiallypaid",
  PAID = "paid",
  UNPAID = "unpaid"
}
export enum APPROVAL_FLAG{
    PENDING = "pending",
    AUTO_APPROVED = "auto_approved",
    APPROVED = "approved",
    REJECTED = "rejected"
}
