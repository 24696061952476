import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../../config/config.config';
import { payroll_type } from 'src/app/enum/enum';

@Injectable({
  providedIn: 'root'
})
export class CompnayAttendanceService {

  private updatePayrollOfId = new BehaviorSubject<string | null>(null);
  updatePayrollOfId$ = this.updatePayrollOfId.asObservable();

  constructor(
    private http: HttpClient,
    private config: Config
  ) { }

  sendPayrollOfId(id: string) {
    this.updatePayrollOfId.next(id);
  }

  detailCompanyAttendanceInfo(id:string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyattendanceinfo/${id}`,
      { }
    );
  }

  setCompanyAttendanceLevelTab(tab:payroll_type){
    sessionStorage.setItem('companyAttendanceLevelTab', tab);
  }

  getCompanyAttendanceLevelTab():payroll_type{
    const tab = sessionStorage.getItem('companyAttendanceLevelTab') as payroll_type;
    if(tab){
      return tab
    }
    return payroll_type.labour;
  }

}
