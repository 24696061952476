import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { Company, CompanyRole, CompanyUser, Member, Project, TeamMember } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent {
  @Input() project?: Project;
  @Output() closePopupEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public company = {} as Company;
  public companyUsers = [] as CompanyUser[];
  public companyUser = {} as CompanyUser;
  public companyRoles = [] as CompanyRole[];
  public companyRole = {} as CompanyRole;
  public companySearchBox = false;
  public members = [] as Member[];
  public member = {} as Member;
  public accessBox = false;
  constructor(
    public authService: AuthService,
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private utilService: UtilService,
  ) {
  }
  ngOnInit() {
    this.company = this.authService.session.company;
    if (this.project?.id) this.getMemberList(this.project.id);
    else this.getCompanyLevelTeamMemberList();
    this.getCompanyRoles();
    this.getCompanyUserList();
  }
  ngOnDestroy(): void {
    this.localStorageService.remove('companyUserList');
  }

  getCompanyLevelTeamMemberList(){
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.getCompanyLevelTeamMemberList(qp).subscribe({
      next:(res)=>{
        this.members = res.data || [] as TeamMember[];
      },
      error:(err)=>{
        console.log(err)
      } 
    })
  }
  getMemberList(project_id: string) {
    let qp = new HttpParams();
    qp = qp.set('project_id', project_id);
    this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      this.members = res.data || ([] as Member);
    });
  }
  getCompanyRoles() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.listCompanyRole(qp).subscribe(
      {
        next: (res) => {
          this.companyRoles = res.data || [] as CompanyRole[];
        },
        error: () => {
          //
        }
      }
    )
  }
  getCompanyUserList() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.getCompanyUserList(qp).subscribe((res) => {
      this.companyUsers = res.data || ([] as CompanyUser[]);
      this.localStorageService.set('companyUserList', this.companyUsers);
      this.companySearchBox = true;
    });
  }
  setCompanyUser(companyUser?: CompanyUser) {
    if (companyUser) {
      this.companyUser = companyUser;
      for (const mem of this.members) {
        if (mem.company_user_id == companyUser.id) {
          this.companySearchBox = false;
          this.member = mem || {} as Member;
          this.openAccessBox(mem)
          return;
        }
      }
      if (companyUser.mobile == 0) {
        this.notificationsService.initiate({ title: 'Mobile not updated of selected user', type: toastTypes.error });
        return
      }
      if (companyUser.company_role_id != '') {
        this.member = {};
        this.member.company_user_id = companyUser.id;
        if (this.project?.id) {
          this.member.project_id = this.project.id;
          this.addTeamMember(this.project.id, companyUser.id);
        }
        this.accessBox = true;
        return
      } else {
        this.accessBox = true;
      }
    } else {
      this.companyUser = {}
    }
  }
  openAccessBox(item: Member) {
    if (this.project?.contractor_company_user_id == item.company_user_id && this.project?.id) {
      this.notificationsService.initiate({ title: 'Not allowed', type: toastTypes.error });
      return
    }
    const index = this.companyUsers.findIndex(i => i.id == item.company_user_id);
    this.companyUser = this.companyUsers[index] || {} as CompanyUser;
    if(this.authService.session.profile.id == this.companyUser.user_id){
      this.notificationsService.initiate({ title: 'You can not change your role', type: toastTypes.error });
      return
    }
    this.accessBox = true;
    return this.member = item;
  }
  deleteTeamMember() {
    const deleteModal = this.modalService.open(ConfirmModalComponent);
    deleteModal.componentInstance.data = {
      modalTitle: 'Remove member',
      modalContent: 'Are you sure?',
    };
    deleteModal.result.then(
      (onfulfilled) => {
        if (this.project?.id) {
          this.adminService.deleteTeamMember(this.member.id).subscribe((res) => {
            const member_index = this.members.findIndex((m) => m.id === res.id);
            this.members.splice(member_index, 1);
            this.accessBox = false;
            this.companyUser = {};
            this.member = {};
            this.utilService.memberCount.next(this.members.length);
          }, err => console.log(err), () => {
            this.member = {};
          });
        } else {
          this.adminService.removeCompanyUser({id: this.member.company_user_id}).subscribe((res) => {
            this.closePopupEvent.next(true);
          }, err => console.log(err), () => {
            this.member = {};
          });
        }
      },
      (onrejected) => this.member = {}
    );
  }
  setMemberAccess(role: CompanyRole) {
    this.patchCompanyUser(this.companyUser.id, role.id)
  }
  patchCompanyUser(company_user_id: string, role_id: string) {
    this.companyUser.id = company_user_id;
    this.companyUser.company_role_id = role_id;
    // this.companyUser.type = CompanyPartyType.Employee;
    this.adminService.editCompanyUser(this.companyUser).subscribe({
      next: () => {
        if (this.project?.id) this.addTeamMember(this.project.id, company_user_id)
        else this.closePopupEvent.next(true);
      },
      error: () => {
        //
      }
    })
  }
  addTeamMember(project_id: string, company_user_id: string) {
    this.member = {};
    this.member.company_user_id = company_user_id;
    this.member.project_id = project_id;
    this.adminService.saveNewUserRole(this.member).subscribe(
      (res) => {
        const index = this.members.findIndex(i => i.id == res.id);
        if (index == -1) {
          this.members.push(res);
        } else {
          this.members[index] = res || {} as Member;
        }
        this.utilService.memberCount.next(this.members.length);
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
