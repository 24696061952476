<div  class="schedule w-100">
    <div  class="schedule-header d-flex align-items-center w-100 border-bottom mb-2">
        <div class="width-40">
            <span class="f-10 disabled-font ">
                Item
            </span>
        </div>

       <div class="width-20">
        <span class="f-10 disabled-font ">
            Start Date
         </span>
       </div>

       <div class="width-20">
        <span class="f-10 disabled-font ">
            End Date
          </span>
       </div>

        <div class="width-20">
            <span class="f-10 disabled-font ">
                Progress
            </span>
        </div>
    </div>

    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScroll()"
    [scrollWindow]="false" [infiniteScrollDisabled]="valueStack.page?.next_url == ''" class="schedule-body d-flex flex-column gap-2">
        <ng-container *ngFor="let item of progress">
            <div class="schedule-header d-flex align-items-center py-1  w-100 border-bottom">
                <div style="overflow: hidden; text-overflow: ellipsis;" class="width-40">
                    <span style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" class="f-12 ">
                        {{item?.name}}
                    </span>
                </div>
        
               <div class="width-20">
                <span class="f-12 ">
                    {{item?.start_date | date:"dd MMM, yy"}}  
                 </span>
               </div>
        
               <div class="width-20">
                <span class="f-12 ">
                    {{item?.end_date | date:"dd MMM, yy"}}  
                  </span>
               </div>
        
                <div class="width-20">
                    <span *ngIf="item?.unit === '%'" class="f-12 ">
                       {{item?.completed_quantity | dynamicNumber: {digit: authService.session.country.decimal_digit} }}{{item?.unit}}
                    </span>
                    <span *ngIf="item?.unit !== '%'" class="f-12 ">
                        {{item?.completed_quantity | dynamicNumber: {digit: authService.session.country.decimal_digit} }}/{{item?.estimated_quantity | dynamicNumber: {digit: authService.session.country.decimal_digit} }} {{item?.unit}}
                     </span>
                </div>
            </div>
        </ng-container>
    </div>
</div>