import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category, Member, Project, Subcategory } from 'src/app/interfaces/interfaces';
import { HttpParams } from '@angular/common/http';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AdminService } from 'src/app/core/services/admin.service';
import { UtilService } from 'src/app/shared/services/util.service';


interface Parent {
  page: 'Payment' | 'Material' | 'Attendance' | 'Party' | 'Warehouse' | 'Company-party'
}

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.css']
})
export class FilterOptionComponent implements OnInit {
 
  @Output() onFilterParameter = new EventEmitter<any>(true)
  @Output() companyPartyFilter = new EventEmitter<any>();
  @Input() parent: Parent;

  public filterPopUp = false;
  public selectedFilterSwitch = '';
  public totalSelectedFilterCount = 0;
  public fromDate = '';
  public toDate = '';
  public today = this.formatDate(new Date);
  public paymentType = [ {name:'To Pay', param:'toPay'},{name:'To Receive', param:'toReceive'}, {name:'Advance Paid', param:'advancePaid'}, {name:'Advance Received', param:'advanceReceived'}];
  public partyType = [ 
    {name:"Client", param:"customer"},
    {name:"Staff", param:"employee"},
    {name:"Labour", param:"labour"},
    {name:"Investor", param:"investor"},
    {name:"Labour Contractor", param:"labour_contractor"},
    {name:"Material Supplier", param:"material_supplier"},
    {name:"Equipment Supplier", param:"equipment_supplier"},
    {name:"Subcontractor", param:"subcontractor"}
  ];
  //For Payment page filter
  public pageFilter = {
    'date': { name: 'Date', hasSelectedValue: [] },
    'transaction': { name: 'Transaction', hasSelectedValue: [] },
    'party': { name: 'Party', hasSelectedValue: [] },
    'trade': { name: 'Cost Code', hasSelectedValue: [] },
    'category': { name: 'Category', hasSelectedValue: [] },
    'creator': { name: 'Entry by', hasSelectedValue: [] },
    'paymentMode': { name: 'Mode', hasSelectedValue: [] },
    'paymentType':{name:'Payment Type', hasSelectedValue:''},
    'partyType':{name:'Party Type', hasSelectedValue:''},
  }

  // Party or members
  public members = [] as Member[];
  public partySearchString?: string;

  //Date
  public myDateType = {
    'all': { name: 'All' },
    'today': { name: 'Today' },
    'lastWeek': { name: 'Last Week (Mon - Sun)' },
    'lastMonth': { name: 'Last Month' },
    'thisWeek': { name: 'This Week (Mon - Today)' },
    'thisMonth': { name: 'This Month' }
  }
  public myDate;
  public start_date;
  public end_date;


  //transaction_type=materialreturn
  // Transaction Type
  public myTransactionType = {
    'all': { name: 'All', isSelected: false },
    'cashbook_deposit': { name: 'Payment In', isSelected: false },
    'cashbook_withdraw': { name: 'Payment Out', isSelected: false },
    'material': { name: 'Material Purchase', isSelected: false },
    'partyearning': { name: 'Other Expense', isSelected: false },
    'staff_payments': { name: 'Party Payment', isSelected: false },
    'reimbursement': { name: 'Site Expense', isSelected: false },
    'customerinvoice': { name: 'Sales Invoice', isSelected: false },
    'materialreturn': { name: 'Material Return', isSelected: false },
    'material_transfer': { name: 'Material Transfer', isSelected: false },
  };




  public paymentMode = {
    // 'Cash': { name: 'Cash', isSelected: false },
    // 'Online': { name: 'Online', isSelected: false },
    'All': { name: 'All', isSelected: false },
    'Cash': { name: 'Cash', isSelected: false }
  }

  public expenseStatus = {
    'paid': { name: 'Paid', isSelected: false },
    'unpaid': { name: 'Unpaid', isSelected: false },
    'partiallypaid': { name: 'Partially Paid', isSelected: false }
  }

  //Cat or sub cat
  public subCategories = [] as Subcategory[];
  public categories = [] as Category[];

  public project = {} as Project;
  public Object = Object;

  public valueStackSubCategory = {
    page: { next_url: "" },
  };
  public busy = false;

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private utilService: UtilService
  ) {
    this.project = this.authService.session.project as Project;
  }
  ngOnInit(): void {
    if(this.parent.page === 'Company-party'){
      
      this.selectedFilterSwitch = 'paymentType'
      delete this.pageFilter['date'];
      delete this.pageFilter['transaction']
      delete this.pageFilter['party']
      delete this.pageFilter['trade']
      delete this.pageFilter['category']
      delete this.pageFilter['creator']
      delete this.pageFilter['paymentMode']
    }else{
      this.selectedFilterSwitch = 'date';

      if (this.parent.page === 'Warehouse') {
        delete this.myTransactionType['cashbook_deposit'];
        delete this.myTransactionType['cashbook_withdraw'];
        delete this.myTransactionType['partyearning'];
        delete this.myTransactionType['staff_payments'];
        delete this.myTransactionType['reimbursement'];
        delete this.myTransactionType['customerinvoice'];
      }
    }
    

   

  }

 


  toggleFilter() {
    this.getProjectParties();
    this.getTransactionSubcategory();
    this.getCategory();
    this.transactionCreators();
  
  }
  applyFilter() {
    
    let qp = new HttpParams();
    if(this.parent.page !== 'Company-party'){
      if (this.pageFilter.category.hasSelectedValue.length > 0) {
        qp = qp.set("category_id", this.pageFilter.category.hasSelectedValue[0]);
      }
      if (this.pageFilter.party.hasSelectedValue.length > 0) {
        qp = qp.set("company_user_id", this.pageFilter.party.hasSelectedValue[0]);
      }
      if (this.pageFilter.creator.hasSelectedValue.length > 0) {
        qp = qp.set("creator_company_user_id", this.pageFilter.creator.hasSelectedValue[0]);
      }
      if (this.pageFilter.trade.hasSelectedValue.length > 0) {
        qp = qp.set("sub_category_id", this.pageFilter.trade.hasSelectedValue[0]);
      }
      if (this.pageFilter.transaction.hasSelectedValue.length > 0) {
        if (this.pageFilter.transaction.hasSelectedValue[0] != 'all') {
          qp = qp.set("transaction_type", this.pageFilter.transaction.hasSelectedValue[0]);
        }
      }
      if (this.pageFilter.paymentMode.hasSelectedValue.length > 0) {
        if (this.pageFilter.paymentMode.hasSelectedValue[0] != 'All') {
          qp = qp.set("payment_mode", this.pageFilter.paymentMode.hasSelectedValue[0]);
        }
      }
  
     
      if (this.pageFilter.date.hasSelectedValue.length > 0) {
        if (this.pageFilter.transaction.hasSelectedValue[0] != 'all') {
          if (this.start_date) {
            qp = qp.set("start_date", this.start_date);
          }
          if (this.end_date) {
            qp = qp.set("end_date", this.end_date);
          }
        }
      }
      this.sendQParamstoParent(qp);
    }else {
      const partyFilterData = {
          filterTitle: [] as string[],
          queryParams: new HttpParams(),
          isDirty: false
      };
  
      const paymentType = this.pageFilter.paymentType.hasSelectedValue;
  
      if (paymentType) {
          switch (paymentType) {
              case 'toPay':
                  partyFilterData.filterTitle.push('To Pay');
                  qp = qp.set("balance_status", 'negative');
                  break;
              case 'toReceive':
                  partyFilterData.filterTitle.push('To Receive');
                  qp = qp.set("balance_status", 'positive');
                  break;
              case 'advancePaid':
                  partyFilterData.filterTitle.push('Advance Paid');
                  qp = qp.set("balance_status", 'positive');
                  break;
              case 'advanceReceived':
                  partyFilterData.filterTitle.push('Advance Received');
                  qp = qp.set("balance_status", 'negative');
                  break;
              default:
                  break;
          }
      }
  
      if (this.pageFilter.partyType.hasSelectedValue) {
          const partyType = this.pageFilter.partyType.hasSelectedValue;
          qp = qp.set("type", partyType);
          partyFilterData.filterTitle.push(partyType);
  
          if ((partyType === 'customer' && (paymentType === 'toPay' || paymentType === 'advancePaid')) ||
              ((partyType === 'employee' || partyType === 'vendor') && (paymentType === 'toReceive' || paymentType === 'advanceReceived'))) {
              partyFilterData.isDirty = true;
          }
      } else {
          if (paymentType === 'toPay' || paymentType === 'advancePaid') {
              qp = qp.set("type", "vendor,employee");
          } else if (paymentType === 'toReceive' || paymentType === 'advanceReceived') {
              qp = qp.set("type", "customer");
          }
      }
  
      partyFilterData.queryParams = qp;
  
      this.companyPartyFilter.emit(partyFilterData);
  }
  
  
    
  }

  sendQParamstoParent(qp) {
    this.onFilterParameter.emit({ queryParams: qp, filterCount: this.totalSelectedFilterCount });
  }

  clearFilter() {
    this.pageFilter = {
      'date': { name: 'Date', hasSelectedValue: [] },
      'transaction': { name: 'Transaction', hasSelectedValue: [] },
      'party': { name: 'Party', hasSelectedValue: [] },
      'trade': { name: 'Cost Code', hasSelectedValue: [] },
      'category': { name: 'Category', hasSelectedValue: [] },
      'creator': { name: 'Entry by', hasSelectedValue: [] },
      'paymentMode': { name: 'Mode', hasSelectedValue: [] },
      'paymentType':{name:'Payment Type', hasSelectedValue:''},
      'partyType':{name:'Party Type', hasSelectedValue:''},
       
    }

    if(this.parent.page === 'Company-party'){
      
      this.selectedFilterSwitch = 'paymentType'
      delete this.pageFilter['date'];
      delete this.pageFilter['transaction']
      delete this.pageFilter['party']
      delete this.pageFilter['trade']
      delete this.pageFilter['category']
      delete this.pageFilter['creator']
      delete this.pageFilter['paymentMode']

      this.companyPartyFilter.emit({
        filterTitle: [] as string[],
        queryParams: new HttpParams(),
        isDirty: false
    });
    }
    
    this.clearDate();
    this.totalSelectedFilterCount = 0;
    this.onFilterParameter.emit(null);
    this.filterPopUp = false;
  }
  clearDate() {
    this.fromDate = null;
    this.toDate = null;
  }
  getTotalSelectedFilterCount() {
    this.totalSelectedFilterCount = 0;
    for (const [key, item] of this.Object.entries(this.pageFilter)) {
      this.totalSelectedFilterCount += item.hasSelectedValue.length;
    }
  }

  // Party
  getProjectParties() {
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);
    //list/transaction/companyuser?
    this.authService.listTransactionCompanyUser(qp).subscribe({
      next: (res) => {
        this.members = res.data;
      }
    });
  }

  pullData(term: string): Observable<any[]> {
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id);
    qp = qp.set('search', term);
    return this.authService.listTransactionCompanyUser(qp).pipe(map(res => {
      this.members = res.data;
      return [];
    }));
  }

  partySearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term =>
        this.pullData(term).pipe(
          catchError(() => {
            return of([]);
          }))
      )
    )
  addRemoveParty(item: Member) {
    if (this.pageFilter['party'].hasSelectedValue.includes(item.id)) {
      this.pageFilter['party'].hasSelectedValue = []
    } else {
      this.pageFilter['party'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveCreator(item: Member) {
    if (this.pageFilter['creator'].hasSelectedValue.includes(item.id)) {
      this.pageFilter['creator'].hasSelectedValue = []
    } else {
      this.pageFilter['creator'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  // Date
  addRemoveDate(key) {
    if (this.pageFilter['date'].hasSelectedValue.includes(key) && key != 'custom') {
      this.pageFilter['date'].hasSelectedValue = []
    } else {
      this.pageFilter['date'].hasSelectedValue = [key]
    }
    this.dateRangeSet(key);
    this.getTotalSelectedFilterCount();
  }

  formatDate(date) {
    if (!date) return;
    const currentDate = new Date(date);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  dateRangeSet(input) {
    const nowDate = new Date();
    if (input == "all") {
      this.start_date = null;
      this.end_date = null;
      this.fromDate = null;
      this.toDate = null;
      return;
    } else if (input == "today") {
      this.start_date = this.currentZoneToUtc(nowDate, 'sd');
      this.end_date = this.currentZoneToUtc(nowDate, 'ed');
    } else if (input == "lastWeek") {
      nowDate.setDate(nowDate.getDate() - ((nowDate.getDay() + 6) % 7));
      const d = new Date().getDate() - nowDate.getDate();
      d < 7 ? nowDate.setDate(nowDate.getDate() - 7) : nowDate;
      this.start_date = this.currentZoneToUtc(nowDate, 'sd');
      this.end_date = new Date();
      this.end_date.setDate(nowDate.getDate() + 6);
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "lastMonth") {
      this.start_date = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 0);
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "thisWeek") {
      const d = nowDate.getDay();
      const mon = new Date(nowDate);
      // this.start_date = new Date(mon.setDate(mon.getDate() - d + 1));
      this.start_date = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date();
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "thisMonth") {
      this.start_date = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
      this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      this.end_date = new Date();
      this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
    } else if (input == "custom") {
      this.start_date = this.currentZoneToUtc(new Date(), 'sd');
      this.end_date = this.currentZoneToUtc(new Date(), 'ed');
      if (this.fromDate) {
        this.start_date = new Date(this.fromDate);
        this.start_date = this.currentZoneToUtc(this.start_date, 'sd');
      }
      if (this.toDate) {
        this.end_date = new Date(this.toDate);
        this.end_date = this.currentZoneToUtc(this.end_date, 'ed');
      }
      if (this.start_date >= this.end_date) {
        if (!this.fromDate) {
          this.start_date = this.end_date;
        }
        if (this.fromDate && this.toDate) {
          this.end_date = this.start_date;
        }
      }
    }
    this.fromDate = this.formatDate(this.start_date);
    this.toDate = this.formatDate(this.end_date);
  }

  currentZoneToUtc(date: Date, dateFor: 'sd' | 'ed') {
    if (dateFor == 'sd') {
      date.setHours(0, 0, 0, 1);
    } else if (dateFor == 'ed') {
      date.setHours(23, 59, 59, 999);
    }
    return date.toISOString();
  }

  //Transaction
  addRemoveTransaction(key) {
    if (this.pageFilter['transaction'].hasSelectedValue.includes(key)) {
      this.pageFilter['transaction'].hasSelectedValue = []
    } else {
      this.pageFilter['transaction'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  //Transaction
  addRemovePaymentMode(key) {
    if (this.pageFilter['paymentMode'].hasSelectedValue.includes(key)) {
      this.pageFilter['paymentMode'].hasSelectedValue = []
    } else {
      this.pageFilter['paymentMode'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }

  //Party Type

  addRemovePartyType(key:string){
    if(this.pageFilter['partyType'].hasSelectedValue !== key){
     
      if(!this.pageFilter['partyType'].hasSelectedValue){
        this.totalSelectedFilterCount = this.totalSelectedFilterCount + 1;
      }
      this.pageFilter['partyType'].hasSelectedValue = key;

    }else{
      this.totalSelectedFilterCount = this.totalSelectedFilterCount - 1;
      this.pageFilter['partyType'].hasSelectedValue = '';
    }


   
  }

   //Payment Type

   addRemovePaymentType(key){
  
    if(this.pageFilter['paymentType'].hasSelectedValue !== key){
     
      if(!this.pageFilter['paymentType'].hasSelectedValue){
        this.totalSelectedFilterCount = this.totalSelectedFilterCount + 1;
      }
      this.pageFilter['paymentType'].hasSelectedValue = key;
    }else{
      this.totalSelectedFilterCount = this.totalSelectedFilterCount - 1;
      this.pageFilter['paymentType'].hasSelectedValue = '';
    }
   
  }


  //Trade
  getTransactionSubcategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    qp = qp.set('project_id', this.project.id);
    this.adminService.transactionSubcategoryList(qp).subscribe((res) => {
      this.valueStackSubCategory = res;
      this.subCategories = res.subcategories || [] as Subcategory[];
    });
  }

  addRemoveTrade(item: Subcategory) {
    if (this.pageFilter['trade'].hasSelectedValue.includes(item.id)) {
      this.pageFilter['trade'].hasSelectedValue = []
    } else {
      this.pageFilter['trade'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  

  //Category
  getCategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    qp = qp.set('type', 'cashbook');
    this.adminService.categoryList(qp).subscribe({
      next: res => this.categories = res.categories || [] as Category,
      error: err => console.log(err)
    });
  }

  creator_companyUsers = [] as Member[];
  transactionCreators() {
    let qp = new HttpParams();
    qp = qp.set('project_id', this.project.id)
    this.adminService.getTransactionCreators(qp).subscribe({
      next: res => this.creator_companyUsers = res.data || [] as Member[],
      error: (err) => console.log(err)
    });
  }

  addRemoveCategory(item: Category) {
    if (this.pageFilter['category'].hasSelectedValue.includes(item.id)) {
      this.pageFilter['category'].hasSelectedValue = []
    } else {
      this.pageFilter['category'].hasSelectedValue = [item.id]
    }
    this.getTotalSelectedFilterCount();
  }

  addRemoveExpense(key) {
    if (this.pageFilter['expenseStatus'].hasSelectedValue.includes(key)) {
      this.pageFilter['expenseStatus'].hasSelectedValue = []
    } else {
      this.pageFilter['expenseStatus'].hasSelectedValue = [key]
    }
    this.getTotalSelectedFilterCount();
  }


  onScrollSubCategory() {
    const url = this.valueStackSubCategory.page.next_url;
    if (!this.busy) {
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(
        (res) => {
          this.valueStackSubCategory = res;
          this.subCategories = this.subCategories.concat(res.subcategories || [] as Subcategory[]);
          this.busy = false;
        },
        (err) => {
          this.busy = false;
          this.valueStackSubCategory = {
            page: { next_url: "" },
          };
        }
      );
    }
  }
}
