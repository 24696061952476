import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/';
import { Config } from '../../config/config.config';

@Injectable({
  providedIn: 'root'
})
export class CompnayAttendanceService {

  constructor(
    private http: HttpClient,
    private config: Config
  ) { }

  detailCompanyAttendanceInfo(id:string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyattendanceinfo/${id}`,
      { }
    );
  }

}
