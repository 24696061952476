export enum CompanyPartyType {
  Customer = 'Customer',
  Employee = 'employee',
  Labour = 'labour',
  Investor = 'investor',
  MaterialSupplier = 'material supplier',
  LabourContractor = 'labour contractor',
  EquipmentSupplier = 'equipment supplier',
  SubContractor = 'sub contractor',
  Vendor = 'vendor'
}

export enum payroll_level {
  Company = 'company',
  Project = 'project'
}

export enum payroll_type {
  staff = 'staff',
  labour = 'labour',
  labour_contractor = 'labour_contractor'
}

export enum view_retention_source {
  company_party_user_ledger = 'company_party_user_ledger',
  view_sales_invoice = 'view_sales_invoice'
}



export const CostComponentTypeAndIcon = {
  material: {
    type: 'material',
    icon: 'featherTruck'
  },
  labour: {
    type: 'labour',
    icon: 'featherUsers'
  },
  fees: {
    type: 'fees',
    icon: 'featherCreditCard'
  }
} as const;

export enum Subcategory_type {
  costCode = 'costcode',
  materialCategory = 'material_category'
}

export enum ViewRetentionSource {
  companyLevelParty = 'company_level_party',
  viewSalesInvoice = 'view_sales_invoice',
}
