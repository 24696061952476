<div *ngIf="filterPopUp" class="background_onclick_close" (click)="filterPopUp=false"></div>
<div *ngIf="filterPopUp" class="absolute_pop_up_for_create_new filter-popup-container" style="top: -15px; left: -18px">
  <div class="p-18 d-flex justify-content-between align-items-center">
    <div class="filter_button" (click)="filterPopUp=!filterPopUp">
      Filter
      <img src="../../../../assets/icons/sliders.svg" style="height: 20px;margin-left: 8px;" />
    </div>
    <!-- <div></div> -->
    <img src="../../../../assets/icons/close.png" style="width: 20px;height: 20px;cursor: pointer;"
      (click)="filterPopUp=false" />
  </div>
  <div class="option_seperator_horizontal"></div>



  <div class="responsive-flex">
    <div class="responsive-tabbar">
      <ng-container [ngSwitch]="selectedFilterSwitch" *ngFor="let key of Object.keys(selectedTypeFilter)">
        <div (click)="selectedFilterSwitch=key" class="filter" [ngClass]="key==selectedFilterSwitch?'active':''">
          <div *ngIf="key==selectedFilterSwitch" class="active-abs"></div>
          <div class="filter-text">
            <div class="filter-type">{{selectedTypeFilter[key].name}}</div>
            <div class="filter-selected" *ngIf="selectedTypeFilter[key].hasSelectedValue.length>0">
              {{selectedTypeFilter[key].hasSelectedValue.length}} Selected</div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="responsive-body" [ngSwitch]="selectedFilterSwitch">

      <ng-template [ngSwitchCase]="'party'">
        <div class="px-5 mt-3">
          <div class="custom-input">
            <label>Party Name</label>
            <input autocomplete="off" [(ngModel)]="partySearchString" [ngbTypeahead]="partySearchFn" #companyUserName />
            <span>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20.9999 20.9984L16.6499 16.6484" stroke="#61636F" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
          </div>
        </div>
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of members">
            <div>
              <div (click)="addRemoveParty(item)"
                [ngClass]="paymentPageFilter['party'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveParty(item)">{{item.name}}</div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'creator'">

        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of creator_companyUsers">
            <div>
              <div (click)="addRemoveCreator(item)"
                [ngClass]="paymentPageFilter['creator'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveCreator(item)">{{item.name}}</div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'trade'">
        <div class="my-2 ps-5 " style="min-height: 250px; height: 100%; overflow-y: auto; " infiniteScroll
          [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScrollSubCategory()"
          [scrollWindow]="false" [infiniteScrollDisabled]="valueStackSubCategory.page.next_url == ''">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of subCategories">
            <div>
              <div (click)="addRemoveTrade(item)"
                [ngClass]="paymentPageFilter['trade'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveTrade(item)">{{item.text_en}}</div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'category'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of categories">
            <div>
              <div (click)="addRemoveCategory(item)"
                [ngClass]="paymentPageFilter['category'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveCategory(item)">{{item.text_en}}</div>
          </div>
        </div>
      </ng-template>

      <!-- <ng-template [ngSwitchCase]="'date'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center">
            <div class="custom-input fs-8 pe-2">
              <label for="qtn-name">Start Date</label>
              <input class="date-input" type="date" [(ngModel)]="fromDate" (change)="addRemoveDate('custom')"
                [max]="today" />
            </div>
            <div class="custom-input fs-8 pe-2">
              <label for="qtn-name">End Date</label>
              <input class="date-input" type="date" [(ngModel)]="toDate" (change)="addRemoveDate('custom')"
                [min]="fromDate" [max]="today" />
            </div>
          </div>
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(myDateType)">
            <div>
              <div (click)="addRemoveDate(key)"
                [ngClass]="paymentPageFilter['date'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveDate(key)">{{myDateType[key].name}}</div>
          </div>
        </div>
      </ng-template> -->

      <ng-template [ngSwitchCase]="'costcode'" >
        <div class="my-2 ps-5 " style="min-height: 250px; height: 100%; overflow-y: auto; " infiniteScroll
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScrollCostCodeSubcategory(subcategory_type.costCode)"
        [scrollWindow]="false" [infiniteScrollDisabled]="valueStackSubCategory.page.next_url == ''">
        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of subCategoryList">
          
          <div>
            <div (click)="addRemoveCostCode(item.id)"
              [ngClass]="materialLibraryFilter['costcode'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
            </div>
          </div>
          <div class="selection-value pointer" (click)="addRemoveCostCode(item.id)">{{item.text_en}}</div>
        </div>
      </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'material_category'" >
        <div class="my-2 ps-5 " style="min-height: 250px; height: 100%; overflow-y: auto; " infiniteScroll
        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScrollCostCodeSubcategory(subcategory_type.materialCategory)"
        [scrollWindow]="false" [infiniteScrollDisabled]="valueStackSubCategory.page.next_url == ''">
        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of MaterialCategoryList">
          
          <div>
            <div (click)="addRemoveMaterialCategory(item.id)"
              [ngClass]="materialLibraryFilter['material_category'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
            </div>
          </div>
          <div class="selection-value pointer" (click)="addRemoveMaterialCategory(item.id)">{{item.text_en}}</div>
        </div>
      </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'transaction'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(myTransactionType)">
            <div>
              <div (click)="addRemoveTransaction(key)"
                [ngClass]="(paymentPageFilter['transaction'].hasSelectedValue.includes(key))?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveTransaction(key)">{{myTransactionType[key].name}}
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'notificationType'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(myNotificationType)">
            <div>
              <div (click)="addRemoveNotificationType(key)"
                [ngClass]="(notificationFilter['notificationType'].hasSelectedValue.includes(key))?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveNotificationType(key)">{{myNotificationType[key].name}}
            </div>
          </div>
        </div>
      </ng-template>


      <!-- *ngIf="paymentPageFilter['transaction'].hasSelectedValue.includes('cashbook_deposit' || 'cashbook_withdraw')" -->
      <ng-template [ngSwitchCase]="'paymentMode'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(paymentMode)">
            <div>
              <div (click)="addRemovePaymentMode(key)"
                [ngClass]="paymentPageFilter['paymentMode'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemovePaymentMode(key)">{{paymentMode[key].name}}</div>
          </div>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="'project'">
        <div class="my-2 ps-5 " style="min-height: 250px; height: 100%; overflow-y: auto; " infiniteScroll
          [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScrollProject()"
          [scrollWindow]="false" [infiniteScrollDisabled]="valueStackSubCategory.page.next_url == ''">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of projectList">
            <div>
              <div (click)="addRemoveProject(item.id)"
                [ngClass]="(paymentPageFilter['project'].hasSelectedValue.includes(item.id))?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveProject(item.id)">{{item.name}}</div>
          </div>
        </div>
      </ng-template>

      <ng-template [ngSwitchCase]="'status'">
        <div class="my-2 ps-5 scroll-filter-div">
          <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(statusType)">
            <div>
              <div (click)="addRemoveStatus(key)"
                [ngClass]="paymentPageFilter['status'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
              </div>
            </div>
            <div class="selection-value pointer" (click)="addRemoveStatus(key)">{{statusType[key].name}}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>







  <div class="option_seperator_horizontal"></div>
  <div class="p-18 d-flex justify-content-between align-items-center">
    <div (click)="clearFilter()" class="f-12 flex_box flex_align_center pointer" style="margin: 0;color: #61636F">
      Clear Filter <span class="cicular_indicator bg-grey">{{totalSelectedFilterCount}}</span>
    </div>

    <button class="onsite-btn onsite-btn-primary" (click)="applyFilter()">
      <span class="btn-text">
        Apply
      </span>
    </button>
  </div>
</div>