import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateModalComponent } from 'src/app/company-settings/company-settings/components/custom-fields/components/custom-date-modal/custom-date-modal.component';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
import { CustomField } from 'src/app/interfaces/interfaces';
import { ValidatorService } from 'src/app/shared/services/validator.service';

interface Parent {
  customFields:CustomField[]; 
}

@Component({
  selector: 'app-party-custom-fields-list',
  templateUrl: './party-custom-fields-list.component.html',
  styleUrls: ['./party-custom-fields-list.component.css']
})
export class PartyCustomFieldsListComponent implements OnInit {
  @Input() source = {} as Parent;

  public loading = false;
  public customFields = [] as any[];
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    private adminService: AdminService,
    public authService: AuthService,
    public validatorService: ValidatorService,
    public ngModal:NgbModal,
    public ngbActiveOffCanvas: NgbActiveOffcanvas
  ) { 
    //
  } 
  ngOnInit(): void {
   this.customFields = this.source.customFields;
  }
  getCustomFieldList(partyType:string){
    this.loading = true;
    
    let params = new HttpParams();
    params = params.append('company_id', this.authService.session.company.id);
    params = params.append('custom_field_type', partyType);

    const service = this.adminService;
    service.getCustomFieldList(params).subscribe({
      next:(res) => {
       this.customFields = res.data;
       this.loading = false;
      },
      
      error:(err)=>{
      
        console.error(err);
      }
    }); 
  }

  toggleCollapse(index: number) {
    this.customFields[index].isCollapsed = !this.customFields[index].isCollapsed;
  }
  
  onCollapseChange(collapsed: boolean, index: number) {
    this.customFields[index].isCollapsed = collapsed;
  }

  openDatePicker(customField: CustomField){
    const ngbModalRef = this.ngModal.open(CustomDateModalComponent,{
      size:'md',
      centered: true,
      windowClass: 'custom-modal',
      backdropClass: 'custom-modal-backdropClass',
    });
  
    ngbModalRef.componentInstance.source = customField.default_value;
  
    ngbModalRef.result.then((onResult) => {
      customField.default_value = onResult.selectedDate;
    }, (onReject)=>{
      //
    })
  }

  // addNewCustomField(){
  //    const customFieldRef = this.ngModal.open(NewCustomFieldComponent,{
  //     size:'md'
  //    })
  //    customFieldRef.componentInstance.source = { company_id: this.authService.session.company.id, type: this.source.party_type};
  //    customFieldRef.result.then((onFullfilled) => {
  //     this.getCustomFieldList(this.source.party_type);
  //    }, (onReject) => {
  //     //
  //    })
  // }

  save(){
   this.ngbActiveOffCanvas.close(
    this.customFields
   )
  }



}
