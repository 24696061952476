<div style="padding: 30px 40px 0px 40px;">
  <div class="d-flex flex-column">
    <div class="d-flex" style="margin-bottom:20px;">
      <div class="f-18" style="font-weight: 500;">Project Setting</div>
      <div class="ms-auto">
        <svg class="pointer" (click)="activeModal.dismiss();" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>

    <div class="tabs_container">
      <div class="tabs_container_content p-0" style="margin-left: 0 !important;">
        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab==TAB.PROJECT_DETAILS?'active':''"
          (click)="projectTab=TAB.PROJECT_DETAILS">
          Project Details
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab==TAB.MEMBER?'active':''"
          (click)="projectTab=TAB.MEMBER">
          Members
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab==TAB.CLIENT_DETAILS?'active':''"
          (click)="projectTab=TAB.CLIENT_DETAILS">
          Client Details
          <div class="active_tab_line"></div>
        </span>
      </div>
    </div>

  </div>
</div>
<div style="border-top: 1px solid #E5E7F3; margin-bottom: 36px;"></div>
<div style="padding: 0px 40px 30px 40px;">
  <div class="d-flex flex-column gap-1">

    <div [ngSwitch]="projectTab">
      <ng-container *ngSwitchCase="TAB.PROJECT_DETAILS">
        <div class="row gx-1">
          <div class="col-3 col-lg-2 d-flex justify-content-start align-items-start p-0">
            <div class="project-photo">
              <div class="project-name">{{ project.name | shortName }}</div>
            </div>
          </div>
          <div class="col-9 col-lg-10 p-0">
            <div class="row gx-3 gy-3">
              <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                <div class="custom-input mb-3">
                  <label class="custom-text">Project Name</label>
                  <input class="custom-text" placeholder="Project Name" type="text" autocomplete="off"
                    [(ngModel)]="project.name" />
                </div>
                <div class="custom-input mb-3">
                  <label class="custom-text">Project Address</label>
                  <input (click)="openAddAddress()" class="custom-text pointer" placeholder="Address" type="text"
                    autocomplete="off" [(ngModel)]="projectAddress_line_1_2_city_state" [readonly]="true" />
                </div>
                <div class="custom-input mb-3">
                  <input id="attendance_radius"
                    [ngModel]="project.attendance_radius | dynamicNumber:{fieldType: IPOP_FIELD_TYPE.OtherStatic}"
                    (input)="project.attendance_radius = validatorService.numberFormat($event, {fieldType: IPOP_FIELD_TYPE.OtherStatic, decimal: true, type: 'number'});"
                    #distanceInput type="text" min="100" max="500" placeholder="Enter Distance (m)" class="form-control" />
                  <label for="attendance_radius">Distance (m)</label>
                </div>
              </div>
              <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
                <div class="f-14 datepicker-hide-input calender mb-3 position-relative">
                  <label class="custom-text date-input-label">Start Date</label>
                  <input class="m-0 date-input w-100" tabindex="-1" placeholder="Start Date" [(ngModel)]="model_s_date"
                    ngbDatepicker #s_d="ngbDatepicker" />
                  <div class="pointer ms-3 p-2" style="position: absolute; top: 18%; right: 0;" (click)="s_d.toggle()">
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
    
                <div class="f-14 datepicker-hide-input calender mb-3 position-relative">
                  <label class="custom-text date-input-label">End Date</label>
                  <input class="m-0 date-input w-100" tabindex="-1" placeholder="End Date" [(ngModel)]="model_e_date"
                    ngbDatepicker #e_d="ngbDatepicker" />
                  <div class="pointer ms-3 p-2" style="position: absolute; top: 18%; right: 0;" (click)="e_d.toggle()">
                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
    
                <div class="custom-input mb-3">
                  <label class="custom-text">Project Value</label>
                  <input class="custom-text" type="text" autocomplete="off" [ngModel]="project.estimated_cost"
                    (input)="project.estimated_cost = validatorService.numberFormat($event, {fieldType: IPOP_FIELD_TYPE.Price , digitCountBeforeDecimal:15, type:'number'})" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="TAB.MEMBER"> 
        <app-add-member [project]="project" *ngIf="authService.hasAccess(authService.companyUserPolicy.Add_project_team)"></app-add-member>
      </ng-container>
      <ng-container *ngSwitchCase="TAB.CLIENT_DETAILS">
        <div class="row">
          <div class="col-3 d-flex flex-column justify-content-start align-items-center">
            <div class="project-client-photo">
              <img *ngIf="project.customer_profile_image" [src]="project.customer_profile_image" alt="">
              <div class="upload__wrap">
                <input class="cursor" id="picInput" type="file" [(ngModel)]="tempImg"
                  (change)="replaceImg($event, project, 'customer_profile_image')" hidden />
              </div>
            </div>
            <div class="mt-3">
              <span class="project-img-replace-btn" (click)="selectFileInput()">Replace Image</span>
            </div>
          </div>
          <div class="col-9">
            <div class="row gx-3 gy-3">
              <div class="col-6">
                <div class="custom-input">
                  <label class="custom-text">Client Name</label>
                  <input class="custom-text" type="text" name="clientName" autocomplete="off"
                    [(ngModel)]="project.customer_name" />
                </div>
              </div>
              <div class="col-6">
                <div class="custom-input">
                  <label class="custom-text">Client Mobile Number</label>
                  <input class="custom-text" type="number" name="clientMobile" autocomplete="off"
                    [(ngModel)]="project.customer_contact" />
                </div>
              </div>
              <div class="col-6">
                <div class="custom-input">
                  <label class="custom-text">Client Company Name</label>
                  <input class="custom-text" type="text" name="clientCompanyName" autocomplete="off"
                    [(ngModel)]="project.customer_company_name" />
                </div>
              </div>
              <div class="col-6">
                <div class="custom-input">
                  <label class="custom-text">Company Address</label>
                  <input class="custom-text" type="text" name="clientCompanyAddress" autocomplete="off"
                    [(ngModel)]="project.customer_company_address" />
                </div>
              </div>
              <div class="col-6">
                <div class="custom-input">
                  <label class="custom-text">
                    Company {{ authService.session.country?.tax_display_name ? authService.session.country?.tax_display_name : 'Tax' }} Number
                  </label> 
                  <input class="custom-text" type="text" name="clientCompanyGst" autocomplete="off"
                    [(ngModel)]="project.customer_gst" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    
    </div>
    

    <div *ngIf="projectTab=='projectDetails' || projectTab=='clientDetails'" class="mt-auto d-flex justify-content-between" style="margin-top: 40px !important;">
      <div class="ms-auto align-self-center">
        <button class="btn btn-outline-danger f-12" type="button" (click)="deleteProject()">Delete Project</button>
        <button class="project-btn save" (click)="updateProjectDetails()">Save</button>
      </div>
    </div>
  </div>
</div>