import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';

// <span> {{ any_number | dynamicNumber: {digit: 6, currency: 1, absOff:1, decimalOff:1} }} </span>

// digit: if you need any other decimal point. default -> country config / {digit: 6} 6 digit
// currency: if you need currency sysmbol default -> 0 (off) / {currency: 1} (on)
// absOff: if you need negative value default -> 0 (abs on) / {absOff: 1} (abs off)
// decimalOff: if you need decimal point default -> 0 (off) / {decimalOff: 1} (on)
// you dont need to send default value. Send parameter when you want some thing rather than default

interface Option {
  currency?: 0 | 1;
  absOff?: 0 | 1;
  digit?: number;
  decimalOff?: 0 | 1;
}

@Pipe({
  name: 'dynamicNumber'
})
export class DynamicNumberPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: string | number, options?: Option): string {
    if (typeof value !== 'number' && typeof value !== 'string') {
      return '';
    }

    let amount = Number(value);
    if (isNaN(amount)) {
      return '';
    }

    if (!options?.absOff) {
      amount = Math.abs(amount);
    }

    const defaultDigits = this.authService.session?.country?.decimal_digit || 2;
    const digits = options?.digit && options?.digit > 0 ? options.digit : defaultDigits;

    const fractionDigits = options?.decimalOff ? 0 : digits;

    if (options?.currency === 1) {
      const currencySymbol = this.authService.session?.company?.currency || '₹';
      return (
        currencySymbol +
        ' ' +
        new Intl.NumberFormat(
          currencySymbol === '₹' ? 'en-IN' : undefined,
          { maximumFractionDigits: fractionDigits }
        ).format(amount)
      );
    }

    return amount.toLocaleString(undefined, {
      maximumFractionDigits: fractionDigits,
    });
  }
}
