<div class="custom-modal py-4 w-100 d-flex flex-column gap-2">
    <ngx-loading [show]="uiState == 'loading'" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
    <div *ngIf="uiState == 'selectOrganization'" class="py-4">
        <div class="d-flex justify-content-center align-items-center gap-4">
            <div>
                <span class="f-16 label">Please select organization</span>
                <span class="f-16 label" style="color: red;">*</span>
            </div>
    
            <div ngbDropdown>
                <div ngbDropdownToggle class="form-control input-height d-flex justify-content-center align-items-center gap-1 cursor" style="position: relative; width: fit-content"
                  (click)="
                    showingOrganizationDropDown
                      ? (showingOrganizationDropDown = false)
                      : (showingOrganizationDropDown = true)
                  "
                >
                  <div class="fs-8 align-self-center f-14" style="width: 30dvh;">
                    {{ (!selectedOrganization || selectedOrganization.organization_id == "") ? "--" : selectedOrganization.name }}
                  </div>
                  <div class="align-self-center"></div>
                </div>
                <div
                  style="
                    height: 30dvh;
                    overflow-y: auto;
                    scroll-behavior: smooth;
                    overscroll-behavior: contain;
                  "
                  ngbDropdownMenu
                  class="custom-dropdown-menu"
                >
                  <div
                    ngbDropdownItem
                    class="p-2 pointer d-flex justify-content-between gap-3"
                    *ngFor="let item of organizationList"
                    (click)="setOrganization(item)"
                  >
                    <div class="align-self-center text-right flex-grow-1 fs-8">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <span class="d-flex" style="height: 5dvh;"></span>
        <div class="d-flex align-items-center justify-content-center">
            <button
              class="btn text-white"
              type="button"
              style="background-color: #5f44ce"
              (click)="save()"
            >
              <span class="fw-bold fs-7">
                {{ "Save & Continue" }}
                <i class="bi bi-chevron-right ms-2"></i>
              </span>
            </button>
          </div>
        </div>
    </div>
    <div *ngIf="uiState == 'error'">
        <span class="f-16 label">{{errorMessage}}</span>
        <div class="d-flex align-items-center justify-content-center">
            <button
              class="btn text-white"
              type="button"
              style="background-color: #5f44ce"
              (click)="retry()"
            >
              <span class="fw-bold fs-7">
                {{ "Retry" }}
                <i class="bi bi-chevron-right ms-2"></i>
              </span>
            </button>
          </div>
        </div>