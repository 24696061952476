<div class="p-4" style="height: 80dvh;">
  <div class="d-flex align-items-center gap-3 border-bottom pb-2">
    <div style="padding-bottom: 3px;" (click)="activeModal.dismiss()">
      <ng-icon style="transform: scale(1.3);" name="featherArrowLeft"></ng-icon>
    </div>
    <span class="f-18">Additional Details</span>
  </div>
  <div class="p-4 border-bottom d-flex flex-column gap-5" style="height: calc(100% - 86px); overflow-y: auto;">
    <div class="w-100 h-auto d-flex justify-content-between">
      <app-ship-bill-address-section
        style="width: 40%"
        title="Bill From"
        [selectedAddress]="shipToBillToAddress().monkey_patch_bill_from_address"
        [listTypes]="parent?.fromPage == InvoiceFeatureType.CUSTOMER_INVOICE || 
        parent?.fromPage == InvoiceFeatureType.MATERIAL_SALE ? listTypes : []"
        [addressListObj]="addressListObj"
        (onAddressSelect)="handleAddressSelect($event, 'bill-from')"
      ></app-ship-bill-address-section>
      <app-ship-bill-address-section
        style="width: 40%"
        title="Bill To"
        [selectedAddress]="shipToBillToAddress().monkey_patch_bill_to_address"
        [listTypes]="parent?.fromPage == FEATURE_TYPE.PO || 
        parent?.fromPage == InvoiceFeatureType.PARTY_EARNING || 
        parent?.fromPage == InvoiceFeatureType.MATERIAL || 
        parent?.fromPage ==  InvoiceFeatureType.SUBCON_EXPENSE ? listTypes : []"
        [addressListObj]="addressListObj"
        (onAddressSelect)="handleAddressSelect($event, 'bill-to')"
      ></app-ship-bill-address-section>
    </div>
    <div class="w-100 h-auto d-flex justify-content-between">
      <app-ship-bill-address-section
        style="width: 40%"
        title="Ship From"
        [selectedAddress]="shipToBillToAddress().monkey_patch_ship_from_address"
        [listTypes]="parent?.fromPage == InvoiceFeatureType.CUSTOMER_INVOICE || 
        parent?.fromPage == InvoiceFeatureType.MATERIAL_SALE ? listTypes : []"
        [showCheckbox]="true"
        [toAndFromSame]="shipToBillToAddress().isShipFromSame"
        [addressListObj]="addressListObj"
        (onAddressSelect)="handleAddressSelect($event, 'ship-from')"
        checkboxLabel="Same as Bill From Address"
        (onCheckboxChange)="handleCheckboxChange($event, 'ship-from')"
      ></app-ship-bill-address-section>
      <app-ship-bill-address-section
        style="width: 40%"
        title="Ship To"
        [selectedAddress]="shipToBillToAddress().monkey_patch_ship_to_address"
        [listTypes]="parent?.fromPage == FEATURE_TYPE.PO || 
        parent?.fromPage == InvoiceFeatureType.PARTY_EARNING || 
        parent?.fromPage == InvoiceFeatureType.MATERIAL || 
        parent?.fromPage == InvoiceFeatureType.SUBCON_EXPENSE ? listTypes : []"
        [showCheckbox]="true"
        [toAndFromSame]="shipToBillToAddress().isShipToSame"
        [addressListObj]="addressListObj"
        (onAddressSelect)="handleAddressSelect($event, 'ship-to')"
        checkboxLabel="Same as Bill To Address"
        (onCheckboxChange)="handleCheckboxChange($event, 'ship-to')"
      ></app-ship-bill-address-section>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-end gap-3 pt-2">
    <button class="btn canvas-cancel-btn f-14" (click)="activeModal.dismiss()">Cancel</button>
    <button class="btn canvas-save-btn f-14" (click)="save()">Save</button>
  </div>
</div>
