<app-logo-loader [state]=loading></app-logo-loader>

<div *ngIf="!fileListUrls.length && !parent?.viewOnly" class="upload-images">
  <input (change)="onFileSelected($event)" multiple = "false" type="file" id="file" [accept]="accept || '.jpg, .jpeg, .png, .pdf, .DWG, .dwg, .XLSX, .xlsx, .DOCX, .docx'" />
  <label class="file-input-label" for="file">
    {{hasTitle || 'Upload Bills'}}
    <img class="mx-2" src="../../../../assets/svg/upload.svg" alt="" />
  </label>
</div>
<ng-container *ngIf="fileListUrls.length">
  <div class="vstack">
    <div class="hstack justify-content-between">
      <span class="media-count">Media ({{imageCount}} Images, {{fileCount}} File)</span>
      <div class="sm-upload-images" *ngIf="!parent?.viewOnly">
        <input type="file" (change)="onFileSelected($event)" multiple = "false" id="smFile"
          [accept]="accept || '.jpg, .jpeg, .png, .pdf, .DWG, .dwg, .XLSX, .xlsx, .DOCX, .docx'" />
        <label for="smFile" class="file-sm-input-label">
          {{hasTitle || 'Upload Bills'}}
          <img class="mx-2" src="../../../../assets/svg/upload.svg" alt="" /></label>
      </div>
    </div>
  </div>

  <div class="hstack gap-2 py-1" style="overflow-x: auto; flex-shrink: 0">
    <div *ngFor="let item of fileListUrls; let i=index"
      class="media-box my-1 mx-1 d-flex justify-content-center align-items-center"  (click)="openFile(i)">
      <img class="img-fluid" [src]="fileExtensionExtract(item.url)" alt="" />
      <div *ngIf="!parent?.viewOnly" class="d-flex justify-content-center align-items-center pointer cross-icon" (click)="removeFile(i)">
        <span style="font-size: 10px">╳</span>
      </div>
    </div>
  </div>
</ng-container>
