import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Address } from 'src/app/interfaces/interfaces';
interface AddressObj{
  addressList?: Address[];
  valueStack?: {count: number, next_url: string};
}
interface AddressListObj{
  company?: AddressObj;
  project?: AddressObj;
  person?: AddressObj;
}
@Component({
  selector: 'app-ship-bill-address-section',
  templateUrl: './ship-bill-address-section.component.html',
  styleUrls: ['./ship-bill-address-section.component.css']
})
export class ShipBillAddressSectionComponent {
  @ViewChild('checkbox') checkbox!: ElementRef;
  @Input() title!: string;
  @Input() selectedAddress!: Address;
  @Input() listTypes!: string[];
  @Input() showListType = false;
  @Input() showCheckbox = false;
  @Input() checkboxLabel = '';
  @Input() toAndFromSame = false;
  @Input() addressListObj = {} as AddressListObj;
  @Output() onCheckboxChange = new EventEmitter<boolean>();
  @Output() onAddressSelect = new EventEmitter<Address>();
  public selectedListType: string = 'person';
  constructor(
    public authService: AuthService
  ){}

  ngOnInit(): void{
    if (this.listTypes.length > 0) this.selectedListType = this.listTypes[0];
    else this.selectedListType = 'person';
  }

  handleCheckboxChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.onCheckboxChange.emit(isChecked);
  }

  handleTypeSelect(type: string): void {
    this.selectedListType = type;
  }

  removeAddress(): void {
    this.onAddressSelect.emit({});
    this.selectedAddress = {};
    this.checkbox.nativeElement.checked = false;
  }
}
