import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Address, Company, Country } from 'src/app/interfaces/interfaces';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { ADDRESS_TYPE } from 'src/app/enum/address_type';
import { Observable } from 'rxjs';
import { AddressLocationComponent } from '../modal/address-location/address-location.component';
interface ComponentData {
  address?:Address,
  type: ADDRESS_TYPE,
  party_company_user_id?:string,
  owner_id?: string
}
@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.css']
})
export class AddAddressComponent {
  @Input() componentData = {} as ComponentData;
  public loading = false;
  public company = {} as Company;
  public address = {} as Address;
  public selectingCountry = false;
  public country = {} as Country;
  public countries = [] as Country[];
  public ADDRESS_TYPE = ADDRESS_TYPE;
  constructor(
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private adminService: AdminService,
    public authService: AuthService,
    private notificationServices: NotificationsService,
    private ngbModal: NgbModal
  ) {
    this.company = this.authService.session.company;
    this.country = this.authService.session.country;
    this.address.country_code = this.country.country_code;
  }
  ngOnInit(): void {
    this.getCountryList();
    if(!this.componentData.type) {
      this.notificationServices.initiate({title:'Unable to open address form!', type:toastTypes.error});
      return this.ngbActiveOffcanvas.dismiss();
    }
    if(this.componentData.address){
      this.address = this.componentData.address;
    }
  }
  getCountryList() {
    this.authService.getCountryList().subscribe({
      next: (res) => {
        this.loading = false;
        this.countries = res || [];
        if(this.componentData.address && this.componentData.address.country_code){
          const country = this.countries.find(
            country => country.country_code === this.componentData.address.country_code
          );
          if(country) this.country = country;
        }
      },
      error: (err) => {
        this.loading = false;
        console.log(err);
      },
    });
  }
  cancelBtn() {
    this.ngbActiveOffcanvas.dismiss();
  }
  saveBtn(){
    if(!this.address.address_line_1){
      return this.notificationServices.initiate({title:'Address line 1 can\'t be blank', type:toastTypes.error});
    }
    this.loading = true;
    if(this.address.id){
      this.editAddress();
    }else{
      this.addAddress();
    }
  }
  addAddress(){    
    if (!this.componentData.owner_id) {
      return this.ngbActiveOffcanvas.close(this.address);
    }

    this.address.company_id = this.company.id;
    this.address.owner_id = this.componentData.owner_id;

    let addressObserver: Observable<any>;
    if(this.componentData.type == ADDRESS_TYPE.COMPANY){
      addressObserver = this.adminService.addCompanyAddress(this.address);
    }else if(this.componentData.type == ADDRESS_TYPE.PERSON){
      addressObserver = this.adminService.addCompanyUserAddress(this.address);
    }else if(this.componentData.type == ADDRESS_TYPE.PROJECT){
      this.address.primary = 1;
      addressObserver = this.adminService.addProjectAddress(this.address);
    }

    addressObserver.subscribe({
      next:(res)=>{
        this.notificationServices.initiate({title:'Address Added Successfully', type:toastTypes.success});
        this.ngbActiveOffcanvas.close(res);
      },
      error:(err)=>{
        console.log(err);
        this.loading = false;
        this.notificationServices.initiate({title:'Unable to add', type:toastTypes.error});
      }
    })
  }
  editAddress(){
    this.adminService.editAddress(this.address).subscribe({
      next:(res)=>{
        this.notificationServices.initiate({title:'Address Updated Successfully', type:toastTypes.success});
        this.ngbActiveOffcanvas.close(res);
      },
      error:(err)=>{
        console.log(err);
        this.loading = false;
        this.notificationServices.initiate({title:'Unable to add', type:toastTypes.error});
      }
    })
  }
  setCountry(item: Country) {
    this.country = item || {};
    this.selectingCountry = false;
    this.address.country_code = this.country.country_code;
  }
  
  setProjectLocation(){
    const modalRef = this.ngbModal.open(AddressLocationComponent,{
      size:'lg',
      backdrop:'static',
      backdropClass:'modal-backdropClass-2',
      windowClass: 'modal-windowClass-2'
    })
    modalRef.componentInstance.componentData = {
      address:this.address,
      type:this.componentData.type,
    };
    modalRef.result.then((result:Address) => {
      if (!result) return;
      // Preserve user-entered values if already filled
      this.address = {
        ...this.address, // Keep existing values
        google_address: result.google_address, // Always update Google address
        location: result.location, // Always update location
        address_line_1: this.address.address_line_1 || result.address_line_1,
        city: this.address.city || result.city,
        state: this.address.state || result.state,
        postal_code: this.address.postal_code || result.postal_code,
        country_code: this.address.country_code || result.country_code,
      };
    }, (reject)=>{
      //
    })
  }
  resetGeoAddress() {
    this.address.google_address = '';
    this.address.location = { type: 'Point', coordinates: [1.1, 1.1] };
  }
}
