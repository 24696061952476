import { Component, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Cashbook, Company, Member, Project } from 'src/app/interfaces/interfaces';
import { UtilService } from '../../services/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CompanyService } from 'src/app/company/services/company.service';
import { HttpParams } from '@angular/common/http';
import { SettingProjectComponent } from '../modal/setting-project/setting-project.component';
import { ConfirmModalComponent } from '../modal/confirm-modal/confirm-modal.component';
import { SettingWarehouseComponent } from '../modal/setting-warehouse/setting-warehouse.component';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.css']
})
export class ProjectHeaderComponent {
  @Input() fromModule: 'project' | 'warehouse';
  public projectSetting = false;

  public project = {} as Project;
  public company = {} as Company;
  public cashbook = [] as Cashbook;
  public member = [] as Member[];
  public memberCount = 0;
  isLargeScreen = false;
  public windowWidth:number = window.innerWidth;
  public status={class:"circle-dot", text:"NA"}
  constructor(
    public authService: AuthService,
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    public modalService: NgbModal,
    private router: Router,
    private utilServices: UtilService,
    private responsive: ResponsiveService,
    private breakpointObserver: BreakpointObserver,
    private companyService:CompanyService,
    private notificationsService:NotificationsService
  ) {
    //
  }
  ngOnInit() {

    this.activatedRoute.data.subscribe((data) => {
      if(this.fromModule === 'project'){
        this.project = data.project as Project;
      }else if(this.fromModule === 'warehouse') {
        this.project = data.warehouse
      }
      
    });

    this.utilServices.memberCountObservable.subscribe(count => {
      this.memberCount = count;
    });
    this.company = this.authService.session.company;
    this.companyService.checkCompanySubscription();

    this.getMember(this.project.id);
    if (window.innerWidth >= 768) {
      this.isLargeScreen = true;
    }
    if (window.innerWidth <= 768) {
      this.isLargeScreen = false;
    }
    this.updateStatus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    if (event.target.innerWidth >= 992 || event.target.innerWidth <= 992) {
      this.breakpointObserver.observe(['(max-width: 992px)']).subscribe((state: BreakpointState) => {
        if (state.matches) {
          // Do something if the viewport width is greater than or equal to 768 pixels
          this.isLargeScreen = false
        } else {
          // Do something if the viewport width is less than 768 pixels
          this.isLargeScreen = true
        }
      });
    }
  }

  updateStatus(){
    switch(this.authService.session.project.status){
      case "Complete":
        this.status.text = this.authService.session.project.status;
        this.status.class = ""
        break
        case "NotStarted":
          this.status.text = "Not Started";
          this.status.class = "not-started-btn"
          break;
          case "OnHold":
            this.status.text = "On Hold";
            this.status.class = "onhold-btn"
            break;
            case "Ongoing":
              this.status.text = "Ongoing";
              this.status.class = "ongoing-btn"
              break;
    }
  }
  getRole(company: Company) {
    this.authService.session.profile.monkey_patch_company_user = this.authService.session.profile.monkey_patch_company_user || [];
    for (const compuser of this.authService.session.profile.monkey_patch_company_user) {
      if (compuser.company_id === company.id) {
        return compuser.role == 'admin' ? 'Admin' : 'Member' || '';
      }
    }
    return 'Member'
  }

  getMember(project_id: string) {
    if (!this.authService.hasAccess(this.authService.companyUserPolicy.Add_project_team)){
      this.notificationsService.initiate({ title: "You don't have access", type: toastTypes.error });
      return
    }
    let qp = new HttpParams();
    qp = qp.set("project_id", project_id);
    this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      this.member = res.data || ([] as Member);
      this.memberCount = this.member.length;
    });
  }

  openSettings(item: any) {
    if(item == 'member' && !this.authService.hasAccess(this.authService.companyUserPolicy.Add_project_team)){
      this.notificationsService.initiate({ title: "You don't have access", type: toastTypes.error });
      return;
    }
    const teamModal = this.modalService.open(this.fromModule == 'warehouse' ? SettingWarehouseComponent : SettingProjectComponent, { size: 'lg', backdrop: 'static',backdropClass:'sales-invoice-modal-backdropClass', windowClass: 'sales-invoice-modal-windowClass'});
    teamModal.componentInstance.componentData = { 'tab': item }
  }

  deleteProject() {
    const deleteConfirmation = this.modalService.open(ConfirmModalComponent);
    deleteConfirmation.componentInstance.data = { modalTitle: 'Are you sure?', modalContent: 'After deleting project you may lose your data.' }
    deleteConfirmation.result.then(onfulfilled => {
      this.adminService.deleteProject(this.project.id).subscribe(res => {
        this.router.navigate(["/../company/dashboard/home"]);
      }, err => console.log(err))
    }, onReject => console.log('Delete rejected by user' + onReject))

  }

  openSmallSidebar() {
    this.responsive.setSmallSidebarStatus(true);
  }

  changeProjectStatus(id:string, status: "NotStarted"|"Ongoing"|"OnHold"|"Complete"){
    this.adminService.editProjectStatus({id, status}).subscribe({
      next:(res)=>{
        this.authService.session.project.status = status;
        this.updateStatus();
      },
      error:()=>{}
    })
  }

}
