<div class="canvas-height" >
  <app-logo-loader [state]="loading"></app-logo-loader>
  <div class="offcanvas-header d-flex align-items-center px-4">
    <div class="canvas-header-left d-flex align-items-center gap-4">
      <app-back-button></app-back-button>
      <div class="canvas-title d-flex flex-column justify-content-start align-items-start">
        <span
          style="font-weight: 700; font-size: 14px; line-height: 21px"
          >Debit Note</span
        >
        <span class="f-14 disabled-font">Entry by {{debitNote?.monkey_patch_creator_company_user?.name}}</span>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="canvas-cancel-btn" (click)="delete()">
        Delete
      </div>
      <div
        class="canvas-save-btn"
        (click)="edit()"
      >
        Edit
      </div>
    </div>
  </div>

  <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>

  <div
    style="overflow-y: auto" class="canvas-body px-4 py-2"
  >
    <div class="d-flex justify-content-start align-self-center gap-3 my-3">
      <div style="flex-shrink: 0;" class="date-card ">
        <div style="background-color: #52487C" class="date-card-header sales-invoice">

          {{debitNote?.invoice_date | date : "dd MMM" }}
        </div>
        <div class="date-card-body" style="background-color: #E6E7FB">
          <span>
            <img src="../../../../assets/svg/payment-partyearing.svg" />
          </span>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex gap-2 ">
          <span class="f-14">Client:</span>
          <span class="f-14" style="font-weight: 600;">{{debitNote?.monkey_patch_party_company_user?.name}}</span>
        </div>
        <div style="color: #7f8296;" class="f-14">
          <span>Project:</span> <span> {{debitNote.monkey_patch_project?.name}}</span>
        </div>
        <div *ngIf="debitNote.reference_number" style="color: #7f8296;" class="f-14">
          <span>Reference No: </span> <span>#{{debitNote.reference_number}}</span>
        </div>
        <div *ngIf="debitNote.notes" style="color: #7f8296;" class="f-14">
          <span>Note: </span> <span>{{debitNote.notes}}</span>
        </div>
      </div>
    </div>
    <ng-container *ngIf="debitNoteItems.length > 0">
      <div class="invoice-table-header row">
        <div class="col-6 d-flex justify-content-start">
          <span style="color: #7f8296;" class="f-14">Item</span>
        </div>
        <div class="col-6 d-flex justify-content-between">
          <span style="color: #7f8296;" class="f-14">Qty.</span>
          <span style="color: #7f8296;" class="f-14">Amt.</span>
        </div>
      </div>
      <div *ngFor="let item of debitNoteItems" class="invoice-table-body row">
        <div class="col-6 d-flex justify-content-start">
          <div class="d-flex flex-column align-items-start">
            <span class="f-14">{{item?.name}}</span>
            <span style="color: #7f8296" class="f-14"> {{item?.unit_price | dynamicNumber: {currency:1,fieldType:IPOP_FIELD_TYPE.UnitRate} }}/{{item?.unit}}</span>
          </div>
        </div>
        <div class="col-6 d-flex align-items-center justify-content-between">
          <span class="f-14">{{item?.quantity | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Quantity} }}</span>

          <div class="d-flex flex-column align-items-end">
            <span class="f-14">{{item?.work_amount | dynamicNumber:{fieldType:IPOP_FIELD_TYPE.Price} }}</span>
            <span style="color: #7f8296" class="f-14">+ {{item.gst_percent}}% {{authService.session.country.tax_value_display_name?authService.session.country.tax_value_display_name:'Tax'}}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="debitNoteItems.length > 0"  class="invoice-sub-total mx-2">
      <span class="f-14">Sub Total</span>
      <span class="f-14">{{debitNote?.work_amount | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
    </div>

    <div *ngIf="debitNoteItems.length > 0"  class="other-carges d-flex flex-column gap-2 py-3 mx-2">
      <ng-container *ngIf="authService.session.country?.tax_value_display_name === 'GST'" >
        <div class="d-flex justify-content-between" *ngIf="!shouldSplitGst; else sameState">
          <span class="f-14" style="font-weight: 400;">IGST</span>
          <span class="f-14" style="font-weight: 400;">{{debitNote?.gst_amount | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
        </div>
        
        <ng-template #sameState>
          <div class="d-flex justify-content-between">
            <span class="f-14" style="font-weight: 400;">CGST</span>
            <span class="f-14" style="font-weight: 400;">{{debitNote?.gst_amount/2 | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
          </div>
          <div class="d-flex justify-content-between">
            <span class="f-14" style="font-weight: 400;">SGST</span>
            <span class="f-14" style="font-weight: 400;">{{debitNote?.gst_amount/2 | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
          </div>
        </ng-template>
      </ng-container>

      <div *ngIf="authService.session.country.tax_value_display_name !== 'GST'" class="d-flex justify-content-between mb-2">
        <span class="f-14" style="font-weight: 400;">{{authService.session.country.tax_value_display_name}}</span>
        <span class="f-14" style="font-weight: 400;">{{debitNote?.gst_amount | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
      </div>


    </div>

    <div class="inv-total mx-2">
      <span class="f-14">Total Amount</span>
      <span class="f-14">{{debitNote?.total_amount | dynamicNumber: {fieldType:IPOP_FIELD_TYPE.Price, currency:1} }}</span>
    </div>

    <app-attachment-view
      #child
      [parent]="{ viewOnly: true }"
    ></app-attachment-view>
  </div>
  <app-canvas-bottom-bar [approval_flag]="debitNote.approval_flag" [transactionType]="'invoice'"
    [id]="debitNote?.id" [approved_by]="debitNote?.monkey_patch_approved_by?.name" [show_more_option]="true"
    [approval_comment]="debitNote?.approval_comment"
    [is_editable]="debitNote.monkey_patch_status=='unpaid' || !debitNote.monkey_patch_status ? true:false"
    (isDirty)="ngbActiveOffcanvas.close()"
    [invoice_feature_type]="debitNote?.monkey_patch_invoice?.feature_type"
  ></app-canvas-bottom-bar>
</div>
