import { HttpParams } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveOffcanvas, NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SelectCostCodeComponent } from 'src/app/company-library/components/company-trade-library/components/select-cost-code/select-cost-code.component';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Category, DebitNoteItem, Subcategory } from 'src/app/interfaces/interfaces';
import { ConfirmModalComponent } from 'src/app/shared/components/modal/confirm-modal/confirm-modal.component';
import { UtilService } from 'src/app/shared/services/util.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
export interface Parent {
  debit_note_item?: DebitNoteItem;
}
@Component({
  selector: 'app-add-debite-note-item',
  templateUrl: './add-debite-note-item.component.html',
  styleUrls: ['./add-debite-note-item.component.css']
})
export class AddDebiteNoteItemComponent {
  @Input() parent = {} as Parent;
  addHSN = false;
  addDescription = false;
  // addTrade = false;
  addTotalEstimatedQty = false;
  subCategory = {} as Subcategory;
  public gst = [] as any;
  public subCategories = [] as Subcategory[];
  public categories = [] as Category[];
  public debitNoteItem = {} as DebitNoteItem;
    constructor(
    private adminService: AdminService,
    private ngbOffcanvas: NgbOffcanvas,
    public authService: AuthService,
    private ngbActiveOffcanvas: NgbActiveOffcanvas,
    private utilService:UtilService,
    public validatorService:ValidatorService,
    private ngbModal: NgbModal
  ) {
    
  }
  ngOnInit(): void {
    this.debitNoteItem = this.parent.debit_note_item || {};
    this.utilService.getGstPercentage().then(res => {
      this.gst = res || [];
      if(!this.parent?.debit_note_item?.gst_percent){
        this.debitNoteItem.gst_percent = parseFloat(this.gst[0]) || 0;
      }
      if(this.debitNoteItem.notes) this.addDescription = true;
      if(this.debitNoteItem.hsn_code) this.addHSN = true;
      // if(this.debitNoteItem.sub_category_id) this.addTrade = true;
    }, err => {
      console.error(err)
    })
    this.getSubcategory();
    this.getUnit_cat();
  }
  getUnit_cat() {
    let qp = new HttpParams();
    qp = qp.set('type', 'billing_unit')
    this.adminService.categoryList(qp).subscribe({
      next: (res) => {
        this.categories = res.categories || [] as Category[];
        if(this.parent.debit_note_item == undefined){
          this.debitNoteItem.unit_id = this.categories[0]?.id;
          this.debitNoteItem.unit = this.categories[0]?.text_en;
        }
      }
    })
  }
  getSubcategory() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.authService.session.company.id);
    this.adminService.subcategoryList(qp).subscribe((res) =>{
      this.subCategories = res.subcategories || [] as Subcategory;
      if(this.debitNoteItem.sub_category_id){
        for (let i = 0; i < this.subCategories.length; i++) {
          if(this.subCategories[i].id === this.debitNoteItem.sub_category_id){
            this.subCategory.text_en = this.subCategories[i].text_en;
            break;
          }
        }
      }
    });
  }
  setActivityUnit(e) {
    const cat_id = e.target.value || {};
    for (const cat of this.categories) {
      if (cat.id === cat_id) {
        this.debitNoteItem.unit_id = cat.id;
        this.debitNoteItem.unit = cat.text_en;
        break;
      }
    }
  }
  setActivityGst(e) {
    const gst_id = e.target.value || {};
    this.debitNoteItem.gst_percent = parseFloat(gst_id);
  }
  saveBtnClicked() {
    console.log(this.debitNoteItem)
    this.ngbActiveOffcanvas.close(this.debitNoteItem);
  }
  deleteBtnClicked(){
      const confirmModal = this.ngbModal.open(ConfirmModalComponent);
      confirmModal.componentInstance.data = { modalTitle: 'Delete Confirmation', modalContent: 'Are you sure?' };
      confirmModal.result.then(onfulfiled => {
        this.debitNoteItem.delete = 1;
        this.ngbActiveOffcanvas.close(this.debitNoteItem);
      }, onrejected => console.log(onrejected));
  }
  openCostCodeCanvas(sub_category_id?: string) {
    const ngbOffcanvas = this.ngbOffcanvas.open(SelectCostCodeComponent, {
      position: 'end',
      backdrop: 'static',
      panelClass: 'offcanvas-sm'
    })

    ngbOffcanvas.componentInstance.parent = {
      sub_category_id: this.debitNoteItem.sub_category_id || '',
      is_add_new: true, height: 1
    }

    ngbOffcanvas.result.then(onfilled => {
      this.subCategory.text_en = onfilled.sub_cat_text_en || '';
      this.debitNoteItem.sub_category_id = onfilled.sub_category_id || '';
    }, onReject => {
      //
    })
  }
}
