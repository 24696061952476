import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportModalComponent } from '../modal/report-modal/report-modal.component';
import { REPORT_FEATURE_TYPE } from 'src/app/enum/report_feature';
@Component({
  selector: 'app-download-report-button',
  templateUrl: './download-report-button.component.html',
  styleUrls: ['./download-report-button.component.css']
})
export class DownloadReportButtonComponent implements OnInit {
@Input() parent = {} as {type:REPORT_FEATURE_TYPE, queryParams:({key:string, value:string})[]};
  constructor(
    public modalService :NgbModal
  ) { }

  ngOnInit(): void {
    //
  }

  downloadReport(){
    const reportModal = this.modalService.open(ReportModalComponent, { centered: true, backdrop: 'static', size: '1000px' });
    reportModal.componentInstance.parent = this.parent;
  }
}
