import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { IPOP_FIELD_TYPE } from 'src/app/enum/ipop-field-type-enum';
import { BillingActivity, Project } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent  {

  private progressOrderId:string;
  public loading = false;
  public busy = false;
  private project = {} as Project;
  public valueStack = { page: { next_url: '' } };
  public progress = [] as BillingActivity[];
  public IPOP_FIELD_TYPE = IPOP_FIELD_TYPE;
  constructor(
    private adminService:AdminService,
    public authService:AuthService,
    private utilService:UtilService
  ){
    this.project = this.authService.session.project;
  }
 
  


  ngOnInit(): void {
    this.getProgressOrder();
  }

  getProgressOrder(){
    this.adminService.getProgressOrder(this.project.id).subscribe({
      next:res=>{
        this.valueStack = res;
       this.progressOrderId = res?.id;
       sessionStorage.setItem('progress-workorer-id', this.progressOrderId)
       
       this.getTaskList();
       
      },
      error:err=>{
        console.log('err', err)
      }
    })
  }



  getTaskList(){
    this.loading = true;
    let qp = new HttpParams();
    qp = qp.set('workorder_id', this.progressOrderId)
     
    this.adminService.getProgressTaskList(qp).subscribe({
      next:res=>{
       this.valueStack = res;
       this.progress = res.data;
       this.loading = false
      },
      error:err=>{
        console.log('err', err)
      }
    })
  }

  onScroll() {
    const url = this.valueStack.page.next_url;
    if (!this.busy) {
     
      this.busy = true;
      this.utilService.getByUrl(url).subscribe(res => {
        this.valueStack = res;
        this.progress = this.progress.concat(res?.data);
        this.busy = false;
      }, err => {
       
        this.busy = false;
        this.valueStack = { page: { next_url: '' } };
      });
    }
  }

}
