import { Component } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { AdminService, AdminService as AdminServiceCore } from 'src/app/core/services/admin.service';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ThirdPartyAuthDataEditConfig, ZohoOrganization } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'zoho-redirect-url',
  templateUrl: './zoho-redirect-url.component.html',
  styleUrls: ['./zoho-redirect-url.component.css']
})
export class ZohoRedirectUrlComponent {
  public callBackUrl = "";
  public selectedOrganization: ZohoOrganization;
  public uiState = "selectOrganization";
  public errorMessage = "";
  public organizationList = [];
  public showingOrganizationDropDown = false;
  public authData;
  private company_id: string;
  constructor(
    public authService: AuthService,
    private adminService:AdminService,
    public validatorService: ValidatorService,
    private router: Router,
  ){}

  ngOnInit(): void {
    this.company_id = this.authService.session.company.id;
    this.callBackUrl = window.location.href;
    this.generateZohoTokens();
  }

  setOrganization(organization) {
    this.selectedOrganization = organization
  }

  generateZohoTokens() {
    this.uiState = "loading";
    let params = new HttpParams();
    params = params.append('company_id', this.company_id);
    params = params.append('callback_url', this.callBackUrl);
    const service = this.adminService;
    service.generateZohoTokens(params).subscribe({
      next:(res) => {
        this.uiState = "";
        if (res.message == "success") {
          this.authData = res.data;
          this.fetchOrganizationList();
        }
      },
      error:(err)=>{
        this.uiState = "error";
        this.errorMessage = "Somrthing went wrong!";
        console.error(err);
      }
    }); 
  }

  fetchOrganizationList() {
    this.uiState = "loading";
    let params = new HttpParams();
    params = params.append('company_id', this.company_id);
    const service = this.adminService;
    service.getZohoOrganizations(params).subscribe({
      next:(res) => {
        this.organizationList = res.data;
        if (!this.organizationList || this.organizationList.length == 0) {
          this.uiState = "error";
        } else if (this.organizationList.length == 1) {
          this.uiState = "";
          this.selectedOrganization = this.organizationList[0];
          this.uiState = "selectOrganization";
          this.save();
        } else {
          this.uiState = "selectOrganization";
        }
      },
      error:(err)=>{
        this.uiState = "error";
        this.errorMessage = "Somrthing went wrong!";
        console.error(err);
      }
    }); 
  }

  openToThirdPartyIntegration() {
    // add token
    this.router.navigate([`/c/${this.authService.session.company?.id}/d/company-settings/third-party-integration`]);
  }

  retry() {
    if (!this.authData) {
      this.generateZohoTokens();
    } else if (!this.organizationList || this.organizationList.length == 0) {
      this.fetchOrganizationList();
    }
  }

  save() {
    if (this.selectedOrganization) {
      this.uiState = "loading";
      let data = {} as ThirdPartyAuthDataEditConfig;
      data.id = this.authData.id;
      data.company_id = this.company_id;
      data.configurations = {
        "primary_organization_id" : this.selectedOrganization.organization_id,
        "primary_organization_name" : this.selectedOrganization.name
      };
      const service = this.adminService;
      service.updateThirdPartyAppConfig(data).subscribe({
        next:(res) => {
          this.uiState = "";
          this.openToThirdPartyIntegration();
        },
        error:(err)=>{
          this.uiState = "selectOrganization";
          console.error(err);
        }
      }); 
    }
  }
}
