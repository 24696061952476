import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { Profile } from 'src/app/interfaces/interfaces';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Input() config: any = {} as any;
  public primaryProfile = {} as Profile;
  constructor(
    public authService: AuthService,
    private router: Router,
    public utilService: UtilService,
    private localStorageService:LocalStorageService
  ) {}

  ngOnInit() {
    this.primaryProfile = this.authService.getPrimaryProfile() as Profile || {} as Profile;
    // console.log(this.authService.session.profile, 'profile')
  }
  logout() {
    this.authService.logout().then(() => {
      this.router.navigate(['/auth']);
    });
  }

  switchAccount() {
    const primaryToken = this.authService.getPrimaryToken();
    this.localStorageService.clearAll();
    sessionStorage.clear();
    this.authService.saveToken(primaryToken);
    this.authService.session.token = primaryToken;
    this.authService.session.isActive = true;
    this.authService.session.profile = this.primaryProfile as Profile;
    this.authService.saveProfile(this.authService.session.profile);
    this.navigateToCompany();
  }

  toggleAccess(){
    this.authService.toggleAccess().subscribe({
      next: res=>{
        this.authService.session.profile.is_access_granted = res.is_access_granted;
        this.authService.saveProfile(this.authService.session.profile);
        if(this.primaryProfile.id == this.authService.session.profile.id){
          this.primaryProfile = this.authService.session.profile;
          this.authService.savePrimaryProfile(this.authService.session.profile);
        }
      },
      error: err=>{
        console.log(err);
      }
    })
  }

  navigateToCompany() {
    let companyId = this.authService.session.profile.current_company_id;
    if (!companyId) {
      if (this.authService.session.profile.monkey_patch_company_user.length > 0) {
        companyId = this.authService.session.profile.monkey_patch_company_user[0].company_id;
      }
    }
    if (companyId) {
      this.router.navigate([`/c/${companyId}/d/home`]).then(ful=>{
        location.reload();
      })
    }
  }
}
