/**
 * Created by tarun on 14/7/17.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from '../../config/config.config';

import {CompanyFileUploadOptions, FileUploadOptions} from 'src/app/interfaces/interfaces';
import { LocalStorageService } from './local-storage-service.service';
import { Router } from '@angular/router';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public digitCount = 2;
  public editorConfig = {} as any;
  public editorConfigInline = {} as any;
  public editorConfigHindi = {} as any;

  private partyCompanyUserId = new BehaviorSubject('');
  public partyIdObservable = this.partyCompanyUserId.asObservable();

  private projectId = new BehaviorSubject('');
  public projectIdObservable = this.projectId.asObservable();

  private taskId = new BehaviorSubject('');
  public taskIdObservable = this.taskId.asObservable();

  public memberCount = new BehaviorSubject(0);
  public memberCountObservable = this.memberCount.asObservable();

  constructor(
    private http: HttpClient,
    private config: Config,
    private ngbModal: NgbModal,
    private localStorageService: LocalStorageService,
    private router: Router,
    private notificationService: NotificationsService
  ) {
    this.digitCount = localStorageService.get('country')?.decimal_digit || 2;
  }

  getByUrl(url): Observable<any> {
    return this.http.get(
      url,
      {}
    );
  }

  s3attachAnyFIle(fileUploadOptions: FileUploadOptions): Observable<any> {
    const fileList: FileList = fileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', fileUploadOptions.contentType);
    formData.append('folder', fileUploadOptions.folder);
    formData.append('extension', fileUploadOptions.extension);
    formData.append('project_id', fileUploadOptions.project_id);
    formData.append('task_id', fileUploadOptions.task_id);
    formData.append('expense_id', fileUploadOptions.expense_id);
    formData.append('material_id', fileUploadOptions.material_id);
    formData.append('drawing_id', fileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload`,
      formData,
      {}
    );
  }

  s3attachCompanyFIle(companyFileUploadOptions: CompanyFileUploadOptions): Observable<any> {
    const fileList: FileList = companyFileUploadOptions.files;
    const file: File = fileList[0];
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('content_type', companyFileUploadOptions.contentType);
    formData.append('parent_dir', companyFileUploadOptions.parent_dir);
    formData.append('name', companyFileUploadOptions.name)
    formData.append('extension', companyFileUploadOptions.extension);
    formData.append('project_id', companyFileUploadOptions.project_id);
    formData.append('task_id', companyFileUploadOptions.task_id);
    formData.append('expense_id', companyFileUploadOptions.expense_id);
    formData.append('material_id', companyFileUploadOptions.material_id);
    formData.append('drawing_id', companyFileUploadOptions.drawing_id);
    return this.http.post(
      `${this.config.userEndpoint}/upload/companyfile`,
      formData,
      {}
    );
  }

  sendPartyId(partyCompanyUserId: string) {
    this.partyCompanyUserId.next(partyCompanyUserId);
  }

  sendProjectId(projectId: string) {
    this.projectId.next(projectId);
  }

  sendTaskId(taskId: string) {
    this.taskId.next(taskId);
  }

  public fileExtension = new Map([
    ['DWG','dwg.svg'],
    ['dwg','dwg.svg'],
    ['pdf','pdf.svg'],
    ['PDF','pdf.svg'],
    ['docx','docx.svg'],
    ['DOCX','docx.svg'],
    ['xlsx','xlsx.svg'],
    ['XLSX','xlsx.svg'],
  ])

  fileExtensionExtract(fileName:string):{file:string, isImage:boolean}{
   const nameArr = fileName.split('.') || [];
    const extn = nameArr[nameArr.length-1];
    if(this.fileExtension.has(extn)){
      return {file:this.fileExtension.get(extn), isImage:false}
    }else{
      return {file:fileName, isImage:true}
    }
  }

  getGstPercentage() {
    return new Promise((resolve, reject) => {
    try {
      const country = this.localStorageService.get('country');
      const taxSlabArray = country?.tax_slabs || [];
      if (Array.isArray(taxSlabArray)) {
        resolve(taxSlabArray);
      } else {
        reject(new Error('Data in localStorage is not an array.'));
      }
    } catch (error) {
      reject(error);
    }
    });
  }

  isSameStateGst(companyGst: string, partyGst: string): boolean {
    try{
      const str1Lower = companyGst.toLowerCase();
      const str2Lower = partyGst.toLowerCase();
      if(str1Lower == "" || str2Lower == "") throw new Error()
      return str1Lower.substring(0, 2) === str2Lower.substring(0, 2);
    }catch(e){
      return true
    }
  }

  copyText(text: string) {
    navigator.clipboard.writeText(text).then(() => {
      this.notificationService.initiate({ title: 'Copied to clipboard!', type: toastTypes.success });
      console.log('Copied to clipboard!');
    }).catch(err => {
      this.notificationService.initiate({ title: 'Failed to copy!', type: toastTypes.error });
    });
  }
}