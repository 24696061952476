import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CompanyRole, CompanyUser, FileUploadOptions, Member, Project } from 'src/app/interfaces/interfaces';
import { UtilService } from 'src/app/shared/services/util.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ImgEditModalComponent } from '../img-edit-modal/img-edit-modal.component';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { Title } from '@angular/platform-browser';
import { ValidatorService } from 'src/app/shared/services/validator.service';
import { AddProjectLocationComponent } from '../add-project-location/add-project-location.component';


@Component({
  selector: 'app-setting-project',
  templateUrl: './setting-project.component.html',
  styleUrls: ['./setting-project.component.css'],
})
export class SettingProjectComponent implements OnInit, OnDestroy {
  @Input() data;
  public tempImg;
  public projectTab = 'projectDetails';
  public loading = false;
  public companySearchBox = false;
  public manageAccessOptionsShow = -1;
  newManageAccessOptionsShow = false;
  public accessBtnActive = '';
  public accessBox = false;
  public members = [] as Member[];
  public member = {} as Member;
  public project = {} as Project;
  public companyUsers = [] as CompanyUser[];
  public companyUser = {} as CompanyUser;
  public projectStatusBox = false;
  public companyRoles = [] as CompanyRole[];
  public objectKeys = Object.keys;
  model_s_date: NgbDateStruct;
  model_e_date: NgbDateStruct;
  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public authService: AuthService,
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
    public utilService: UtilService,
    private router: Router,
    private notificationsService: NotificationsService,
    public title: Title,
    public validatorService: ValidatorService,
    private ngbModal: NgbModal
  ) {
    title.setTitle("Project Setting");
    this.project = JSON.parse(JSON.stringify(authService.session.project));
    this.getMemberList(this.project.id);
    this.getCompanyRoles();
    this.getCompanyUserList();
  }

  ngOnInit(): void {
    this.getProjectDetails();
    this.data.fromPage ? this.projectTab = this.data.fromPage : this.projectTab = 'projectSetting';
    this.model_s_date = this.date_to_ngb_model(this.project.start_date);
    this.model_e_date = this.date_to_ngb_model(this.project.end_date);
  }
  ngOnDestroy(): void {
    this.localStorageService.remove('companyUserList');
  }

  close() {
    this.activeModal.close(this.members);
  }

  setFeatures(value: any, e: Event) {
    e.stopPropagation();
  }
  getMemberList(project_id: string) {
    let qp = new HttpParams();
    qp = qp.set('project_id', project_id);
    this.authService.getStaffMemberAdmin(qp).subscribe((res) => {
      this.members = res.data || ([] as Member);
    });
  }

  setMemberAccess(role: CompanyRole) {
    this.patchCompanyUser(this.companyUser.id, role.id)
  }

  deleteTeamMember() {
    const deleteModal = this.modalService.open(ConfirmModalComponent);
    deleteModal.componentInstance.data = {
      modalTitle: 'Remove member',
      modalContent: 'Are you sure?',
    };
    deleteModal.result.then(
      (onfulfilled) => {
        this.adminService.deleteTeamMember(this.member.id).subscribe((res) => {
          const member_index = this.members.findIndex((m) => m.id === res.id);
          this.members.splice(member_index, 1);
          this.accessBox = false;
          this.companyUser = {};
          this.member = {};
          this.utilService.memberCount.next(this.members.length);
        }, err => console.log(err), () => {
          this.member = {};
        });
      },
      (onrejected) => this.member = {}
    );
  }

  getCompanyUserList() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    this.adminService.getCompnayUserList(qp).subscribe((res) => {
      this.companyUsers = res.data || ([] as CompanyUser[]);
      this.localStorageService.set('companyUserList', this.companyUsers);
      this.companySearchBox = true;
    });
  }

  openAccessBox(item: Member) {
    if (this.project.contractor_company_user_id == item.company_user_id) {
      this.notificationsService.initiate({ title: 'Not allowed', type: toastTypes.error });
      return
    }
    const index = this.companyUsers.findIndex(i => i.id == item.company_user_id);
    this.companyUser = this.companyUsers[index] || {} as CompanyUser;
    if(this.authService.session.profile.id == this.companyUser.user_id){
      this.notificationsService.initiate({ title: 'You can not change your role', type: toastTypes.error });
      return
    }
    this.accessBox = true;
    return this.member = item;
  }

  getProjectDetails() {
    //
  }
  updateProjectDetails() {
    const tempData = JSON.parse(JSON.stringify(this.project));
    // this.project.start_date = new Date(this.project.start_date);

    if(this.model_s_date){
      tempData.start_date = this.validatorService.dateToUtc(this.model_s_date.day, this.model_s_date.month, this.model_s_date.year, 0, 0, 0, 1);
    }
    if(this.model_e_date){
      tempData.end_date = this.validatorService.dateToUtc(this.model_e_date?.day, this.model_e_date?.month, this.model_e_date?.year, 23, 59, 59, 998);
    }
    this.adminService.editProject(tempData).subscribe(
      {
        next: (res) => {
          if(this.authService.session.project.estimated_cost !== this.project.estimated_cost){
            this.authService.saveProject(res);
            this.authService.projectBehaviour.next(res);
          }else{
            this.authService.saveProject(res);
          }
          this.notificationsService.initiate({ title: 'Project update successfully', type: toastTypes.success });
          this.activeModal.close();
        },
        error: (err) => {
          this.notificationsService.initiate({ title: 'Project update error', type: toastTypes.error });
        }
      })
  }

  replaceImg(event, model, key) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      const fileName = files[0].name;
      const fileType = files[0].type;
      const extn = fileName.substring(fileName.lastIndexOf('.'));
      const fileReader = new FileReader();
      fileReader.onload = (fileLoadedEvent) => {
        const target = fileLoadedEvent.target as { result?: string };
        const modalRef = this.modalService.open(ImgEditModalComponent, { backdrop: 'static' });
        modalRef.componentInstance.data = target.result;
        modalRef.result.then(data => {
          const newfile = new File([data.blob], fileName, { type: fileType, lastModified: Date.now() });
          const fileUploadOptions = {
            files: [newfile],
            contentType: fileType,
            extension: extn,
            folder: 'profile'
          } as FileUploadOptions;
          this.utilService.s3attachAnyFIle(fileUploadOptions).subscribe(res => {
            this.loading = false;
            this.project.customer_profile_image = res.url;
            // this.authService.editProfile(model).subscribe(profile => {
            //   this.profile = profile as Profile;
            //   this.authService.saveProfile(this.profile);
            //   location.reload();
            // });
          }, err => {
            this.loading = false;
          });
        }).catch(err => {
          location.reload();
        });
      };
      fileReader.readAsDataURL(files[0]);
    }
  }
  selectFileInput() {
    document.getElementById('picInput').click();
  }


  deleteProject() {
    const deleteConfirmation = this.modalService.open(ConfirmModalComponent);
    deleteConfirmation.componentInstance.data = { modalTitle: 'Are you sure?', modalContent: 'After deleting project you may lose your data.' }
    deleteConfirmation.result.then(onfulfilled => {
      this.adminService.deleteProject(this.project.id).subscribe(res => {
        this.activeModal.dismiss();
        this.router.navigate(["/../company/dashboard/home"]);
      }, err => console.log(err))
    }, onReject => console.log('Delete rejected by user' + onReject))

  }


  changeTeamMemberRole() {
    //
  }




  setCompanyUser(companyUser?: CompanyUser) {
    if (companyUser) {
      this.companyUser = companyUser;
      for (const mem of this.members) {
        if (mem.company_user_id == companyUser.id) {
          this.companySearchBox = false;
          this.member = mem || {} as Member;
          this.openAccessBox(mem)
          return
        }
      }

      if (companyUser.mobile == 0) {
        alert('Mobile not updated of selected user');

        return
      }

      if (companyUser.company_role_id != '') {
        this.member = {};

        this.member.company_user_id = companyUser.id;
        this.member.project_id = this.project.id;
        this.addTeamMember(this.project.id, companyUser.id);
        this.accessBox = true;
        // this.patchCompanyUser(companyUser.id, companyUser.company_role_id)
        return
      } else {
        this.accessBox = true;
      }
    } else {
      this.companyUser = {}
    }
  }



  getCompanyRoles() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.project.company_id);
    this.adminService.listCompanyRole(qp).subscribe(
      {
        next: (res) => {
          this.companyRoles = res.data || [] as CompanyRole[];
        },
        error: () => {
          //
        }
      }
    )
  }

  addTeamMember(project_id: string, company_user_id: string) {
    this.member = {};
    this.member.company_user_id = company_user_id;
    this.member.project_id = project_id;
    this.adminService.saveNewUserRole(this.member).subscribe(
      (res) => {
        const index = this.members.findIndex(i => i.id == res.id);
        if (index == -1) {
          this.members.push(res);
        } else {
          this.members[index] = res || {} as Member;
        }
        // this.loading = false;
        // this.accessBox = false;
        this.utilService.memberCount.next(this.members.length);
        // this.companyUser = {};
        // this.member = {};
      },
      (err) => {
        console.log(err);
      }
    );
  }

  patchCompanyUser(company_user_id: string, role_id: string) {
    this.companyUser.id = company_user_id;
    this.companyUser.company_role_id = role_id;
    // this.companyUser.type = CompanyPartyType.Employee;
    this.adminService.editCompanyUser(this.companyUser).subscribe({
      next: () => {
        this.addTeamMember(this.project.id, company_user_id)
      },
      error: () => {
        //
      }
    })
  }

  date_to_ngb_model(input_date):NgbDateStruct{
    input_date = new Date(input_date);
    if(input_date.getFullYear() == 1){
      return null
    }
    return {day:input_date.getDate(), month:input_date.getMonth()+1, year:input_date.getFullYear()}
  }

  setProjectLocation(){
    const modalRef = this.ngbModal.open(AddProjectLocationComponent,{
      size:'lg',
      backdrop:'static'
    })
    modalRef.result.then((result) => {
      this.project = result;
      this.updateProjectDetails();
    })
  }
}
