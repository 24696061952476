import { NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { AngularCropperjsComponent } from './components/angular-cropperjs/angular-cropperjs.component';
import { ImgEditModalComponent } from './components/modal/img-edit-modal/img-edit-modal.component';
import { UtilComponentsComponent, MyDatePickerComponent } from './components/util-components/util-components.component';

import {UtilService} from './services/util.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopComponent } from './components/top/top.component';
import { UserComponent } from './components/user/user.component';
import { FilterPipe } from './pipe/filter.pipe';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { BannerComponent } from './components/banner/banner.component';
import { TimerComponent } from './components/timer/timer.component';
import { ImageSliderModalComponent } from './components/modal/image-slider-modal/image-slider-modal.component';
import { ShortNamePipe } from './pipe/short-name.pipe';
import { ReportModalComponent } from './components/modal/report-modal/report-modal.component';
import { FilterModalComponent } from './components/modal/filter-modal/filter-modal.component';
import { PartyMemberModalComponent } from './components/modal/party-member-modal/party-member-modal.component';
import { DownloadReportButtonComponent } from './components/download-report-button/download-report-button.component';
import { MaterialItemModalComponent } from './components/modal/materialItem-modal/materialItem-modal.component';
import { ConfirmModalComponent } from './components/modal/confirm-modal/confirm-modal.component';
import { SortPipe } from './pipe/sort.pipe';
import { LogoLoaderComponent } from './components/logo-loader/logo-loader.component';
import { TruncatePipe } from './pipe/truncate.pipe';
import { PartyInputComponent } from './components/party-input/party-input.component';
import { SettingProjectComponent } from './components/modal/setting-project/setting-project.component';
import { SettingCompanyComponent } from './components/modal/setting-company/setting-company.component';
import { ZeroDataComponent } from './components/zero-data/zero-data.component';
import { ValidatorService } from './services/validator.service';
import { AttachmentViewComponent } from './components/attachment-view/attachment-view.component';
import { FilterFeatureComponent } from './components/filter-feature/filter-feature.component';
import {BackButtonComponent} from './components/back-button/back-button.component'
import { NotPremiumUserPopupComponent } from './components/not-premium-user-popup/not-premium-user-popup.component';
import { DuplicateProjectModalComponent } from './components/modal/duplicate-project-modal/duplicate-project-modal.component';
import { ToastsComponent } from './components/toasts/toasts.component';
import { ReportCompanyModalComponent } from './components/modal/report-company-modal/report-company-modal.component';
import { UpgradeStatusModalComponent } from './components/modal/upgrade-status-modal/upgrade-status-modal.component';
import { ServerDownModalComponent } from './components/modal/server-down-modal/server-down-modal.component';
import { CanvasBottomBarComponent } from './components/canvas-bottom-bar/canvas-bottom-bar.component';
import { LocalStorageService } from './services/local-storage-service.service';
import { HeroAddButtonComponent } from './components/hero-add-button/hero-add-button.component';
import { PhotoSliderComponent } from './components/photo-slider/photo-slider.component';
import { CalendarModalComponent } from './components/modal/calendar-modal/calendar-modal.component';
import { TwoDigitDecimaNumberDirective } from './directive/two-digit-decima-number.directive';
import { CaretComponent } from './components/caret/caret.component';
import { RangeDatePickerComponent } from './components/range-date-picker/range-date-picker.component';
import { NgIconsModule } from '@ng-icons/core';
import all from './services/icon-service.service';
import { AddCommentComponent } from './components/add-comment/add-comment.component';
import { ReadingFormatterPipe } from './pipe/reading-formatter.pipe';
import {DecimalPlacesDirective} from './directive/decimal-limit.directive';
import { SettingWarehouseComponent } from './components/modal/setting-warehouse/setting-warehouse.component';
import { ProjectHeaderComponent } from './components/project-header/project-header.component';
import { LottiePlayerComponent } from './lottie-player/lottie-player.component';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
    return player;
  }
import { ListTransactionComponent } from './components/list-transaction/list-transaction.component';
import { BankAccountsComponent } from './components/bank-accounts/bank-accounts.component';
import { SubconWorkorderModalComponent } from './components/modal/subcon-workorder-modal/subcon-workorder-modal.component';
import { SortByOrderPipe } from './pipe/sort-by-order.pipe';
import { CreatePurchaseOrderModalComponent } from './components/modal/create-purchase-order-modal/create-purchase-order-modal.component';
import { ProjectInputComponent } from './components/project-input/project-input.component';
import { TaskInputComponent } from './components/task-input/task-input.component';
import { DynamicNumberPipe } from './pipe/dynamic-number.pipe';
import { VideoPalyerComponent } from './components/video-palyer/video-palyer.component';
import { AddAddressComponent } from './components/add-address/add-address.component';
import { NumberFormatPipe } from './pipe/number-format.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { NewlineToBrPipe } from './pipe/newline-to-br.pipe';
import { MaterialStatusPipe } from './pipe/material-status.pipe';
import { AddressLocationComponent } from './components/modal/address-location/address-location.component';
import { AddMemberComponent } from './components/modal/add-member/add-member.component';
import { PremiumTagComponent } from './components/premium-tag/premium-tag.component';
import { ViewShipBillComponent } from '../company/shared/ship-to-bill-to/view-ship-bill/view-ship-bill.component';
import { InputShipBillComponent } from '../company/shared/ship-to-bill-to/input-ship-bill/input-ship-bill.component';

@NgModule({
    imports: [
        NgOptimizedImage,
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule.forRoot(), 
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            backdropBorderRadius: '0px',
            primaryColour: '#5F44CE',
            secondaryColour: 'rgb(228,228,228)',
            tertiaryColour: '#ff4d00'
        }),
        // NgProgressModule.withConfig({
        //     /*spinnerPosition: 'left',*/
        //     color: '#f71cff',
        //     thick: true
        // }),
        // NgProgressRouterModule.withConfig({}),
        InfiniteScrollModule,
        NgIconsModule.withIcons(all),
        
    ],
    declarations: [
        AngularCropperjsComponent,
        ImgEditModalComponent,
        MyDatePickerComponent,
        UtilComponentsComponent,
        SidebarComponent,
        TopComponent,
        UserComponent,
        FilterPipe,
        MobileMenuComponent,
        SafeHtmlPipe,
        PdfViewerComponent,
        ScrollTopComponent,
        BannerComponent,
        TimerComponent,
        ImageSliderModalComponent,
        ShortNamePipe,
        ReportModalComponent,
        FilterModalComponent,
        PartyMemberModalComponent,
        DownloadReportButtonComponent,
        MaterialItemModalComponent,
        ConfirmModalComponent,
        SettingProjectComponent,
        SortPipe,
        TruncatePipe,
        LogoLoaderComponent,
        PartyInputComponent,
        ProjectInputComponent,
        SettingCompanyComponent,
        ZeroDataComponent,
        AttachmentViewComponent,
        FilterFeatureComponent,
        BackButtonComponent,
        NotPremiumUserPopupComponent,
        DuplicateProjectModalComponent,
        ToastsComponent,
        ReportCompanyModalComponent,
        UpgradeStatusModalComponent,
        ServerDownModalComponent,
        CanvasBottomBarComponent,
        HeroAddButtonComponent,
        PhotoSliderComponent,
        CalendarModalComponent,
        TwoDigitDecimaNumberDirective,
        CaretComponent,
        RangeDatePickerComponent,
        AddCommentComponent,
        ReadingFormatterPipe,
        DecimalPlacesDirective,
        SettingWarehouseComponent,
        ProjectHeaderComponent,
        LottiePlayerComponent,
        ListTransactionComponent,
        BankAccountsComponent,
        SubconWorkorderModalComponent,
        SortByOrderPipe,
        CreatePurchaseOrderModalComponent,
        ProjectInputComponent,
        TaskInputComponent,
        DynamicNumberPipe,
        VideoPalyerComponent,
        NumberFormatPipe,
        ProgressBarComponent,
        AddressLocationComponent,
        AddAddressComponent,
        NumberFormatPipe,
        NewlineToBrPipe,
        MaterialStatusPipe,
        AddMemberComponent,
        PremiumTagComponent,
        ViewShipBillComponent,
        InputShipBillComponent,

    ],
    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModule,
        NgxLoadingModule,
        InfiniteScrollModule,
        ImgEditModalComponent,
        MyDatePickerComponent,
        UtilComponentsComponent,
        SidebarComponent,
        MobileMenuComponent,
        TopComponent,
        UserComponent,
        FilterPipe,
        SafeHtmlPipe,
        ShortNamePipe,
        PdfViewerComponent,
        ScrollTopComponent,
        BannerComponent,
        TimerComponent,
        ImageSliderModalComponent,
        ReportModalComponent,
        DownloadReportButtonComponent,
        ConfirmModalComponent,
        SortPipe,
        TruncatePipe,
        LogoLoaderComponent,
        PartyInputComponent,
        ProjectInputComponent,
        TaskInputComponent,
        ZeroDataComponent,
        AttachmentViewComponent,
        FilterFeatureComponent,
        BackButtonComponent,
        NotPremiumUserPopupComponent,
        DuplicateProjectModalComponent,
        ToastsComponent,
        ServerDownModalComponent,
        CanvasBottomBarComponent,
        HeroAddButtonComponent,
        PhotoSliderComponent,
        CalendarModalComponent,
        TwoDigitDecimaNumberDirective,
        CaretComponent,
        RangeDatePickerComponent,
        NgIconsModule,
        ReadingFormatterPipe,
        ProjectHeaderComponent,
        LottiePlayerComponent,
        ListTransactionComponent,
        BankAccountsComponent,
        SortByOrderPipe,
        DynamicNumberPipe,
        NumberFormatPipe,
        ProgressBarComponent,
        NewlineToBrPipe,
        MaterialStatusPipe,
        AddMemberComponent,
        PremiumTagComponent,
        ViewShipBillComponent,
        InputShipBillComponent,
        DecimalPlacesDirective
    ],
    providers: [
        UtilService,
        ValidatorService,
        LocalStorageService,
        DynamicNumberPipe
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
