<div class="d-flex flex-column gap-3 w-100">
    <div class="d-flex align-items-start justify-content-between gap-2 w-100" *ngIf=" shipToBillToAddress?.bill_from_address_id || shipToBillToAddress?.bill_to_address_id">
        <div class="d-flex flex-column align-items-start text-start gap-2" style="width: 40%;">
                <span class="f-14 disabled-font">Bill From:</span>
                <div class="d-flex flex-column align-items-start address-section" *ngIf="shipToBillToAddress?.bill_from_address_id">
                    <!-- <span class="f-12">{{ shipToBillToAddress?.monkey_patch_bill_from_address.address_title }}</span> -->
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_from_address.address_line_1" class="f-12">{{shipToBillToAddress?.monkey_patch_bill_from_address.address_line_1}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_from_address.address_line_2" class="f-12">{{shipToBillToAddress?.monkey_patch_bill_from_address.address_line_2}}</span>
                    <span class="f-12">
                        {{shipToBillToAddress?.monkey_patch_bill_from_address?.city ? shipToBillToAddress?.monkey_patch_bill_from_address?.city+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_bill_from_address?.state ? shipToBillToAddress?.monkey_patch_bill_from_address?.state+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_bill_from_address?.postal_code}} 
                    </span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_from_address.monkey_patch_country_config?.country_name"
                    class="f-12">{{shipToBillToAddress?.monkey_patch_bill_from_address.monkey_patch_country_config?.country_name}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_from_address.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ shipToBillToAddress?.monkey_patch_bill_from_address.address_gst }}</span>
                </div>
                <div class="d-flex flex-column align-items-start jusfity-content-center address-section" *ngIf="!shipToBillToAddress?.bill_from_address_id">
                    <span class="f-12 disabled-font">No address available</span>
                </div>
        </div>
        <div class="d-flex flex-column align-items-end text-end gap-2" style="width: 40%;">
                <span class="f-14 disabled-font">Bill To:</span>
                <div class="d-flex flex-column text-end address-section" *ngIf="shipToBillToAddress?.bill_to_address_id">
                    <!-- <span class="f-12">{{ shipToBillToAddress?.monkey_patch_bill_to_address.address_title }}</span> -->
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_to_address?.address_line_1" class="f-12">{{shipToBillToAddress?.monkey_patch_bill_to_address?.address_line_1}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_to_address?.address_line_2" class="f-12">{{shipToBillToAddress?.monkey_patch_bill_to_address?.address_line_2}}</span>
                    <span class="f-12">
                        {{shipToBillToAddress?.monkey_patch_bill_to_address?.city ? shipToBillToAddress?.monkey_patch_bill_to_address?.city+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_bill_to_address?.state ? shipToBillToAddress?.monkey_patch_bill_to_address?.state+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_bill_to_address?.postal_code}} 
                    </span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_to_address?.monkey_patch_country_config?.country_name"
                    class="f-12">{{shipToBillToAddress?.monkey_patch_bill_to_address?.monkey_patch_country_config?.country_name}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_bill_to_address?.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ shipToBillToAddress?.monkey_patch_bill_to_address?.address_gst }}</span>
                </div>
                <div class="d-flex flex-column align-items-start jusfity-content-center address-section" *ngIf="!shipToBillToAddress?.bill_to_address_id">
                    <span class="f-12 disabled-font">No address available</span>
                </div>
        </div>
    </div>
    <div class="d-flex align-items-start justify-content-between gap-2 w-100" *ngIf=" shipToBillToAddress?.ship_from_address_id || shipToBillToAddress?.ship_to_address_id">
        <div class="d-flex flex-column text-start align-items-start gap-2" style="width: 40%;">
                <span class="f-14 disabled-font">Ship From:</span>
                <div class="d-flex flex-column text-start address-section" *ngIf="shipToBillToAddress?.ship_from_address_id">
                    <!-- <span class="f-12">{{ shipToBillToAddress?.monkey_patch_ship_from_address.address_title }}</span> -->
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_from_address.address_line_1" class="f-12">{{shipToBillToAddress?.monkey_patch_ship_from_address.address_line_1}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_from_address.address_line_2" class="f-12">{{shipToBillToAddress?.monkey_patch_ship_from_address.address_line_2}}</span>
                    <span class="f-12">
                        {{shipToBillToAddress?.monkey_patch_ship_from_address?.city ? shipToBillToAddress?.monkey_patch_ship_from_address?.city+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_ship_from_address?.state ? shipToBillToAddress?.monkey_patch_ship_from_address?.state+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_ship_from_address?.postal_code}} 
                    </span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_from_address.monkey_patch_country_config?.country_name"
                    class="f-12">{{shipToBillToAddress?.monkey_patch_ship_from_address.monkey_patch_country_config?.country_name}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_from_address.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ shipToBillToAddress?.monkey_patch_ship_from_address.address_gst }}</span>
                </div>
                <div class="d-flex flex-column align-items-start jusfity-content-center address-section" *ngIf="!shipToBillToAddress?.ship_from_address_id">
                    <span class="f-12 disabled-font">No address available</span>
                </div>
        </div>
        <div class="d-flex flex-column align-items-end text-end gap-2" style="width: 40%;">
                <span class="f-14 disabled-font">Ship To:</span>
                <div class="d-flex flex-column address-section" *ngIf="shipToBillToAddress?.ship_to_address_id">
                    <!-- <span class="f-12">{{ shipToBillToAddress?.monkey_patch_ship_to_address.address_title }}</span> -->
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_to_address?.address_line_1" class="f-12">{{shipToBillToAddress?.monkey_patch_ship_to_address?.address_line_1}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_to_address?.address_line_2" class="f-12">{{shipToBillToAddress?.monkey_patch_ship_to_address?.address_line_2}}</span>
                    <span class="f-12">
                        {{shipToBillToAddress?.monkey_patch_ship_to_address?.city ? shipToBillToAddress?.monkey_patch_ship_to_address?.city+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_ship_to_address?.state ? shipToBillToAddress?.monkey_patch_ship_to_address?.state+', ' : ''}} 
                        {{shipToBillToAddress?.monkey_patch_ship_to_address?.postal_code}} 
                    </span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_to_address?.monkey_patch_country_config?.country_name"
                    class="f-12">{{shipToBillToAddress?.monkey_patch_ship_to_address?.monkey_patch_country_config?.country_name}}</span>
                    <span *ngIf="shipToBillToAddress?.monkey_patch_ship_to_address?.address_gst" class="f-12">{{authService.session.country?.tax_display_name | uppercase}}: {{ shipToBillToAddress?.monkey_patch_ship_to_address?.address_gst }}</span>
                </div>
                <div class="d-flex flex-column align-items-start jusfity-content-center address-section" *ngIf="!shipToBillToAddress?.ship_to_address_id">
                    <span class="f-12 disabled-font">No address available</span>
                </div>
        </div>
    </div>
</div>
