/**
 * Created by tarun on 16/7/17.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, merge, fromEvent, of, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Token, Session, Profile, Member, Company, Project, WorkOrder, Country, CompanyAttendanceInfo, CompanyConfig } from '../../interfaces/interfaces';

import { Config } from '../../config/config.config';
import { mapTo, tap } from 'rxjs/operators';
import { VideoModalComponent } from '../components/video-modal/video-modal.component';
import { Observer } from 'rxjs';
import { CompanyUserPolicy } from 'src/app/enum/policy_ids_enums';
import { CompanyAttendanceService } from 'src/app/payroll-attendance/service/compnay-attendance.service';
declare let dataLayer: any;
@Injectable()
export class AuthService {
  public token: Token = {} as Token;
  public session: Session = {} as Session;
  private messageSource = new BehaviorSubject('default message');
  public currentMessage$ = this.messageSource.asObservable();
  private activeTabBehaviorSubject = new BehaviorSubject('' as string);
  public activeTab$ = this.activeTabBehaviorSubject.asObservable();
  private activeTopBehaviorSubject = new BehaviorSubject('' as string);
  public topBarLevel$ = this.activeTopBehaviorSubject.asObservable();
  public companyUserPolicy = CompanyUserPolicy;

  public myCustomTransactionStatus = new BehaviorSubject<boolean>(false);
  public myTransactionBoolean$ = this.myCustomTransactionStatus.asObservable();

  public companyListUpdated = new BehaviorSubject<boolean>(false);
  public companyListObserver = this.companyListUpdated.asObservable();

  private serverDownStatus = new BehaviorSubject<boolean>(false);

  projectBehaviour = new BehaviorSubject([]);
  project$ = this.projectBehaviour.asObservable();

  public dashboardTodoCount = new BehaviorSubject<boolean>(false);
  public dashboardTodoCountObservable = this.dashboardTodoCount.asObservable();

  public companyBehavioSubject = new BehaviorSubject({} as Company);
  public company$ = this.companyBehavioSubject.asObservable();
  public locale = 'en';
  public JSONMAP = new Map();
  public tokenMap = new Map<string, Token>();
  public companyHeaderBehaviorSubject = new BehaviorSubject('' as string);
  public companyHeader = this.companyHeaderBehaviorSubject.asObservable();
  public sessionUpdated = new BehaviorSubject<boolean>(false);
  public sessionObserver = this.sessionUpdated.asObservable();

  public title = '';
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private config: Config,
    private modalService: NgbModal,
    private compnayAttendanceService: CompanyAttendanceService
  ) {
    this.locale = this.localStorageService.get('locale') || 'en';
    const profile = this.localStorageService.get('profile');
    const l_token = this.localStorageService.get('token');
    const company = localStorageService.get('company');
    const country = localStorageService.get('country');
    const policy = localStorageService.get('policy');
    if (l_token && profile) {
      this.token = l_token as Token;
      this.session.token = this.token;
      this.session.isActive = true;
      this.session.profile = profile as Profile;
      this.session.company = company as Company;
      this.session.policy = policy as [];
      this.session.country = country as Country;
      this.sessionUpdated.next(true);
    } else {
      this.session.isActive = false;
    }
  }
  public setMyTransactionStatus(value: boolean): void {
    this.myCustomTransactionStatus.next(value);
  }

  getServerDownStatus(): Observable<boolean> {
    return this.serverDownStatus;
  }

  setServerDownStatus(value: boolean): void {
    this.serverDownStatus.next(value);
  }

  changeCompanyHeader(text: string) {
    this.companyHeaderBehaviorSubject.next(text);
  }
  sendGTMEvent(eventName: string, eventMap?: Map<string, any>) {
    try {
      if (dataLayer) {
        const data = {} as any;
        data.event = eventName;
        data.mobile = this.session?.profile?.mobile;
        eventMap?.forEach((v, k) => {
          data[k] = v;
        });
        dataLayer.push(data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  changeActiveTab(tabName: string) {
    this.activeTabBehaviorSubject.next(tabName);
  }
  changeActiveTopBar(topbarName: string) {
    this.activeTopBehaviorSubject.next(topbarName);
  }

  changeCompany(company: Company) {
    this.companyBehavioSubject.next(company);
  }
  setLocale(locale: string) {
    this.locale = locale;
    this.localStorageService.set('locale', locale);
  }
  openVideoModal(url, goto) {
    // document.body.classList.add('modal-login');
    const modalRef = this.modalService.open(VideoModalComponent, { centered: true, backdrop: 'static', size: 'lg' });
    modalRef.componentInstance.data = { url, goto };
    modalRef.result.then(data => {
      //
    }, err => {
      console.log(err);
    });
  }
  networkStatus(): Observable<any> {
    return merge(
      fromEvent(window, 'offline').pipe(mapTo(false)),
      fromEvent(window, 'online').pipe(mapTo(true)),
      // of(navigator.onLine),
    );
  }
  hasAccess(...policies: CompanyUserPolicy[]): boolean {
    for (let i = 0; i < policies.length; i++) {
      if (this.session?.policy?.includes(policies[i])) {
        return true;
      }
    }
    return false;
  }

  getUserCompanyUserId(): string {
    return this.session.company.monkey_patch_my_company_user.id;
  }

  changeMessage(message: any) {
    this.messageSource.next(message);
  }



  signupAndGetOTP(user): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/register`,
      user,
      {}
    );
  }

  signupAndGetOTPWhatsApp(user): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/send-whatsapp-otp`,
      user,
      {}
    );
  }

  loginWithGoogle(user): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/google-login`,
      user,
      {}
    );
  }
  login(user): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/login`,
      user,
      {}
    );
  }

  detailAnonMobile(data):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/detail/anon/mobile`, data, {}
    )
  }
  sendOTP(user): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/send-otp`,
      user,
      {}
    );
  }
  loginWithQr(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/generate/login/qr`,
      data,
      {}
    );
  }

  loginWithPassword(data:any):Observable<any>{
    return this.http.post(
      `${this.config.userEndpoint}/login/password/mobile`, data, {}
    )
  }

  editLoginPassword(data:any):Observable<any>{
    return this.http.patch(
      `${this.config.userEndpoint}/edit/password`, data, {}
    )
  }

  attemptQRLogin(data): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/qr/login`,
      data,
      {}
    );
  }
  getUserByToken(): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/profile`,
      {}
    );
  }
  getUserCategory(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/category`,
      { params }
    )
  }
  addStaff(profile): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/profile`,
      profile,
      {}
    );
  }
  editProfile(profile: Profile): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/profile`,
      profile,
      {}
    );
  }
  getUserByUsername(user_name): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/profile/${user_name}`,
      {}
    );
  }
  refreshToken(): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/token/refresh`,
      {}
    );
  }
  saveToken(token: Token) {
    return this.localStorageService.set('token', token);
  }
  getToken(): Token {
    return this.localStorageService.get('token') as Token;
  }
  savePrimaryToken(token: Token) {
    return this.localStorageService.set('primaryToken', token);
  }
  getPrimaryToken(): Token {
    return this.localStorageService.get('primaryToken') as Token;
  }
  removePrimaryToken() {
    this.localStorageService.remove('primaryToken');
  }
  saveProfile(profile: Profile) {
    this.localStorageService.set('profile', profile);
  }
  saveCompany(company: Company) {
    this.localStorageService.set('company', company);
    this.localStorageService.set('policy', company.monkey_patch_my_company_user.monkey_patch_policy_ids);
    this.session.company = company;
    this.session.policy = company.monkey_patch_my_company_user.monkey_patch_policy_ids || [];
    const localProfile = this.localStorageService.get('profile');
    localProfile.current_company_id = company.id || '';
    this.saveProfile(localProfile);
    this.companyBehavioSubject.next(company);
    this.setCountry(company.country_iso);
    this.saveCompanyConfiguration(company.monkey_patch_company_config);
    this.saveCompanyAttendanceSetting();
    this.checkWarehouse();
    this.fetchThirdPartyApp();
    this.sessionUpdated.next(true);
  }

  getCompanyConfiguration(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/company/companyconfiguration/${id}`,
      {}
    );
  }

  setCountry(company_country_iso){
    this.getCountryList().subscribe({
      next: (res) => {
        const countries = res || [];
        const _index = countries.findIndex(item=>item.country_iso===company_country_iso);
        this.session.country = countries[_index] as Country;
        this.localStorageService.set('country', countries[_index]);
      },
      error: () => {
        //
      }
    })
  }

  saveCompanyConfiguration(companyConfiguration: CompanyConfig){
    this.session.companyConfiguration = companyConfiguration as CompanyConfig;
    this.localStorageService.set('companyConfiguration', companyConfiguration as CompanyConfig);
  }

  saveCompanyAttendanceSetting(){
    this.compnayAttendanceService
    .detailCompanyAttendanceInfo(this.session.company.id).subscribe({
      next:(res:CompanyAttendanceInfo)=>{
        this.saveCompanyAttendanceInfo(res)

      }
    })
  }

  saveCompanyAttendanceInfo(data:CompanyAttendanceInfo){
    this.session.CompanyAttendanceInfo = data;
  }
  checkWarehouse() {
    let qp = new HttpParams();
    qp = qp.set('company_id', this.session.company.id);
    this.getWarehouseList(qp).subscribe({
      next: (res) => {
        this.saveWarehouse(res?.projects[0] || {});
      },
      error: (err) => {
        //
      }
    })
  }


  saveProject(project: Project){
    this.localStorageService.set('project', project);
    this.session.project = project as Project;
  }

  saveWarehouse(warehouse: Project) {
    this.session.warehouse = warehouse as Project;
    this.sessionUpdated.next(true);
  }

  getProfile(): Profile {
    return this.localStorageService.get('profile') as Profile;
  }
  savePrimaryProfile(profile: Profile){
    return this.localStorageService.set('primaryProfile', profile);
  }
  getPrimaryProfile(): Profile {
    return this.localStorageService.get('primaryProfile') as Profile;
  }
  removePrimaryProfile() {
    return this.localStorageService.remove('primaryProfile');
  }
  fetchToken(): Promise<any> {
    return Promise.resolve(this.localStorageService.get('token'));
  }

  startSession(token: Token): Promise<Session> {
    return new Promise((resolve, reject) => {
      this.saveToken(token);
      this.token = token;
      this.session.token = token;
      this.getUserByToken().subscribe((res) => {
        this.session.profile = res.user;
        this.session.isActive = true;
        this.saveProfile(this.session.profile);
        resolve(this.session);
      });
    });
  }
  getSession(): Promise<Session> {
    return new Promise((resolve, reject) => {
      this.session.token = this.getToken();
      this.session.profile = this.getProfile();
      if (this.session.token && this.session.profile) {
        this.session.isActive = true;
      } else {
        this.session.isActive = false;
      }
      resolve(this.session);
    });
  }
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      const web_fcm_token = localStorage.getItem('web_fcm_token');
      if(web_fcm_token && web_fcm_token === this.session.profile.web_fcm_token){
        this.removeFCMToken({id: this.session.profile.id, web_fcm_token: ""}).subscribe(res => {
          //
        });
      }
      this.session = {} as Session;
      this.token = {} as Token;
      this.localStorageService.clearAll();
      sessionStorage.clear();
      resolve(true);
    });
  }

  // for admin

  getPlatformInfo(): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/platforminfo`,
      {}
    )
  }
  getByUrl(url): Observable<any> {
    return this.http.get(
      url,
      {}
    );
  }
  downloadImage(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/user/download/image/${id}`,
      { responseType: 'blob' as 'json' }
    );
  }
  getPinnedProjects(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/pinned/project`,
      { params }
    );
  }
  getUnpinnedProjects(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/unpinned/project`,
      { params }
    );
  }
  getCompanyList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/company`,
      { params }
    );
  }

  getWorkableCompanyList(params?:HttpParams):Observable<any>{
    return this.http.get(
      `${this.config.userEndpoint}/list/workable/company`,{params}

    )
  }

  getProjectById(id): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/project/${id}`,
      {}
    );
  }
  getHelpVideos(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/helpvideo`,
      { params }
    );
  }
  getCachedJSONByUrl(url): Observable<any> {
    if (this.JSONMAP.get(url)) {
      return new Observable<any>((observer: Observer<any>) => {
        observer.next(this.JSONMAP.get(url));
        observer.complete();
      });
    } else {
      return this.http.get(
        url,
        {}
      ).pipe(tap((data) => {
        this.JSONMAP.set(url, data);
      }));
    }
  }
  getTasks(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/task`,
      { params }
    );
  }
  getExpenses(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/expense`,
      { params }
    );
  }
  getDrawings(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/drawing`,
      { params }
    );
  }
  getMaterials(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/material`,
      { params }
    );
  }
  getMaterialStock(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialstock`,
      { params }
    );
  }
  getProjectMaterialList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/project/material`,
      { params }
    );
  }
  getMaterialStockDetail(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/materialstock/${id}`
    );
  }
  getMaterialItems(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/materialitem`,
      { params }
    );
  }
  getImages(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/image`,
      { params }
    );
  }

  getStaffMemberAdmin(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/team-member`,
      { params }
    );
  }
  getPartiesMember(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/party-member`,
      { params }
    );
  }

  editRole(member: Member): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/team-member`,
      member,
      {}
    );
  }
  getMonthlyStaffLedger(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/monthly/staff-ledger`,
      { params }
    );
  }
  getListMonthlyStockWage(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/monthly/stock/wage`,
      { params }
    );
  }

  companyTrialSubscription(company: Company): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/activate/company/trail`,
      company,
      {}
    );
  }

  listTransactionCompanyUser(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/transaction/companyuser`,
      { params }
    );
  }
  addSaleleadToken(): Observable<any> {
    return this.http.post(
      `${this.config.userEndpoint}/add/salesleadtoken`,
      {},
      {}
    );
  }
  getCountryList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/countries`,
      { params }
    );
  }
  getPolicyTree(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/policy/tree`,
      { params }
    );
  }
  // listCompanyRole(params?: HttpParams): Observable<any> {
  //   return this.http.get(
  //     `${this.config.userEndpoint}/list/companyrole`,
  //     { params }
  //   );
  // }
  getCompanyRoleById(id: string): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/detail/companyrole/${id}`
    );
  }
  getPolicyList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/policy`,
      { params }
    );
  }

  editCompanyRole(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/companyrole`,
      data,
      {}
    );
  }

  getWarehouseList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/warehouse`,
      { params }
    );
  }

  removeFCMToken(data): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/edit/fcmtoken`,
      data, {}
    );
  }

  getInitialTab(tab?:string) {

  switch (tab) {
    case "task":
      if (
        this.hasAccess(
          this.companyUserPolicy.View_all_task,
          this.companyUserPolicy.View_my_task
        )
      ) {
        return "task";
      } else {
        return "access_failed";
      }
    case "todo":
      if (
        this.hasAccess(
          this.companyUserPolicy.VIEW_ALL_TODO,
          this.companyUserPolicy.VIEW_MY_TODO
        )
      ) {
        return "todo";
      } else {
        return "access_failed";
      }
    default:
      if (
        this.hasAccess(
          this.companyUserPolicy.View_all_transactions,
          this.companyUserPolicy.View_my_transactions
        )
      ) {
        return "payment";
      } else if (
        this.hasAccess(this.companyUserPolicy.View_project_dashboard) &&
        this.hasAccess(this.companyUserPolicy.Project_Overview)
      ) {
        return "overview";
      } else if (
        this.hasAccess(this.companyUserPolicy.View_project_party_balance)
      ) {
        return "party-list";
      } else if (
        this.hasAccess(
          this.companyUserPolicy.View_all_task,
          this.companyUserPolicy.View_my_task
        )
      ) {
        return "task";
      } else if (this.hasAccess(this.companyUserPolicy.VIEW_ATTENDANCE)) {
        return "attendance";
      } else if (this.hasAccess(this.companyUserPolicy.View_material)) {
        return "material";
      } else if (
        this.hasAccess(this.companyUserPolicy.View_Equipment) &&
        this.session.company?.is_equipment_enabled
      ) {
        return "equipment";
      } else if (this.hasAccess(this.companyUserPolicy.View_photos)) {
        return "photos";
      } else if (
        this.hasAccess(
          this.companyUserPolicy.View_Subcon,
          this.companyUserPolicy.View_My_Subcon
        ) &&
        this.session.company?.is_subcon_enabled
      ) {
        return "sub-contractor";
      } else if (
        this.hasAccess(
          this.companyUserPolicy.view_my_boq,
          this.companyUserPolicy.view_all_boq
        )
      ) {
        return "boq";
      } else if (
        this.hasAccess(
          this.companyUserPolicy.VIEW_MY_TODO,
          this.companyUserPolicy.VIEW_ALL_TODO
        )
      ) {
        return "todo";
      }
      break;
  }
  }

  toggleAccess(): Observable<any> {
    return this.http.patch(
      `${this.config.userEndpoint}/toggle/accessgrant`,
      {},
      {}
    );
  }

  fetchThirdPartyApp() {
    if (this.session.company.is_third_party_app_integration_enabled == 0){
      return null;
    }
    let qp = new HttpParams();
    qp = qp.set('company_id', this.session.company.id);
    let returnValue = this.getThirdPartyAppsList(qp)
    returnValue.subscribe({
      next: (res) => {
        this.session.third_party_apps = res.data;
      },
      error: (err) => {
        //
      }
    })
    return returnValue
  }

  getThirdPartyAppsList(params: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/third-party-app`,
      { params }
    );
  }
}
