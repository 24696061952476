export enum CompanyUserPolicy {
  Add_album = "add_album",
  Add_company_role = "add_company_role",
  Add_company_team_member = "add_company_team_member",
  Add_i_paid = "add_i_paid",
  Add_i_received = "add_i_received",
  Add_material_item = "add_material_item",
  Add_material_used = "add_material_used",
  Add_material_return = "add_material_return",
  Add_new_party = "add_new_party",
  Add_new_project = "add_new_project",
  Add_other_expense = "add_other_expense",
  Add_payment_in = "add_payment_in",
  Add_payment_out = "add_payment_out",
  Add_petty_expesne = "add_petty_expesne",
  Add_photo = "add_photo",
  Add_project_team = "add_project_team",
  Add_purchase = "add_purchase",
  Add_quotation = "add_quotation",
  Add_quotation_invoice = "add_quotation_invoice",
  Add_received = "add_received",
  Add_task = "add_task",
  Add_task_progress = "add_task_progress",
  Add_task_comment = 'add_task_comment',
  Assign_task_to_user = "assign_task_to_user",
  Edit_task_status = "edit_task_status",
  Add_sales_invoice = "add_sales_invoice",
  Approval = "approval",
  Approve_album = "approve_album",
  Approve_material_received = "approve_material_received",
  Approve_material_used = "approve_material_used",
  Approve_other_expense = "approve_other_expense",
  Approve_payment_in = "approve_payment_in",
  Approve_payment_out = "approve_payment_out",
  Approve_petty_expense = "approve_petty_expense",
  Approve_photo = "approve_photo",
  Approve_purchase = "approve_purchase",
  Approve_quotation = "approve_quotation",
  Approve_quotation_invoice = "approve_quotation_invoice",
  Approve_staff_payment = "approve_staff_payment",
  Approve_task = "approve_task",
  Approve_update_progress = "approve_update_progress",
  Attendance = "attendance",
  Bank_details = "bank_details",
  Client = "client",
  Comapny_details = "comapny_details",
  Company_accounts = "company_accounts",
  Company_roles = "company_roles",
  Company_settings = "company_settings",
  Company_team = "company_team",
  Data_settings = "data_settings",
  Edit_album = "edit_album",
  Edit_all_transaction = "edit_all_transaction",
  Edit_bank_details = "edit_bank_details",
  Edit_company_role = "edit_company_role",
  Edit_company_team_member = "edit_company_team_member",
  Edit_material_item_detail = "edit_material_item_detail",
  Edit_material_received = "edit_material_received",
  Edit_material_used = "edit_material_used",
  Edit_other_expense = "edit_other_expense",
  Edit_own_task = "edit_own_task",
  Edit_party_detail = "edit_party_detail",
  Edit_payment_in = "edit_payment_in",
  Edit_payment_out = "edit_payment_out",
  Edit_petty_expense = "edit_petty_expense",
  Edit_photo = "edit_photo",
  Edit_project_details = "edit_project_details",
  Edit_purchase = "edit_purchase",
  Edit_quotation = "edit_quotation",
  Edit_quotation_invoice = "edit_quotation_invoice",
  Edit_staff_payment = "edit_staff_payment",
  Edit_task = "edit_task",
  Edit_task_progress = "edit_task_progress",
  Hide_unhide_party = "hide_unhide_party",
  Manage_album = "manage_album",
  Manage_photo = "manage_photo",
  Manage_task = "manage_task",
  Manage_task_progress = "manage_task_progress",
  Material = "material",
  Material_library = "material_library",
  Material_purchase = "material_purchase",
  Material_received = "material_received",
  Material_used = "material_used",
  Other_expense = "other_expense",
  Party_library = "party_library",
  Payment_in = "payment_in",
  Payment_out = "payment_out",
  Petty_expense = "petty_expense",
  Photo = "photo",
  Project = "project",
  Project_team = "project_team",
  Quotation = "quotation",
  Quotation_invoice = "quotation_invoice",
  Reimbursement = "reimbursement",
  Remove_project_team = "remove_project_team",
  Site_tracking = "site_tracking",
  Task = "task",
  Task_and_photos = "task_and_photos",
  View_all_transactions = "view_all_transactions",
  View_bank_details = "view_bank_details",
  View_billing_in_project = "view_billing_in_project",
  View_company_roles = "view_company_roles",
  View_company_team = "view_company_team",
  View_material = "view_material",
  View_material_library = "view_material_library",
  View_my_transactions = "view_my_transactions",
  View_other_expense = "view_other_expense",
  View_party_library = "view_party_library",
  View_payment_in = "view_payment_in",
  View_payment_out = "view_payment_out",
  View_photos = "view_photos",
  View_project_party_balance = "view_project_party_balance",
  View_project_party_balance_detail = "view_project_party_balance_detail",
  View_quotation = "view_quotation",
  View_quotation_invoice = "view_quotation_invoice",
  View_task = "view_task",
  View_all_task = "view_all_task",
  View_my_task = "view_my_task",
  View_company_party_ledger = "view_company_party_ledger",
  Download_company_reports = "download_company_reports",
  View_company_dashboard = "view_company_dashboard",
  View_trade_library = "view_trade_library",
  Add_new_trade = "add_new_trade",
  Edit_trade = "edit_trade",
  View_salary_amount = "view_salary_amount",
  Add_material_request = "add_material_request",
  View_material_request = "view_material_request",
  View_project_dashboard = "view_project_dashboard",
  Equipment = "equipment",
  View_Equipment = "view_equipment",
  View_Equipment_Library = "view_equipment_library",
  Add_Equipment = "add_equipment",
  Edit_Equipment = "edit_equipment",
  Delete_Equipment = "delete_equipment",
  Add_Equipment_Stock = "add_equipment_stock",
  Edit_Equipment_Stock = "edit_equipment_stock",
  Add_Equipment_Used_Entry = "add_equipment_used_entry",
  Edit_Equipment_Used_Entry = "edit_equipment_used_entry",
  Company_Dashboard = "company_dashboard",
  Company_Reports = "company_reports",
  Transaction_Summary = "transaction_summary",
  Project_Overview = "project_overview",
  Edit_Material_Request_Status = "edit_material_request_status",
  View_Subcon = "view_subcon",
  Add_Subcon_Workorder = "add_subcon_workorder",
  Edit_Subcon_Workorder = "edit_subcon_workorder",
  View_Subcon_Expense = "view_subcon_expense",
  Add_Subcon_Expense = "add_subcon_expense",
  Edit_Subcon_Expense = "edit_subcon_expense",
  Add_Subcon_Workorder_Progress = "add_subcon_workorder_progress",
  Edit_Subcon_Workorder_Progress = "edit_subcon_workorder_progress",
  Add_Subcon_Billing_Activity = "add_subcon_billing_activity",
  Edit_Subcon_Billing_Activity = "edit_subcon_billing_activity",

  Procurement = "procurement",
  View_Purchase_Order_List = "view_purchase_order_list",
  View_Purchase_Order_details = "view_purchase_order_details",
  Edit_Purchase_Order = "edit_purchase_order",
  Mark_Close_Purchase_Order = "mark_close_purchase_order",
  Edit_Purchase_Order_Status = "edit_purchase_order_status",
  View_Purchase_Order_Item_List = "view_purchase_order_item_list",
  Add_Purchase_Order = "add_purchase_order",

  VIEW_ASSET = "view_asset",
  ADD_ASSET = "add_asset",
  EDIT_ASSET="edit_asset",
  ADD_ASSET_ALLOCATION="add_asset_allocation",
  EDIT_ASSET_ALLOCATION="edit_asset_allocation",
  VIEW_ASSET_ALLOCATION="view_asset_allocation",
  View_Project_Balances="view_project_balances",
  
  VIEW_ALL_TODO = "view_all_todo",
  VIEW_MY_TODO = "view_my_todo",
  ADD_TODO = "add_todo",
  EDIT_TODO = "edit_todo",
  EDIT_TODO_STATUS = "edit_todo_status",
  ADD_TODO_COMMENT = "add_todo_comment",

  DEDUCTION = "deduction",
  Add_Deduction_Item = "add_deduction_item", 
  Edit_Deduction_Item = "edit_deduction_item",
  View_Deduction_Library = "view_deduction_library",

  ADD_DEBIT_NOTE ="add_debit_note",
  EDIT_DEBIT_NOTE ="edit_debit_note",

  ADD_PARTY_TO_PARTY_PAYMENT = "add_party_to_party_payment",
  EDIT_PARTY_TO_PARTY_PAYMENT = "edit_party_to_party_payment",

  VIEW_SITE_PHOTOS = "view_site_photos",
  VIEW_SUBCON_SUMMARY = "view_subcon_summary",

  VIEW_MY_QUOTATION = "view_my_quotation",
  VIEW_DETAIL_QUOTATION = "view_detail_quotation",

  service_rate_library = "service_rate_library",
  view_list_service_rate = "view_list_service_rate",
  add_service_rate_library = "add_service_rate_library",
  edit_service_rate_library = "edit_service_rate_library",

  estimate = "estimate",
  view_my_boq = "view_my_boq",
  view_all_boq = "view_all_boq",
  add_new_boq = "add_new_boq",
  edit_boq = "edit_boq",
  view_budget = "view_budget",

  MATERIAL_SALES = "material_sales",
	ADD_MATERIAL_SALES = "add_material_sales",
	EDIT_MATERIAL_SALES = "edit_material_sales",
	VIEW_MATERIAL_SALES = "view_material_sales",

	VIEW_ATTENDANCE = "view_attendance",
	MANAGE_ATTENDANCE = "manage_attendance",
	MANAGE_WORKFORCE = "manage_workforce",
	VIEW_STAFF_ATTENDANCE = "view_staff_attendance",
	VIEW_LABOUR_ATTENDANCE = "view_labour_attendance",

  ADD_ATTENDANCE = "add_attendence",
	EDIT_ATTENDANCE = "edit_attendence",
	ADD_LABOUR_ATTENDANCE = "add_labour_attendance",
	EDIT_LABOUR_ATTENDANCE = "edit_labour_attendance",
	ADD_STAFF_ATTENDANCE = "add_staff_attendance",
	EDIT_STAFF_ATTENDANCE = "edit_staff_attendance",

	ADD_SELF_PUNCH = "add_self_punch",
  
	VIEW_PAYROLL = "view_payroll",
  VIEW_PAYROLL_PEOPLE = "view_payroll_people",
	ADD_WORKFORCE = "add_workforce",
	EDIT_WORKFORCE = "edit_workforce",
	VIEW_WORKFORCE_LIBRARY = "view_workforce_library",
	ADD_STAFF_PAYROLL = "add_staff_payroll",
	EDIT_STAFF_PAYROLL = "edit_staff_payroll",
	ADD_LABOUR_PAYROLL = "add_labour_payroll",
	EDIT_LABOUR_PAYROLL = "edit_labour_payroll",
  
	VIEW_LABOUR_ATTENDANCE_PROJECT = "view_labour_attendance_project",
  ASSOCIATE_PAYROLL_PROJECT = "associate_payroll_project",
  EDIT_PUNCH_SETTINGS = "edit_punch_settings",
  VIEW_PUNCH_SETTINGS = "view_punch_settings",

  VIEW_COMPANY_TRANSACTIONS = "view_company_transactions"
}
