export enum INVOICE_FEATURE_TYPE {
    PARTY_EARNING = "partyearning",
    REIMBURSEMENT = "reimbursement",
    MATERIAL = "material",
    MATERIAL_RETURN = "materialreturn",
    CUSTOMER_INVOICE = "customerinvoice",
    SUBCON_EXPENSE = "subconexpense",
    DEBIT_NOTE = "debitnote",
    MATERIAL_SALE = "materialsale",
    MATERIAL_TRANSFER = "material_transfer"
}

// Define additional fields for each enum type
export type InvoiceFeatureDetails = {
    transaction_name: string;
    image: string;
    approvalUrl: string;
    approvalCommentUrl: string;
};

// Map enum values to their respective details
export const InvoiceFeatureDetailsMap: Record<string, InvoiceFeatureDetails> = {
    [INVOICE_FEATURE_TYPE.PARTY_EARNING]: {
        transaction_name: "Other Expense",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "partyearning/approval",
        approvalCommentUrl: "update/partyearning/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.REIMBURSEMENT]: {
        transaction_name: "Site Expense",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "reimbursement/approval",
        approvalCommentUrl: "update/reimbursement/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.MATERIAL]: {
        transaction_name: "Material Purchase",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/materialpurchase/approval",
        approvalCommentUrl: "edit/materialpurchase/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.MATERIAL_RETURN]: {
        transaction_name: "Material Return",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/materialreturn/approval",
        approvalCommentUrl: "edit/materialreturn/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.CUSTOMER_INVOICE]: {
        transaction_name: "Sales Invoice",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/salesorderinvoice/approval",
        approvalCommentUrl: ""
    },
    [INVOICE_FEATURE_TYPE.SUBCON_EXPENSE]: {
        transaction_name: "Subcon Bill",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/subconexpense/approval",
        approvalCommentUrl: "edit/subconexpense/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.DEBIT_NOTE]: {
        transaction_name: "Debit Note",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "debitnote/approval",
        approvalCommentUrl: "update/debitnote/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.MATERIAL_SALE]: {
        transaction_name: "Material Sales",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/materialsale/approval",
        approvalCommentUrl: "edit/materialsale/approval/comment"
    },
    [INVOICE_FEATURE_TYPE.MATERIAL_TRANSFER]: {
        transaction_name: "Material Transfer",
        image: "../assets/svg/payment-material.svg",
        approvalUrl: "edit/materialsale/approval",
        approvalCommentUrl: "edit/materialsale/approval/comment"
    },
};