<div class="custom-div custom-select d-flex justify-content-between">
  <span class="f-12 disabled-font py-1">Bill To/Ship To</span>
  <span *ngIf="!parent.shipToBillToAddress.bill_from_address_id && !parent.shipToBillToAddress.bill_to_address_id 
  && !parent.shipToBillToAddress.ship_from_address_id && !parent.shipToBillToAddress.ship_to_address_id "
   class="f-14" style="color: #5F44CE; cursor: pointer;" 
  (click)="openShipToBillTo()">+ Add</span>
  <span *ngIf="parent.shipToBillToAddress.bill_from_address_id || parent.shipToBillToAddress.bill_to_address_id 
  || parent.shipToBillToAddress.ship_from_address_id || parent.shipToBillToAddress.ship_to_address_id "
  style="color: #5F44CE; cursor: pointer;" class="f-14" (click)="openShipToBillTo()">
  View</span>
</div>

