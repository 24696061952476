<div class="custom-dropdown" ngbDropdown>
    <button ngbDropdownToggle style="color: rgba(95, 68, 206, 1); height: 44px;" type="button"
        class="d-flex align-items-center gap-2 btn icon-btn">
        <div>
            <ng-icon name="featherSliders" style="transform: scale(1);"></ng-icon>
        </div>
        <span style="font-weight: 400;" class="f-14 mt-1">Filter</span>
    </button>
    <div class="custom-menu " ngbDropdownMenu>
        <div style="padding-bottom: 12px; padding-top: 3px; "
            class="filter-header  d-flex align-items-center justify-content-between px-3  w-100 border-bottom ">
            <div class="d-flex align-items-center gap-2 ">
                <div style="color: rgba(95, 68, 206, 1);">
                    <ng-icon name="featherSliders" style="transform: scale(1);"></ng-icon>
                </div>
                <span style="font-weight: 400; color: rgba(95, 68, 206, 1);" class="f-14 mt-1">Filter</span>
            </div>

            <div>
                <button ngbDropdownItem class="icon-btn btn disabled-font px-1" type="button">
                    <div>
                        <ng-icon name="featherX"></ng-icon>
                    </div>
                </button>
            </div>
        </div>
        <div class="filter-body border-bottom w-100">
            <div class="filter-title-container gap-1 ">
                <ng-container *ngFor="let key of Object.keys(pageFilter)" >
                    <button (click)="selectedFilterSwitch=key" [ngClass]="key==selectedFilterSwitch?'active':''"
                        class="filter-btn disabled-font">
                        <div *ngIf="key==selectedFilterSwitch" class="active-abs"></div>
                        <span class="f-12">
                            {{pageFilter[key].name}}
                        </span>
                    </button>
                </ng-container>



            </div>
            <div [ngSwitch]="selectedFilterSwitch" class="filter-container">


                <ng-template [ngSwitchCase]="'party'">
                    <div class="px-1 my-2">
                        <div class="custom-input">
                            <label>Party Name</label>
                            <input autocomplete="off" [(ngModel)]="partySearchString" [ngbTypeahead]="partySearchFn"
                                #companyUserName />
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                                        stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M20.9999 20.9984L16.6499 16.6484" stroke="#61636F" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="my-2 ps-5 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of members">
                            <div>
                                <div (click)="addRemoveParty(item)"
                                    [ngClass]="pageFilter['party'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer" (click)="addRemoveParty(item)">{{item.name}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'creator'">

                    <div class="my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of creator_companyUsers">
                            <div>
                                <div (click)="addRemoveCreator(item)"
                                    [ngClass]="pageFilter['creator'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer" (click)="addRemoveCreator(item)">{{item.name}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'trade'">
                    <div class="py-2 px-1" style=" height: 100%; overflow-y: auto; " infiniteScroll
                        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500" (scrolled)="onScrollSubCategory()"
                        [scrollWindow]="false" [infiniteScrollDisabled]="valueStackSubCategory.page.next_url == ''">
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of subCategories">
                            <div>
                                <div (click)="addRemoveTrade(item)"
                                    [ngClass]="pageFilter['trade'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer" (click)="addRemoveTrade(item)">{{item.text_en}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'category'">
                    <div class=" my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let item of categories">
                            <div>
                                <div (click)="addRemoveCategory(item)"
                                    [ngClass]="pageFilter['category'].hasSelectedValue.includes(item.id)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer f-12" (click)="addRemoveCategory(item)">{{item.text_en}}
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'date'">
                    <div class="my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center">
                            <div class="custom-input fs-8 pe-2">
                                <label for="qtn-name">Start Date</label>
                                <input class="date-input fs-8" type="date" [(ngModel)]="fromDate"
                                    (change)="addRemoveDate('custom')" [max]="today" />
                            </div>
                            <div class="custom-input fs-8 pe-2">
                                <label for="qtn-name">End Date</label>
                                <input class="date-input fs-8" type="date" [(ngModel)]="toDate"
                                    (change)="addRemoveDate('custom')" [min]="fromDate" [max]="today" />
                            </div>
                        </div>
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(myDateType)">
                            <div>
                                <div (click)="addRemoveDate(key)"
                                    [ngClass]="pageFilter['date'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer f-12" (click)="addRemoveDate(key)">
                                {{myDateType[key].name}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'transaction'">
                    <div class="my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center"
                            *ngFor="let key of Object.keys(myTransactionType)">
                            <div>
                                <div (click)="addRemoveTransaction(key)"
                                    [ngClass]="pageFilter['transaction'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer f-12" (click)="addRemoveTransaction(key)">
                                {{myTransactionType[key].name}}</div>
                        </div>
                    </div>
                </ng-template>


                <!-- *ngIf="pageFilter['transaction'].hasSelectedValue.includes('cashbook_deposit' || 'cashbook_withdraw')" -->
                <ng-template [ngSwitchCase]="'paymentMode'">
                    <div class="my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center" *ngFor="let key of Object.keys(paymentMode)">
                            <div>
                                <div (click)="addRemovePaymentMode(key)"
                                    [ngClass]="pageFilter['paymentMode'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer f-12" (click)="addRemovePaymentMode(key)">
                                {{paymentMode[key].name}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'expenseStatus'">
                    <div class="my-2 px-1 scroll-filter-div">
                        <div class="my-2 d-flex gap-3 align-items-center"
                            *ngFor="let key of Object.keys(expenseStatus)">
                            <div>
                                <div (click)="addRemoveExpense(key)"
                                    [ngClass]="pageFilter['expenseStatus'].hasSelectedValue.includes(key)?'my-checkbox-active':'my-checkbox'">
                                </div>
                            </div>
                            <div class="selection-value pointer" (click)="addRemoveExpense(key)">
                                {{expenseStatus[key].name}}</div>
                        </div>
                    </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'paymentType'">
                   <div class="w-100 h-100  p-2">
                    <ng-container *ngFor="let item of paymentType">
                        <div (click)="addRemovePaymentType(item.param)" class="d-flex align-items-center gap-2 py-2">
                            <input [checked]="item.param === this.pageFilter['paymentType'].hasSelectedValue " type="checkbox">
                            <span class="f-12">{{item.name}}</span>
                        </div>
                    </ng-container>
                   </div>
                </ng-template>
                <ng-template [ngSwitchCase]="'partyType'">
                    <div class="w-100 h-100 p-2">
                     <ng-container *ngFor="let item of partyType">
                         <div (click)="addRemovePartyType(item.param)" class="d-flex align-items-center gap-2 py-2">
                             <input [checked]="item.param === this.pageFilter['partyType'].hasSelectedValue " type="checkbox">
                             <span class="f-12">{{item.name}}</span>
                         </div>
                     </ng-container>
                    </div>
                 </ng-template>

            </div>
        </div>
        <div class="filter-footer d-flex justify-content-between align-items-center px-3 py-2 ">
            <div class="d-flex align-items-center gap-1">
                <button ngbDropdownItem (click)="clearFilter()" class="text-btn btn f-12 p-0">
                    clear Filters
                </button>
                <span
                    style="background-color: #5F44CE; height: fit-content; color: #fff; border-radius: calc(infinity * 1px); font-weight: 400;"
                    class="badge f-10">{{totalSelectedFilterCount}}</span>
            </div>
            <button ngbDropdownItem (click)="applyFilter()" style="background-color: #5F44CE; width: fit-content; border-radius: 4px; color: #fff;" class="solid-btn btn f-12 mt-2">Apply</button>
        </div>
    </div>
</div>