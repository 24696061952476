<div style="position: relative">
  <div
    class="custom-input"
    (click)="filterCompanyProjects()"
    [ngClass]="formInputError == 'projectName' ? 'input-error' : ''"
  >
    <label>{{parentComponent?.inputLabel ? parentComponent?.inputLabel : 'Project Name'}} <div *ngIf="required" style="color: red; margin-left: 2px;">*</div></label>
    <input tabindex="-1"
      type="text"
      [(ngModel)]="project.name"
      [ngbTypeahead]="tradeSearchFn"
      autocomplete="off"
      #projectName
    />

    <span *ngIf="!companyProjectSearchBox && project.name">
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18H19"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.5 1.50023C14.8978 1.1024 15.4374 0.878906 16 0.878906C16.2786 0.878906 16.5544 0.933776 16.8118 1.04038C17.0692 1.14699 17.303 1.30324 17.5 1.50023C17.697 1.69721 17.8532 1.93106 17.9598 2.18843C18.0665 2.4458 18.1213 2.72165 18.1213 3.00023C18.1213 3.2788 18.0665 3.55465 17.9598 3.81202C17.8532 4.06939 17.697 4.30324 17.5 4.50023L5 17.0002L1 18.0002L2 14.0002L14.5 1.50023Z"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>

    <span *ngIf="!companyProjectSearchBox && !project.name">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9999 20.9984L16.6499 16.6484"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span (click)="closeSearchBox($event)" *ngIf="companyProjectSearchBox">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 5L5 15"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5 5L15 15"
          stroke="#61636F"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </div>

  <div class="search-box-outer-div" *ngIf="companyProjectSearchBox">
    <div class="search-box"
      style="height: 400px; overflow-y: auto;"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="500"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
      [infiniteScrollDisabled]="valueStack.page.next_url == ''">
      <div *ngFor="let project of projects; index as i">
        <div *ngIf="project.type != 'w' || (project.type == 'w' && isWarehouse)"
          class="d-flex align-self-center p-3 search-box-row gap-4"
          (click)="setCompanyProject(project)">
          <div class="avatar align-self-center">
            {{ project.name | shortName }}
          </div>
          <div style="position: relative" class="pointer align-self-center">
            <span class="party-name">{{ project.name | truncate : "35" }}</span>
            <span class="fs-8 disabled-font"> ({{project.monkey_patch_primary_address?.city}})</span>
          </div>
        </div>
      </div>
     
    </div>
    <div *ngIf="projects?.length == 0" class="d-flex justify-content-center align-items-center py-2 m-3">
      <span class="fs-8 disabled-font" >No Project Found</span>
    </div>
  </div>
</div>
