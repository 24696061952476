<div class="main-div p-3">
    <div class="d-flex align-items-center justify-content-between header border-bottom pb-3">
        <span class="f-14">Sub-Con Prefix Sequence</span>
        <button (click)="dismissModal()" class="btn icon-btn" type="button">
            <ng-icon name="featherX" class="pointer disabled-font" style="transform: scale(1.5);"></ng-icon>
        </button>
    </div>
    <div class="body py-3 d-flex flex-column gap-3 align-items-end w-100">
     <div class="d-flex align-items-center gap-2 w-100">
        <div class="custom-input f-12 w-50">
            <label for="prefix">Prefix</label>
            <input type="text" [(ngModel)]="componentData.prefix">
          </div>
          <div class="custom-input f-12 w-50">
            <label for="sequence">Sequence</label>
            <input type="number" [(ngModel)]="componentData.sequence">
          </div>
     </div>
     <button (click)="save()" class="save-btn btn f-14 fw-normal" type="button">Save</button>
    </div>
</div>