import { Component, NgZone, ViewChild, ElementRef, Input } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { Address, Company } from 'src/app/interfaces/interfaces';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorService } from 'src/app/shared/services/validator.service';

@Component({
  selector: 'app-address-location',
  templateUrl: './address-location.component.html',
  styleUrls: ['./address-location.component.css'],
})
export class AddressLocationComponent {
  @Input() componentData = {} as { address: Address };
  public map: google.maps.Map;
  public marker: google.maps.Marker;
  private circle: google.maps.Circle;
  public suggestions: Array<any> = [];
  public isLoading = true;
  public defaultLocation = { lat: 28.7041, lng: 77.1025 };
  public addressLocation: any;

  public searchLocation = '';
  public distance = 100;
  public address = {} as Address;
  public company: Company;

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('distanceInput', { static: false }) distanceInput: ElementRef;

  constructor(
    private ngZone: NgZone, 
    private adminService: AdminService, 
    private authService: AuthService,
    private notificationsService: NotificationsService,
    public ngbActiveModal: NgbActiveModal,
    public validatorService: ValidatorService
   
  ) {

    this.company = this.authService.session.company;
    console.log(this.company)
    
  }

  async ngOnInit(): Promise<void> {
    if(this.componentData.address?.location == null){
      this.componentData.address.location = {type: "Point", coordinates: [1.1, 1.1]}
    }
    this.address = JSON.parse(JSON.stringify(this.componentData.address));
    if(this.address?.location?.coordinates[0] !== 1.1){
      this.addressLocation = {
        lat: this.address?.location?.coordinates[1],
        lng: this.address?.location?.coordinates[0]
      }
    }
    this.distance = this.authService.session.project.attendance_radius || 100;
    this.searchLocation = this.address?.google_address || '';
    await this.initMap();
  }

  async initMap(): Promise<void> {
    const position = this.addressLocation || await this.getUserLocation() || this.defaultLocation;

    if(position){
      this.address.location.coordinates = [position.lng, position.lat];
    }

    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 16,
      center: position,
      mapId: 'DEMO_MAP_ID',
      draggable: true,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    });

    this.marker = new google.maps.Marker({
      map: this.map,
      position: position,
      icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
    });

    this.circle = new google.maps.Circle({
      map: this.map,
      center: position,
      radius: this.distance,
      fillColor: '#b1c9ff',
      fillOpacity: 0.2,
      strokeColor: '#4781ef',
      strokeOpacity: 0.5,
      strokeWeight: 2,
    });

    this.map.addListener('drag', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('zoom_changed', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('center_changed', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('dragend', () => this.onMapDragEnd());
    this.isLoading = false;
  }

  getUserLocation(): Promise<google.maps.LatLngLiteral | null> {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.address.location.coordinates = [userPosition.lng, userPosition.lat];
            this.fetchAddress(userPosition.lat, userPosition.lng);
            resolve(userPosition);
          },
          () => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  updateFixedMarkerAndCircle(): void {
    const center = this.map.getCenter();
    if (center) {
      this.marker.setPosition(center);
      this.circle.setCenter(center);
      this.address.location.coordinates = [center.lng(), center.lat()];
    }
  }

  onMapDragEnd(): void {
    const center = this.map.getCenter();
    if (center) {
      this.marker.setPosition(center);
      this.circle.setCenter(center);
      this.address.location.coordinates = [center.lng(), center.lat()];
      this.fetchAddress(center.lat(), center.lng());
    }
  }

  fetchAddress(lat: number, lng: number): void {
    const latlng = `${lat},${lng}`;
    let qp = new HttpParams();
    qp = qp.set('company_id', this.address.company_id || this.company.id);
    qp = qp.set('latlng', latlng);
    this.adminService.mapReverseGeocode(qp).subscribe({
      next: (res) => {
          if (res?.results?.[0]) {
            this.ngZone.run(() => {
              this.address = this.updateAddressFromGoogleMap(res);
              this.searchLocation = this.address.google_address;
            });
          }
      },
      error: (err) => {
        //
      }
    });
  }

  pullData(term: string): Observable<any[]> {
    console.log(term);
    if (term == "" || term.trim().length <= 3) {
      this.suggestions = [];
      return of([]);
    }
    let qp = new HttpParams();
    qp = qp.set('company_id', this.address.company_id || this.company?.id);
    qp = qp.set('input', term);
    return this.adminService.mapAutoComplete(qp).pipe(
      map((res) => {
        this.ngZone.run(() => {
          this.suggestions = res?.predictions || [];
        });
        return [];
      })
    );
  }
  tradeSearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.pullData(term)?.pipe(
          catchError(() => {
            return of([]);
          })
        )
    )
  );

  onSelectSuggestion(prediction: any): void {
    this.searchLocation = prediction.description;
    this.address.google_address = prediction.description;
    let qp = new HttpParams();
    qp = qp.set('company_id', this.address.company_id || this.company.id);
    this.adminService.mapPlaceDetails(prediction.place_id, qp).subscribe({
      next: (res) => {
        const position = {
          lat: res.result.geometry.location.lat,
          lng: res.result.geometry.location.lng,
        };
        this.map.setCenter(position);
        this.marker.setPosition(position);
        this.circle.setCenter(position);
        this.address = this.updateAddressFromGoogleMap(res);
        this.searchLocation = prediction.description;
        this.suggestions = [];
      },
      error: (err) => {
        //
      }
    });
  }

  save(){
    // this.address.attendance_radius = this.distance;
    if(!this.address.google_address || !this.address.google_address || this.address.location.coordinates[0] === 1.1){
      return this.notificationsService.initiate({title:'Please select location', type:toastTypes.error});
    }
    this.ngbActiveModal.close(this.address);
  }
  
  updateAddressFromGoogleMap(googleResponse: any): Address {
    if (!googleResponse || !googleResponse.results?.length) {
      console.error("Invalid Google Maps Response");
      return {} as Address;
    }

    const firstResult = googleResponse.results[0];
    const components = firstResult.address_components;

    const address: Address = {
      address_line_1: "",
      city: "",
      state: "",
      postal_code: "",
      country_code: "",
      google_address: firstResult.formatted_address,
      location: {
        type: "Point",
        coordinates: [firstResult.geometry.location.lng, firstResult.geometry.location.lat], // Longitude first, then Latitude
      }
    };

    const addressParts: string[] = []; // Store all parts before city

    for (const component of components) {
      if (component.types.includes("locality")) {
        address.city = component.long_name;
        break; // Stop processing as we found city
      }
      addressParts.push(component.long_name);
    }

    // Join all collected parts as address_line_1
    address.address_line_1 = addressParts.join(", ");

    // Continue extracting state, postal_code, country_code
    for (const component of components) {
      if (component.types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      }
      if (component.types.includes("postal_code")) {
        address.postal_code = component.long_name;
      }
      if (component.types.includes("country")) {
        address.country_code = component.short_name;
      }
    }

    return address;
  }

}
