<div class="canvas-height">
    <div class="offcanvas-header d-flex align-items-center px-4">
        <div class="canvas-header-left d-flex align-items-center gap-4">
          <div class="pointer m-0 p-0" >
            <app-back-button></app-back-button>
          </div>
          <div class="canvas-title">
            <span class="f-14">Expense Analysis by category</span>
          </div>
        </div>
        <!-- <div class="d-flex gap-4">
          <div class="canvas-cancel-btn" >
            Cancel
          </div>
          <div class="canvas-save-btn" >Save</div>
        </div> -->
      </div>
      <div style="height: 7px; background-color: rgba(95, 68, 206, 1);" class="w-100"></div>
      <div style="padding: 32px 32px 0px 32px;" class="canvas-body">
           <ng-container >
            <div *ngFor="let item of chartDataList; let j = index" class="w-100 d-flex align-items-center justify-content-between my-3">
               <div class="d-flex align-items-center gap-3">
                 <div [style.background-color]="chartDataList[j].color" class="rec-avatar"></div>
                 <span class="f-14">{{item?.label}}</span>
               </div>
               <span class="f-14">₹{{item?.value|dynamicNumber: {currency:1} }}</span>
            </div>
           </ng-container>
      </div>
</div>
