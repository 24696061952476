import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Config } from 'src/app/config/config.config';
import { AuthService } from 'src/app/core/services/auth.service';
import { Address, Company, Profile } from 'src/app/interfaces/interfaces';
import { LoaderServiceTsService } from './loader.service.ts.service';
@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public qp = new HttpParams();
  public profile = {} as Profile;

  public admin_BehavioSubject = new BehaviorSubject(false);
  public observerAdmin$ = this.admin_BehavioSubject.asObservable();

  private companiesBehavioSubject = new BehaviorSubject([] as Company[]);
  public observeCompanies$ = this.companiesBehavioSubject.asObservable();

  private companyBehavioSubject = new BehaviorSubject({} as Company);
  public observeCompany$ = this.companyBehavioSubject.asObservable();

  public premiumUserPopupBehaviorSubject = new BehaviorSubject(false);
  public isNotPremium$ = this.premiumUserPopupBehaviorSubject.asObservable();

  public policy_ids = []
  public companies = [] as Company[];
  constructor(
    private http: HttpClient,
    private config: Config,
    private authService: AuthService,
    private loaderServiceTsService: LoaderServiceTsService,
  ) {
    this.profile = authService.getProfile() || {} as Profile;
  }

  getAdminCompanyId(monkey_patch_company_user: any, current_company_id?: string): string {
    for (const item of monkey_patch_company_user) {
      if (item.company_role_id == 'id1' && item.company_id == current_company_id) {
        return current_company_id;
      } else if (item.company_role_id == 'id1') {
        return item.company_id;
      }
    }
    return null
  }

  setCompanyDetails(company: Company) {
    this.policy_ids = company.monkey_patch_my_company_user.monkey_patch_policy_ids || [];
    this.loaderServiceTsService.updateLoader(false);
    this.checkCompanySubscription(company);
    this.checkRoleInCompany(company.monkey_patch_my_company_user.company_role_id)
    this.companyBehavioSubject.next(company);
  }

  checkRoleInCompany(company_role_id) {
    if (company_role_id == 'id1') {
      return this.admin_BehavioSubject.next(true);
    } else {
      return this.admin_BehavioSubject.next(false);
    }
  }

  checkCompanySubscription(company?: Company) {
    if (!company) {
      company = this.authService.session.company;
    }
    if (company.is_paid == 0) {
      //Unpaid company
      this.premiumUserPopupBehaviorSubject.next(true);
    } else {
      //Paid company
      const now = new Date();
      const valid_till = new Date(company.subscription_end_date);
      if (valid_till <= now) {
        this.premiumUserPopupBehaviorSubject.next(true);
        return;
      }
      this.premiumUserPopupBehaviorSubject.next(false);
    }
  }

  /*
  API's
  */

  getNotificationList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `${this.config.userEndpoint}/list/notification`,
      {params}
    )
  }

  readNotification(id:string): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/read/notification/${id}`, {})
  }

  getCompanyProjects(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/project`, { params })
  }

  getPaymentInData(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/party/cashbookentry`, { params })
  }

  getPaymentOutData(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/party/cashbookentry`, { params })
  }

  getSalaryData(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/attendance`, { params })
  }
  getMaterialPurchaseData(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/party/materialpurchase`, { params })
  }
  getMaterialReturnList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/party/materialreturn`, { params })
  }
  getOtherExpenseData(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/partyearning`, { params })
  }
  getReimbursementList(params?: HttpParams): Observable<any> {
    return this.http.get(`${this.config.userEndpoint}/list/reimbursement`, { params })
  }
  getSalesInvoiceList(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/salesorderinvoice`, {params})
  }
  getSubconExpenseListPrjectLevel(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/subconexpense/projectlevel`, {params})
  }
  getDeductionListPrjectLevel(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/deductionentry/projectlevel`, {params})
  }
  getDebitNoteListPrjectLevel(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/debitnote/projectlevel`, {params})
  }
  getMaterialSaleListPrjectLevel(params?:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/materialsale/projectlevel`, {params})
  }
  getBankDetails(ifsc:string):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/detail/ifsc/${ifsc}`, {})
  }

  addBankAccount(data):Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/companybankaccount`, data, {})
  }

  editBankAccount(data):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/companybankaccount`, data, {})
  }

  getPartySelectedTabDetails(params:HttpParams, url:string):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}${url}`, {params})
  }

  getCompanyLevelCashbookentry(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/cashbookentry/companylevel`, {params})
  }

  getCompanyLevelMaterialPurchase(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/materialpurchase/companylevel`, {params})
  }
  getCompanyLevelMaterialReturn(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/materialreturn/companylevel`, {params})
  }

  getCompanyLevelReimbursement(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/reimbursement/companylevel`, {params})
  }

  getCompanyLevelSalesInvoiceOrder(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/salesorderinvoice/companylevel`, {params})
  }

  getCompanyLevelSubconExpense(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/subconexpense/companylevel`, {params})
  }

  getCompanyLevelDeductions(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/deductionentry/companylevel`, {params})
  }
  getCompanyLevelDebitNote(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/debitnote/companylevel`, {params})
  }
  getCompanyLevelMaterialSale(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/party/materialsale/companylevel`, {params})
  }

  getCompanyLevelPartyPendingCount(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/transaction/party/pending/count/companylevel`,{params})
  }

  getCompanyLevelPartySalary(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/attendance/companylevel`, {params})
  }

  getCompanyLevelPartyEarning(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/partyearning/companylevel`, {params})
  }

  searchCompanyParty(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/search/companyuser/ledger`, {params})
  }

  getCostCode(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/subcategory`,{params})
  }

  addCostCode(data):Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/subcategory`, data, {})
  }
  
  getListWorkforce(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/list/workforce-type-wise`,{params})
  }

  addWorkForce(data):Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/workforce`, data, {})
  }

  editWorkForce(data):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/workforce`, data, {})
  }

  editWorkForceStock(data):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/workforcestock`, data, {})
  }

  searchWorkForce(params:HttpParams):Observable<any>{
    return this.http.get(`${this.config.userEndpoint}/search/workforce`,{params})
  }

  

  addCompanyUserbankAccount(data):Observable<any>{
    return this.http.post(`${this.config.userEndpoint}/add/companyuserbankaccount`, data, {})
  }

  editCompanyUserbankAccount(data):Observable<any>{
    return this.http.patch(`${this.config.userEndpoint}/edit/companyuserbankaccount`, data, {})
  }
  
  concatProject(projectAddress:Address):string{
    let projectAddress_line_1_2_city_state = projectAddress.address_line_1
    if(projectAddress.city){
      projectAddress_line_1_2_city_state += ' ,' + projectAddress.city
    }
    if(projectAddress.state){
      projectAddress_line_1_2_city_state += ' ,' + projectAddress.state
    }
    return projectAddress_line_1_2_city_state
  }
}

