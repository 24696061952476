<div style="padding: 30px 40px 0px 40px;">
  <div class="d-flex flex-column">
    <div class="d-flex" style="margin-bottom:20px;">
      <div class="f-18" style="font-weight: 500;">Project Setting</div>
      <div class="ms-auto">
        <svg class="pointer" (click)="activeModal.dismiss();" width="24" height="24" viewBox="0 0 24 24" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6 6L18 18" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <!-- <div class="project-tabs">
          <ul>
            <li [ngClass]="projectTab=='projectDetails'?'active':''" (click)="projectTab='projectDetails'">
              Project Details
              <span class="active_tab_line"></span>
            </li>
            <li [ngClass]="projectTab=='member'?'active':''" (click)="projectTab='member'">Members</li>
            <li [ngClass]="projectTab=='clientDetails'?'active':''" (click)="projectTab='clientDetails'">Client Details
            </li>
          </ul>
        </div> -->

    <div class="tabs_container">
      <div class="tabs_container_content p-0" style="margin-left: 0 !important;">
        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab=='projectDetails'?'active':''"
          (click)="projectTab='projectDetails'">
          Project Details
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab=='member'?'active':''"
          (click)="projectTab='member'">
          Members
          <div class="active_tab_line"></div>
        </span>

        <span class="cursor single_tab text-muted custom-text" [ngClass]="projectTab=='clientDetails'?'active':''"
          (click)="projectTab='clientDetails'">
          Client Details
          <div class="active_tab_line"></div>
        </span>
      </div>
    </div>

  </div>
</div>
<div style="border-top: 1px solid #E5E7F3; margin-bottom: 36px;"></div>
<div style="padding: 0px 40px 30px 40px;">
  <div class="d-flex flex-column gap-1">
    <ng-template [ngIf]="projectTab=='projectDetails'">

      <div class="row gx-1">
        <div class="col-3 col-lg-2 d-flex justify-content-start align-items-start p-0">
          <div class="project-photo">
            <div class="project-name">{{project.name | shortName}}</div>
          </div>
        </div>
        <div class="col-9 col-lg-10 p-0">
          <div class="row gx-3 gy-3">
            <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
              <div class="custom-input mb-3">
                <label class="custom-text">Project Name</label>
                <input class="custom-text" placeholder="Project Name" type="text" autocomplete="off" [(ngModel)]="project.name" />
              </div>
              <div (click)="setProjectLocation()" class="custom-div mb-3" style="background: transparent !important;">
                <label class="custom-text">Project Location</label>
                <span class="f-14 disabled-font">{{this.project.address || 'Select Location' | truncate : '30'}}</span>
                <div>
                  <ng-icon class="disabled-font" name="featherCrosshair"></ng-icon>
                </div>
              </div>
              <div class="custom-input mb-3">
                <label class="custom-text">Project Address</label>
                <input class="custom-text" placeholder="Address" type="text" autocomplete="off" [(ngModel)]="project.address" />
              </div>
            </div>

            <div class="col-sm-12 col-xs-12 col-md-6 col-lg-6">
              <div class="f-14 datepicker-hide-input calender mb-3 position-relative">
                <label class="custom-text date-input-label">Start Date</label>
                <input class="m-0 date-input w-100" tabindex="-1" placeholder="Start Date" [(ngModel)]="model_s_date"
                  ngbDatepicker #s_d="ngbDatepicker" />
                <div class="pointer ms-3 p-2" style=" position: absolute; top: 18%; right: 0;" (click)="s_d.toggle()">
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>

              <div class="f-14 datepicker-hide-input calender mb-3 position-relative">
                <label class="custom-text date-input-label">End Date</label>
                <input class="m-0 date-input w-100" tabindex="-1" placeholder="End Date" [(ngModel)]="model_e_date"
                  ngbDatepicker #e_d="ngbDatepicker" />
                <div class="pointer ms-3 p-2" style=" position: absolute; top: 18%; right: 0;" (click)="e_d.toggle()">
                  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L13 1" stroke="#61636F" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>

              <div class="custom-input mb-3">
                <label class="custom-text">Project Value</label>
                <input class="custom-text" type="text" autocomplete="off" [ngModel]="project.estimated_cost | dynamicNumber: {digit:6}" (input)="project.estimated_cost = validatorService.numberFormat($event, {digit:6, decimal:true, type: 'number'})" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="projectTab=='member'">
      <div class="modal-container" style=" max-height: 60vh !important;">
        <div class="d-flex flex-column pt-2">
          <app-party-input [parentComponent]="{pageFrom:'project-setting'}"
            (onCompanyUserSelect)="setCompanyUser($event)"></app-party-input>
        </div>

        <div class="vstack" style="overflow-x:hidden; overflow-y: auto; height: 30vh;">
          <div>
            <span class="member-count">
              <!-- 2 Members, 1 Pending -->
            </span>
          </div>
          <div *ngFor="let item of members; let i = index;">
            <ng-container>
              <div class="d-flex flex-column" style="position: relative !important;">
                <div class="d-flex my-1 pointer member-card p-2" [ngClass]="member.id===item.id?'member-card-active':''"
                  (click)="openAccessBox(item); companySearchBox=false" id="dropdownManual">
                  <div class="align-self-center">
                    <div [ngClass]="item.role=='manager'?'avatar-manager':'avatar-admin'" class="avatar"
                      style="font-size:16px !important;">
                      {{item.monkey_patch_company_user.name | shortName}}
                    </div>
                  </div>
                  <div class="ms-4">
                    <span class="member-name">
                      {{item.monkey_patch_company_user.name}}
                      <span *ngIf="project.contractor_company_user_id==item.company_user_id" class="fs-8">
                        (Super Admin)
                      </span>
                    </span>
                    <br>
                    <span class="member-mobile">
                      {{item.monkey_patch_company_user.mobile}}
                    </span>
                  </div>
                  <div class="ms-auto">
                    <span class="member-mobile">
                      {{item.monkey_patch_company_user.monkey_patch_company_role.name}}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>


      <div *ngIf="accessBox" class="access-box-close" (click)="accessBox=false"></div>
      <div class="access-box p-1" *ngIf="accessBox" [ngClass]="companyUser.role=='admin'? 'top-38-percent':''">

        <div *ngFor="let role of companyRoles">
          <div class="d-flex access-box-items pointer py-2" (click)="setMemberAccess(role); accessBox=false">
            <div class="d-flex justify-content-center align-items-center" style="width: 50px">
              <div *ngIf="companyUser.company_role_id==role.id" class="active-tick"></div>
            </div>
            <div class="d-flex flex-column ">
              <span style="font-weight: 500;" class="access-title mb-1">{{role.name}}</span>
              <span class="btn-desc" *ngIf="role.id=='id1'">Admin has complete
                access.</span>
              <span class="btn-desc" *ngIf="role.id=='id2'"> Manager has
                limited access.</span>
            </div>
          </div>
        </div>
        <div>
          <div *ngIf="project.creator_company_user_id!=companyUser.id" class="d-flex access-box-items pointer py-2"
            (click)="deleteTeamMember()">
            <div style="width: 50px;" class="p-2">
            </div>
            <div class="d-flex flex-column">
              <span style="font-weight: 500;" class="remove mb-1">
                Remove
              </span>

              <span class="btn-desc">
                Remove from project.
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="projectTab=='clientDetails'">

      <div class="row">
        <div class="col-3 d-flex flex-column justify-content-start align-items-center">
          <div class="project-client-photo">
            <img *ngIf="project.customer_profile_image" [src]="project.customer_profile_image" alt="">
            <div class="upload__wrap">
              <input class="cursor" id="picInput" type="file" [(ngModel)]="tempImg"
                (change)="replaceImg($event,project, 'customer_profile_image')" hidden />
            </div>
          </div>
          <div class="mt-3">
            <span class="project-img-replace-btn" (click)="selectFileInput()">Replace Image</span>
          </div>
        </div>
        <div class="col-9">
          <div class="row gx-3 gy-3">
            <div class="col-6">
              <div class="custom-input ">
                <label class="custom-text">Client Name</label>
                <input class="custom-text" type="text" name="clientName" autocomplete="off"
                  [(ngModel)]="project.customer_name" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input ">
                <label class="custom-text">Client Mobile Number</label>
                <input class="custom-text" type="number" name="clientMobile" autocomplete="off"
                  [(ngModel)]="project.customer_contact" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input ">
                <label class="custom-text">Client Company Name</label>
                <input class="custom-text" type="text" name="clientCompanyName" autocomplete="off"
                  [(ngModel)]="project.customer_company_name" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input ">
                <label class="custom-text">Company Address</label>
                <input class="custom-text" type="text" name="clientCompanyAddress" autocomplete="off"
                  [(ngModel)]="project.customer_company_address" />
              </div>
            </div>
            <div class="col-6">
              <div class="custom-input ">
                <label class="custom-text">Company
                  {{authService.session.country?.tax_display_name?authService.session.country?.tax_display_name:'Tax'}}
                  Number</label>
                <input class="custom-text" type="text" name="clientCompanyGst" autocomplete="off"
                  [(ngModel)]="project.customer_gst" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="d-flex gap-2 justify-content-between">
          <div class="d-flex flex-column align-items-center gap-2">

          </div>
          <div class="d-flex flex-column gap-4 ms-auto">
            <div class="d-flex gap-4">


            </div>
            <div class="d-flex gap-4">


            </div>
            <div class="d-flex gap-4">

            </div>
          </div>
        </div> -->
    </ng-template>


    <div class="mt-auto d-flex justify-content-between" style="margin-top: 40px !important;"
      *ngIf="projectTab=='projectDetails' || projectTab=='clientDetails'">

      <div *ngIf="projectTab=='projectDetails'">
        <button class="project-btn" (click)="deleteProject(); projectStatusBox=false">Delete</button>

      </div>
      <div class="ms-auto align-self-center">
        <button class="btn btn-outline-danger f-12" type="button" (click)="deleteProject()">Delete Project</button>
        <!-- <span (click)="activeModal.dismiss()" class="project-btn cancel">Cancel</span> -->
        <button class="project-btn save" (click)="updateProjectDetails()">Save</button>
      </div>

    </div>
  </div>
</div>