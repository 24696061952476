import { HttpParams } from "@angular/common/http";
import { Component, Input, OnInit, signal } from "@angular/core";
import {
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { AdminService } from "src/app/core/services/admin.service";
import { Project } from "src/app/interfaces/interfaces";
import { AuthService } from "src/app/core/services/auth.service";
import { Title } from "@angular/platform-browser";
import { FormatType, REPORT_FEATURE_TYPE, ReportFeatureDetails, ReportFeatureDetailsMap, ReportType } from "src/app/enum/report_feature";

@Component({
  selector: "app-report-modal",
  templateUrl: "./report-modal.component.html",
  styleUrls: ["./report-modal.component.css"],
})
export class ReportModalComponent implements OnInit {
  @Input() parent = {} as {type:REPORT_FEATURE_TYPE, queryParams:({key:string, value:string})[]};

  public reportFeatureDetail = {} as ReportFeatureDetails;
  public project = {} as Project;
  party_id: string;
  dateRange = "";
  loading = false;
  public filter = false;
  public dataFromFilter: any = {};
  public filterParams = new HttpParams();
  public reportType = signal<ReportType>({} as ReportType);
  public downloadPdfBtn = false;
  public downloadCsvBtn = false;
  public dateSelector = [];
  public startDate?: Date;
  public endDate?: Date;
  public report_duration_title?: string = "All Time Date";
  public qp;
  public datePicker: Object = {
    fromDate: new Date(),
    toDate: new Date(),
    show: false
  }
  constructor(
    public activeModal: NgbActiveModal,
    private adminService: AdminService,
    private authService: AuthService,
    public title: Title
  ) {
    title.setTitle("Project Report");
   
  }
  ngOnInit(): void {
    this.project = this.authService.session.project;
    this.reportFeatureDetail = ReportFeatureDetailsMap[this.parent.type];
    this.setReportType(this.reportFeatureDetail.reportType[0])
  }
  assignDateSelector() {
    this.dateSelector = [
      { key: "all", value: "All" },
      { key: "today", value: "Today" },
      { key: "lastWeek", value: "Last Week (Mon - Sun)" },
      { key: "lastMonth", value: "Last Month" },
      { key: "thisWeek", value: "This Week (Mon - Today)" },
      { key: "thisMonth", value: "This Month" },
      { key: "custom", value: "Custom" },
    ];
    if (this.reportType()?.excludeDateRange?.length > 0) {
      this.dateSelector = this.dateSelector.filter(i => !this.reportType().excludeDateRange.includes(i.key));
    }
  }

  dismiss(str) {
    this.activeModal.dismiss("Hi");
  }
  setReportType(reportType: ReportType) {
    this.reportType.set(reportType);
    if (reportType.defaultDateRange) this.dateRangeSet(reportType.defaultDateRange);
    this.assignDateSelector();
  }
  excludeDateRangeSet() {
    
  }
  dateRangeSet(e) {
    this.dateRange = e;
    const cDate = new Date();
    let d;
    let date = new Date();
    switch (e) {
      case "custom":
        this.datePicker['show'] = true;
        break;

      case "all":
        this.report_duration_title = "All Time";
        this.startDate = null;
        this.endDate = null;
        break;

      case "today":
        this.startDate = cDate;
        this.endDate = cDate;
        this.report_duration_title = "Today";
        break;

      case "lastWeek":
        this.report_duration_title = "Last Week (Mon-Sun)";
        const prevMonday = new Date();
        prevMonday.setDate(
          prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
        );
        d = new Date().getDate() - prevMonday.getDate();
        d < 7 ? prevMonday.setDate(prevMonday.getDate() - 7) : prevMonday;
        this.startDate = prevMonday;
        this.endDate = new Date(prevMonday);
        this.endDate.setDate(prevMonday.getDate() + 6);
        break;

      case "lastMonth":
        this.report_duration_title = "Last Month";
        this.startDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        this.endDate = new Date(date.getFullYear(), date.getMonth(), 0);
        break;

      case "thisWeek":
        this.report_duration_title = "This Week";
        const main = new Date();
        d = main.getDay();
        const mon = new Date(main);
        this.startDate = new Date(mon.setDate(mon.getDay() > 0 ? mon.getDate() - d + 1 : mon.getDate() - 6));
        this.endDate = new Date();
        break;

      case "thisMonth":
        this.report_duration_title = "This Month";
        this.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.endDate = new Date();
        break;
    }
    if (this.startDate) {
      const date = new Date(this.startDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (this.endDate) {
      const date = new Date(this.endDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }

  download(formatType: FormatType){
    this.filterParams = new HttpParams();
    this.startDate
      ? (this.filterParams = this.filterParams.set(
        "start_date",
        this.startDate.toISOString()
      ))
      : null;
    this.endDate
      ? (this.filterParams = this.filterParams.set(
        "end_date",
        this.endDate.toISOString()
      ))
      : null;
    if(this.parent.queryParams.length > 0){
      this.parent.queryParams.forEach(i=>{
        this.filterParams = this.filterParams.set(i.key, i.value)
      })
    }
    this.loading = true;
    let url = `${this.reportType().formatUrl[formatType]}/${this.project.id}`;
    this.adminService.genrateProjectLevel(this.filterParams, url).subscribe({
      next:(res)=>{
        this.loading = false;
        window.open(res.url, "_blank");
      },
      error:(err)=>{
        this.loading = false;
        console.log("Somthing wrong happen");
      }
    }); 
  }

  setDate(e) {
    this.datePicker['show'] = false;
      this.report_duration_title = "Custom";
    if (e.fromDate) {
      const date = new Date(e.fromDate);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0, 0);
      this.startDate = new Date(date);
    }
    if (e.toDate) {
      const date = new Date(e.toDate);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59, 999);
      this.endDate = new Date(date);
    }
  }
  
}
