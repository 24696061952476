export const formats = ["pdf", "csv"] as const; //Introduce new format here and have melody
export type FormatType = typeof formats[number];

// Introduce new report feature here and have melody
export enum REPORT_FEATURE_TYPE {
  PARTY,
  PARTY_PROJECT_TRANSACTION,
  EQUIPMENT_STOCK,
  ATTENDANCE_V3,
  ATTENDANCE_V4,
  MATERIAL,
  PAYMENT,
  TASK
}

export type FormatUrl = Partial<Record<FormatType, string>>;

export interface ReportType {
  name: string;
  format: FormatType[];
  formatUrl: FormatUrl;
  defaultDateRange: string;
  excludeDateRange: ("all"|"today"|"lastWeek"|"lastMonth"|"thisWeek"|"thisMonth"|"custom")[];
}


export interface ReportFeatureDetails {
  name: string;
  reportType: ReportType[];
}

// Introduce new report feature details here and have melody
export const ReportFeatureDetailsMap: Record<REPORT_FEATURE_TYPE, ReportFeatureDetails> = {
  [REPORT_FEATURE_TYPE.PARTY]: {
    name: "Party",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Party Balance",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/party-balance"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.PARTY_PROJECT_TRANSACTION]: {
    name: "Party Project Transaction",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Party Project Transaction",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/party-transanction"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.EQUIPMENT_STOCK]: {
    name: "Equipment Stock",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Equipment Stock",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/equipmentstock/pdf"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.ATTENDANCE_V3]: {
    name: "Attendance",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Salary",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/attendance-salary"
            },
        },
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Attendance",
            format: ["pdf", "csv"],
            formatUrl: {
                pdf: "generate-report/attendance-operation",
                csv: "generate-report/attendance-csv"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.ATTENDANCE_V4]: {
    name: "Attendance",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Salary",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/payroll-attendance-salary"
            },
        },
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Attendance",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/payroll-attendance-operation",
                csv: "generate-report/payroll-attendance-csv"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.MATERIAL]: {
    name: "Material",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Material Inventory",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/material-pdfbydate"
            },
        },
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Material Purchase",
            format: ["pdf", "csv"],
            formatUrl: {
                pdf: "generate-report/material-purchase-report",
                csv: "generate-report/material-csv"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.PAYMENT]: {
    name: "Payment",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Payment",
            format: ["pdf", "csv"],
            formatUrl: {
                pdf: "generate-report/wallet/pdf",
                csv: "generate-report/cashbook-csv"
            },
        },
        {
            excludeDateRange:[],
            defaultDateRange:'all',
            name: "Summary",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/wallet-summary/pdf"
            },
        },
    ],
  },
  [REPORT_FEATURE_TYPE.TASK]: {
    name: "Task",
    reportType: [
        {
            excludeDateRange:[],
            defaultDateRange:'today',
            name: "DPR",
            format: ["pdf"],
            formatUrl: {
                pdf: "generate-report/dpr/pdf"
            },
        },
    ],
  },
};