
import { Component, OnInit, Input } from '@angular/core';
import {  NgbActiveOffcanvas, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SelectCostCodeComponent } from '../select-cost-code/select-cost-code.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { Company, Subcategory } from 'src/app/interfaces/interfaces';
import { CompanyService } from 'src/app/company/services/company.service';
import { AdminService } from 'src/app/core/services/admin.service';
import { Subcategory_type } from 'src/app/enum/enum';

interface NewCostCode {
  company_id?:string;
  parent_id?:string;
  type?:string;
  text_en?:string;
  text_hi?:string;
}

interface Parent{
  sub_category_id?:string;
  type?:Subcategory_type
}
@Component({
  selector: 'app-add-cost-code',
  templateUrl: './add-cost-code.component.html',
  styleUrls: ['./add-cost-code.component.css']
})
export class AddCostCodeComponent implements OnInit{
  @Input() parent = {} as Parent;
  public subCostCode = false;
  public parentCostCode:string;
  public costCodeName:string;
  private company:Company = {};

  public subCategory = {} as Subcategory;
  public parentSubCategory = {} as Subcategory;
  public subcategory_type = Subcategory_type
  constructor(
    private ngbOffcanvas: NgbOffcanvas,
    private ngbOffActiveCanvas:NgbActiveOffcanvas,
    private authService: AuthService,
    private companyService: CompanyService,
    private adminService: AdminService

  ){
    this.company = this.authService.session.company;
  }


  ngOnInit(): void {
    if(this.parent?.sub_category_id){
      this.getSubCategoryDetails(this.parent.sub_category_id)
    }else{
      this.subCategory.company_id = this.company.id || '';
    }
  }

  getSubCategoryDetails(id?:string){
    this.adminService.subcategoryDetails(id).subscribe({
      next:(res)=>{
        this.subCategory = res || {};
        if(this.subCategory.parent_id){
          this.subCostCode = true;
          this.parentSubCategory = res.monkey_patch_parent || {};
        }
      },
      error:()=>{
        //
      }
    })
  }

  toggleCheckbox(e:any){
    this.subCostCode = e.target.checked;
    if(!this.subCostCode){
      this.parentSubCategory = {};
      this.subCategory.parent_id = '';
    }
  }

  selectParentCostCode(){
    const offCanvasRef = this.ngbOffcanvas.open(SelectCostCodeComponent, { position: 'end', backdrop:'static',   panelClass:'offcanvas-sm' });
    offCanvasRef.componentInstance.parent = {height:0, is_add_new:false}
    offCanvasRef.result.then(res=>{
      // this.parentCostCode = res.name;
      // this.parent_id =  res.id;
      this.parentSubCategory.text_en = res.sub_cat_text_en || '';
      this.subCategory.parent_id = res.sub_category_id || '';
    }, rej=>{
      //
    })
  }

  saveNewCostCode(){
    if(this.subCategory.text_en == undefined){
      return alert('Cost code name required');
    }
    if(this.parent.sub_category_id){
      return this.editNewCostCode();
    }

    if(this.parent.type){
      this.subCategory.type = this.parent.type
    }
    this.companyService.addCostCode(this.subCategory).subscribe({
      next:res=>{
        this.ngbOffActiveCanvas.close(res);
      },
      error:err=>{
        //
      }
    })
  }

  editNewCostCode(){
    this.adminService.editSubcategory(this.subCategory).subscribe({
      next:(res)=>{
        this.ngbOffActiveCanvas.close(res);
      },
      error:()=>{
        //
      }
    })
  }
}
