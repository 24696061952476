import { Component, NgZone, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdminService } from 'src/app/core/services/admin.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable, catchError, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { error } from 'console';
import { Company, Project } from 'src/app/interfaces/interfaces';
import { NotificationsService, toastTypes } from 'src/app/core/services/notifications.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorService } from 'src/app/shared/services/validator.service';

@Component({
  selector: 'app-add-project-location',
  templateUrl: './add-project-location.component.html',
  styleUrls: ['./add-project-location.component.css'],
})
export class AddProjectLocationComponent {
  public map: google.maps.Map;
  public marker: google.maps.Marker;
  private circle: google.maps.Circle;
  public suggestions: Array<any> = [];
  public isLoading: boolean = true;
  public defaultLocation = { lat: 28.7041, lng: 77.1025 };
  public projectLocation: any;

  public searchLocation: string = '';
  public distance: number = 100;
  public project: Project;
  public company: Company;

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('distanceInput', { static: false }) distanceInput: ElementRef;

  constructor(
    private ngZone: NgZone, 
    private adminService: AdminService, 
    private authService: AuthService,
    private notificationsService: NotificationsService,
    public ngbActiveModal: NgbActiveModal,
    public validatorService: ValidatorService
   
  ) {
    
  }

  async ngOnInit(): Promise<void> {
    this.project = {...this.authService.session.project};
    this.company = {...this.authService.session.company};
    if(this.project?.location?.coordinates[0] !== 1.1){
      this.projectLocation = {
        lat: this.project?.location?.coordinates[0], 
        lng: this.project?.location?.coordinates[1]
      }
    }
    this.distance = this.project?.attendance_radius || 100;
    this.updateCircleRadius();
    await this.initMap();
  }

  async initMap(): Promise<void> {
    const position = this.projectLocation || await this.getUserLocation() || this.defaultLocation;

    if(position){
      this.project.location.coordinates = [position.lat, position.lng];
    }

    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById('map') as HTMLElement, {
      zoom: 16,
      center: position,
      mapId: 'DEMO_MAP_ID',
      draggable: true,
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    });

    this.marker = new google.maps.Marker({
      map: this.map,
      position: position,
      icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
    });

    this.circle = new google.maps.Circle({
      map: this.map,
      center: position,
      radius: this.distance,
      fillColor: '#b1c9ff',
      fillOpacity: 0.2,
      strokeColor: '#4781ef',
      strokeOpacity: 0.5,
      strokeWeight: 2,
    });

    this.map.addListener('drag', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('zoom_changed', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('center_changed', () => this.updateFixedMarkerAndCircle());
    this.map.addListener('dragend', () => this.onMapDragEnd());
    this.isLoading = false;
  }

  getUserLocation(): Promise<google.maps.LatLngLiteral | null> {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.project.location.coordinates = [userPosition.lat, userPosition.lng];
            this.fetchAddress(userPosition.lat, userPosition.lng);
            resolve(userPosition);
          },
          () => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  }

  updateFixedMarkerAndCircle(): void {
    const center = this.map.getCenter();
    if (center) {
      this.marker.setPosition(center);
      this.circle.setCenter(center);
      this.project.location.coordinates = [center.lat(), center.lng()];
    }
  }

  onMapDragEnd(): void {
    const center = this.map.getCenter();
    if (center) {
      this.marker.setPosition(center);
      this.circle.setCenter(center);
      this.project.location.coordinates = [center.lat(), center.lng()];
      this.fetchAddress(center.lat(), center.lng());
    }
  }


  updateCircleRadius(): void {
    if (this.circle && !isNaN(this.distance) && this.distance > 0) {
      this.circle.setRadius(this.distance);
      this.project.attendance_radius = this.distance;
    }
  }


  fetchAddress(lat: number, lng: number): void {
    const latlng = `${lat},${lng}`;
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    qp = qp.set('latlng', latlng);
    this.adminService.mapReverseGeocode(qp).subscribe((res) => {
      if (res?.results?.[0]) {
        const address = res.results[0].formatted_address;
        this.ngZone.run(() => {
          this.project.address = address;
          this.searchLocation = address;
        });
      }
    }, error => {
      //
    });
  }

  pullData(term: string): Observable<any[]> {
    console.log(term);
    if (term == "" || term.trim().length <= 3) {
      this.suggestions = [];
      return of([]);
    }
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    qp = qp.set('input', term);
    return this.adminService.mapAutoComplete(qp).pipe(
      map((res) => {
        this.ngZone.run(() => {
          this.suggestions = res?.predictions || [];
        });
        return [];
      })
    );
  }
  tradeSearchFn = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.pullData(term)?.pipe(
          catchError(() => {
            return of([]);
          })
        )
    )
  );

  onSelectSuggestion(prediction: any): void {
    this.searchLocation = prediction.description;
    this.project.address = prediction.description;
    let qp = new HttpParams();
    qp = qp.set('company_id', this.company.id);
    this.adminService.mapPlaceDetails(prediction.place_id, qp).subscribe((res) => {
      const position = {
        lat: res.result.geometry.location.lat,
        lng: res.result.geometry.location.lng,
      };
      this.map.setCenter(position);
      this.marker.setPosition(position);
      this.circle.setCenter(position);
      this.searchLocation = prediction.description;
      this.suggestions = [];
    },error=>{
      ///
    });
  }
  save(){
    if(this.distance<100 || this.distance>500){
      this.notificationsService.initiate({title:"Distance is out range of (100-500)m", type:toastTypes.error})
      return
    }
    this.project.attendance_radius = this.distance;
    if(this.project.address == '' || !this.project.address || this.project.location.coordinates[0] === 1.1){
      return this.notificationsService.initiate({title:'Please select location', type:toastTypes.error});
    }else if(this.project.attendance_radius <=0){
      return this.notificationsService.initiate({title:'Please enter radius', type:toastTypes.error});
    }
    this.ngbActiveModal.close(this.project);
  }

  // validateDistance(event: Event) {
  //   const input = event.target as HTMLInputElement;
  //   let value = parseFloat(input.value);
  
  //   // If input is not a number, default to the minimum
  //   if (isNaN(value)) {
  //     value = 100;
  //   } else if (value < 100) {
  //     value = 100;
  //   } else if (value > 500) {
  //     value = 500;
  //   }
  
  //   // Update the input and variable
  //   input.value = value.toString();
  //   this.distance = value;
  // }
  
}
